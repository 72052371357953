@media screen and (min-width: 992px) {
  .header {
    .sidebar-button {
      display: none;
    }
  }
}

@media screen and (max-width: 1300px) {
  .header {
    .navbar {
      .nav-item {
        &:not(:last-child) {
          margin-right: 15px;
        }

        .nav-link {
          padding: 0;
          font-size: 12px;
        }
      }
    }

    .menu {
      &__list {
        .menu-item:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .header-v2 {
    .header__container {
      justify-content: space-between;
      padding: 8px 15px;
    }

    .logo {
      margin: 0;
    }

    .navbar {
      .navbar-nav {
        width: unset;
      }

      .nav-item {
        .nav-link {
          font-size: 1rem;
        }
      }
    }

    .logo {
      width: 84px;
      height: 32px;
    }

    .menu {
      display: none;
    }

    ~ main {
      // margin-top: 65px;
    }

    &__button {
      font-size: 14px !important;
      line-height: 24px !important;
      padding: 4px 15px !important;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .course-filter {
    padding-top: 25px;

    .courses {
      .search_course_form_mobile {
        margin-bottom: 23px;

        .filter--box {
          .btn {
            width: 100%;

            &:first-child {
              border-top-left-radius: 16px;
              border-bottom-left-radius: 16px;
            }
            &:last-child {
              border-top-right-radius: 16px;
              border-bottom-right-radius: 16px;
            }
          }
        }
        .filter--button {
          border-radius: 4px;
          padding: 4px 10px;

          img {
            margin-right: 5px;
          }
        }
      }
    }

    .upcoming_course_card {
      height: 200px;
      margin-bottom: 2px;
    }

    .course_data {
      top: 10px;
    }

    .course_info {
      top: 44px;

      .course_status {
        font-size: 10px;
        margin-bottom: 8px;
      }

      .course_name {
        font-size: 18px;
      }

      .course_place {
        font-size: 10px;
      }
    }

    .course_complete_registration,
    .course_complete {
      height: 35px;
    }

    .btn-outline {
      padding: 10px 20px;
      height: 44px;
    }
  }
}

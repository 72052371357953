main.profile-home {
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
  }
  .welcome-section {
    .container {
      max-width: 1170px;
    }
  }
  .container {
    max-width: 1341px;
  }
  .welcome-text {
    margin-top: 32px;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
      text-align: center;
    }
  }
  .banner-section {
    overflow: hidden;
    z-index: 0;
    position: relative;
    .container {
      max-width: 100%;
      padding: 0;
    }
  }
  .banner-slider {
    position: relative;
    margin-top: 24px;
    padding: 0 10%;
    .slide-button-prev,
    .slide-button-next {
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      width: 48px;
      height: 48px;
      border-radius: 49px;
      font-size: 24px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .slide-button-prev {
      left: calc(10% - 24px);
    }
    .slide-button-next {
      right: calc(10% - 24px);
    }
    .banner-slide-content {
      height: 560px;
      background-color: #edf5fc;
      border-radius: 20px;
      @media screen and (max-width: 991px) {
        height: 574px;
      }
    }
    .swiper-slide-visible {
      opacity: 1;
    }
    .slide-1-content {
      padding: 40px 15px;
      max-width: 1010px;
      margin: auto;
      display: flex;
      align-items: center;
      gap: 60px;
      height: 100%;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        .second-box {
          order: -1;
        }

        .first-box img {
          max-height: 344px;
          height: auto;
          max-width: 100%;
        }
      }
      .banner1-btn {
        margin-top: 24px;
        button {
          background: linear-gradient(
            102.78deg,
            #3d8be8 -37.89%,
            #89beec 100.28%
          );
          width: 100%;
          height: 64px;
          border-radius: 100px;
          color: white;
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
          border: none;
          text-transform: capitalize;
          &:focus {
            outline: none;
            border: none;
          }
          @media screen and (max-width: 991px) {
            font-size: 18px;
            height: 54px;
          }
        }
      }
    }
    .banner-slide-2,
    .banner-slide-3 {
      background-size: cover;
      background-position: center;
      box-shadow: inset 0 0 0 1000px rgba(22, 10, 21, 0.6);
      .slide-2-content,
      .slide-3-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 24px;
        h2 {
          font-size: 56px;
          line-height: 122%;
          margin-bottom: 10px;
          color: white;
          @media screen and (max-width: 991px) {
            font-size: 36px;
            line-height: 122%;
          }
        }
        p {
          font-size: 22px;
          font-weight: 500;
          line-height: 28px;
          color: white;
          @media screen and (max-width: 991px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
        .banner-btn {
          margin-top: 32px;
          button {
            background: linear-gradient(
              102.78deg,
              #ffd16f -37.89%,
              #ff865b 100.28%
            );
            width: 100%;
            height: 64px;
            border-radius: 100px;
            color: white;
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;
            border: none;
            padding: 0 48px;
            &:focus {
              outline: none;
              border: none;
            }
            @media screen and (max-width: 991px) {
              font-size: 18px;
              height: 54px;
            }
          }
        }
      }
    }
    .banner-slide-3 {
      background: url(/img/banner-bg-r2.webp) no-repeat center;
      background-size: cover;
      .banner-btn {
        button {
          background: white;
          color: #31364e;
        }
      }
    }
    .mobile-app-info {
      .info-header {
        display: flex;
        gap: 24px;
        align-items: center;
      }
      .app-icon {
        border: #d9d9d9 1px solid;
        border-radius: 24px;
        width: 112px;
        height: 112px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
        }
        @media screen and (max-width: 991px) {
          width: 72px;
          height: 72px;
          padding: 0 5px;
          border-radius: 12px;
        }
      }
      .app-title {
        h2 {
          color: #9598a6;
          font-family: Lora;
          font-size: 36px;
          line-height: 44px;
          font-style: italic;
          font-weight: 400;
          margin: 0;
          @media screen and (max-width: 991px) {
            font-size: 20px;
            line-height: 25px;
          }
        }
        h3 {
          font-family: Lora;
          font-size: 56px;
          font-weight: 400;
          line-height: 68.32px;
          margin: 0;
          @media screen and (max-width: 991px) {
            font-size: 32px;
            line-height: 38px;
          }
        }
      }
    }
  }
  .top-picks-container {
    .top-picks-content {
      padding: 0 24px 30px;
      margin: 0 -24px;
    }
    .top-picks-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }
    .top-picks-title {
      font-family: Lora;
      font-size: 48px;
      line-height: 62px;
      font-weight: 400;
      @media screen and (max-width: 991px) {
        font-size: 32px;
        line-height: 38px;
        flex: 1;
      }
    }
    .top-picks-actions {
      display: flex;
      gap: 14px;
      font-size: 32px;
      @media screen and (max-width: 767px) {
        display: none;
      }
      .swiper-button-disabled {
        opacity: 0.3;
      }
    }
  }
  .section-wisdom {
    padding-top: 120px;
    .section-title {
      text-align: center;
      margin-bottom: 20px;
    }
    .section-desc {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      text-align: center;
      color: #6f7283;
      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .swiper-wrapper {
      padding-bottom: 24px;
    }
    .swiper-horizontal {
      @media screen and (max-width: 991px) {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .swiper-slide {
      background: #ffffff;
      box-shadow: 0px 0px 46.6px 0px #d0cccc40;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        height: 100%;
      }
      @media screen and (max-width: 767px) {
        width: calc(100vw - 80px);
      }
    }
    .top-pick-preview-area {
      height: 269px;
      position: relative;
      img.top-pick-img {
        height: 269px;
        width: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 16px 16px 0 0;
      }
    }
    .preview-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .play-time {
        background: linear-gradient(
          102.78deg,
          #ffd16f -37.89%,
          #ff865b 100.28%
        );
        padding: 2px 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        border-radius: 8px;
        color: white;
      }
      .lock-info {
        width: 28px;
        height: 28px;
        background-color: white;
        border-radius: 8px;
        font-size: 16px;
        color: #31364e;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .top-pick-content-info {
      padding: 20px 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .top-pick-title {
      font-family: Lora;
      font-size: 24px;
      font-weight: 500;
      line-height: 31.2px;
      margin-bottom: 14px;
    }
    .top-pick-player-area {
      box-shadow: 0px 0px 46.6px 0px #d0cccc40;
      background: white;
      padding: 16px 10px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
    }
    .player-icon {
      width: 44px;
      height: 44px;
      background: linear-gradient(102.78deg, #3d8be8 -37.89%, #89beec 100.28%);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-aol {
        font-size: 20px;
        color: white;
      }
    }
    .play-duration {
      color: #6f7283;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.77px;
      display: flex;
      align-items: center;
      gap: 8px;
      .icon-aol {
        font-size: 18px;
        color: #9598a6;
      }
    }
    .play-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.11px;
      color: #6f7283;
      margin-top: 4px;
    }
    .top-pick-author {
      font-family: Work Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: auto;
      .icon-aol {
        background: #edf5fc;
        border-radius: 8px;
        color: #3d8be8;
        font-size: 16px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .upcoming-courses {
    padding-top: 120px;
    @media screen and (max-width: 767px) {
      padding-top: 60px;
    }
    .flip-card {
      background-color: transparent;
      height: 308px;
      perspective: 1000px;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transform: rotateY(0deg);
    }

    .flip-card-front {
      color: black;
    }

    .flip-card-back {
      transform: rotateY(180deg);
    }
    .ds-course-item {
      position: relative;
      height: 298px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 24px 8px #b1aaaa26;
      border-radius: 16px;
      .ds-image-wrap {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 100%;
          height: 298px;
          object-fit: cover;
          border-radius: 16px;
        }
      }
      .ds-course-header {
        position: relative;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .play-time {
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          padding: 2px 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          border-radius: 8px;
          color: white;
        }
        .lock-info {
          width: 28px;
          height: 28px;
          background-color: white;
          border-radius: 8px;
          font-size: 16px;
          color: #31364e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ds-course-info {
        position: relative;
        margin-top: auto;
        height: 120px;
        background: linear-gradient(
          180deg,
          rgba(61, 139, 232, 0) 0%,
          rgba(61, 139, 232, 0.74) 59.89%,
          #3d8be8 100%
        );
        border-radius: 0 0 16px 16px;
        padding: 0 24px 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .ds-course-title {
        font-family: Lora;
        font-size: 24px;
        font-weight: 500;
        line-height: 31.2px;
        text-align: center;
        color: #fff;
        margin-bottom: 12px;
      }
      .ds-course-author {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        color: #fff;
      }
    }
  }
  .recommended-courses,
  .activities-courses {
    padding-top: 90px;
    @media screen and (max-width: 767px) {
      padding-top: 30px;
    }
  }
  .recommended-courses,
  .activities-courses,
  .upcoming-slider {
    .course-item {
      display: flex;
      flex-direction: column;
      background: white;
      box-shadow: 0px 4px 24px 8px #b1aaaa26;
      border-radius: 16px;
      padding: 20px 16px;
      background: #ffffff;
      position: relative;
      @media screen and (max-width: 991px) {
        max-width: 100%;
        flex: 1 0 100%;
      }
      &:hover {
        .course-actions {
          display: flex;
        }
      }
    }
    .course-item-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 14px;
    }
    .course-title-duration {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    .course-title {
      font-family: Lora;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 12px;
      flex: 100%;
    }
    .course-type {
      background: #edf5fc;
      border-radius: 8px;
      padding: 5px 12px;
      color: #3d8be8;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-transform: uppercase;
      margin-right: 8px;
      &.online {
        background: #fff5eb;
        color: #ed994e;
      }
    }
    .course-duration {
      background: #edf5fc;
      border-radius: 8px;
      padding: 5px 12px;
      color: #3d8be8;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-transform: uppercase;
      margin-left: 8px;
    }
    .course-price {
      background: linear-gradient(94.04deg, #ffa180 3.84%, #ed7347 109.6%);
      border-radius: 8px;
      padding: 4px 12px;
      color: white;
      font-family: Lora;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
    }
    .course-instructors,
    .course-location,
    .course-date,
    .course-time {
      margin-bottom: 14px;
      padding: 14px 0;
      border-top: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
      &::before {
        content: '\e90c';
        font-family: 'iconaol' !important;
        line-height: 16px;
        text-align: center;
        font-size: 16px;
        color: #3d8be8;
        width: 16px;
        height: 16px;
        display: block;
        flex-shrink: 0;
        margin-left: 4px;
        margin-right: 12px;
      }
    }
    .course-location {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
      &::before {
        content: '\e90b';
      }
      & + .course-instructors {
        padding-top: 12px;
        border-top: none;
      }
    }
    .course-date {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
      &::before {
        content: '\e90a';
      }
    }
    .course-time {
      border: none;
      margin-bottom: 14px;
      padding-bottom: 0;
      &::before {
        content: '\e90d';
      }
    }
    .course-timing {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
      &::before {
        content: '\e90a';
        font-family: 'iconaol' !important;
        line-height: 16px;
        text-align: center;
        font-size: 16px;
        color: #3d8be8;
        width: 16px;
        height: 16px;
        display: block;
        flex-shrink: 0;
        margin-left: 4px;
        margin-right: 12px;
      }
      & + & {
        margin-top: 6px;
      }
    }
    .event-categories {
      display: flex;
      border-top: #e9e9e9 1px solid;
      padding-top: 14px;
      margin-top: auto;
      .cat-item {
        font-family: Lora;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        flex: 1;
        & + .cat-item {
          padding-left: 8px;
          border-left: #e9e9e9 1px solid;
        }
      }
    }
    .course-actions {
      background: linear-gradient(102.78deg, #f5f7f8 -37.89%, #ffffff 100.28%);
      padding: 24px 20px;
      gap: 12px;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      border-radius: 0 0 16px 16px;
      display: none;
      @media screen and (max-width: 1023px) {
        display: flex;
        padding: 14px 0 0;
        background: none;
        position: relative;
      }
      button {
        flex: 1;
        border-radius: 100px;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        &.btn-secondary {
          border: #ff865b 1.5px solid;
          color: #e47d6c;
          background: #ffffff;
        }
        &.btn-primary {
          border: none;
          color: #fff;
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
        }
      }
    }
    .course-item {
      &.course-full,
      &.registered {
        overflow: hidden;
        position: relative;
        &::after {
          content: 'Course Full';
          display: flex;
          justify-content: center;
          align-items: center;
          width: 171px;
          height: 35px;
          background: #31364e;
          color: #ffffff;
          font-size: 14px;
          line-height: 15px;
          font-weight: 600;
          text-transform: uppercase;
          position: absolute;
          transform: rotate(-45deg);
          bottom: 26px;
          right: -40px;
          padding: 0 10px;
          text-align: center;
        }
        .course-price {
          background: #c9c9c9;
        }
        .course-title-duration {
          color: #bdbdbd;
          &::before {
            color: #bdbdbd;
          }
        }
        .course-type {
          color: #bdbdbd;
          background-color: #f2f2f2;
          &::before {
            color: #bdbdbd;
          }
        }
        .course-location,
        .course-instructors,
        .course-timing {
          color: #bdbdbd;
          &::before {
            color: #bdbdbd;
          }
        }
        &:hover {
          .course-actions {
            display: none;
          }
        }
      }
      &.registered {
        &::after {
          content: 'Already Registered';
          background: linear-gradient(94.04deg, #ffa180 3.84%, #ed7347 109.6%);
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .order {
    &__form {
      .details {
        &__title {
          font-size: 18px;
        }

        &__content {
          font-size: 18px;
        }
      }
    }

    &__card {
      .bank-card-info {
        flex-wrap: wrap;

        #mm-yy,
        #cvc {
          width: 165px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .order {
    &__card {
      flex-direction: column;

      input:not([type='radio']):not([type='checkbox']) {
        width: 100%;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        padding: 15px;
      }
      .extra-padding {
        padding-left: 62px !important;
      }

      .select-box {
        width: 100%;
      }

      .validation-mobile-message {
        &.show {
          display: block;
        }
      }

      .bank-card-info {
        #mm-yy,
        #cvc {
          width: 45%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .order {
    padding: 30px 0 0 0;

    &__card {
      padding: 20px;
    }

    .title {
      font-size: 36px;
    }

    &__detail {
      font-size: 18px;
    }

    &__complete_important {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 575px) {
  .order {
    &__form {
      .details {
        flex-direction: column;
        align-items: flex-start;
        &__title {
          margin-bottom: 10px;
        }
      }
    }

    &__security {
      margin-bottom: 20px;
    }

    &__complete {
      button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .order {
    &__form {
      .details__title {
        font-size: 14px;
      }

      .details__content {
        font-size: 14px;
      }
    }

    .title {
      font-size: 24px;
    }

    &__detail {
      font-size: 15px;
    }
  }
}

/*
 * New typography toolkit
 */
@media screen and (max-width: 991px) {
  .h1 {
    font-size: 24px;
    line-height: 36px;
  }

  .h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .h3 {
    font-size: 18px;
    line-height: 26px;
  }

  .h4 {
    font-size: 20px;
    line-height: 28px;
  }

  .h5 {
    font-size: 18px;
    line-height: 26px;
  }

  .p1 {
    font-size: 16px;
    line-height: 24px;
  }

  .p2 {
    font-size: 14px;
    line-height: 22px;
  }

  .p3 {
    font-size: 12px;
    line-height: 18px;
  }

  .caption {
    font-size: 16px;
    line-height: 24px;

    &_sm {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  h1,
  h2,
  h3 {
    font-family: Lora, sans-serif;
    line-height: 1.33;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h3,
  h5,
  h6 {
    font-family:
      Work Sans,
      sans-serif;
    line-height: 1.33;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 24px;
    line-height: 1.41;
  }

  h6 {
    font-size: 22px;
    line-height: 1.36;
  }

  .p1,
  .p2 {
    font-family:
      Work Sans,
      sans-serif;
    line-height: 1.5;
  }

  .p1 {
    font-size: 20px;
  }

  .p2 {
    font-size: 18px;
  }

  .p3 {
    font-family:
      Work Sans,
      sans-serif;
    font-size: 16px;
    line-height: 1.375;
  }

  .p4 {
    font-family:
      Work Sans,
      sans-serif;
    font-size: 12px;
    line-height: 1.16;
  }

  .caption {
    font-family:
      Work Sans,
      sans-serif;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.3px;
  }
}

.the-sky-program {
  .banner {
    background-image: url(/img/the-sky-program-banner-bg.webp);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    @media screen and (max-width: 767px) {
      background-image: url(/img/the-sky-program-banner-mobile-bg.webp);
      background-position: top left;
    }
    .container {
      margin-left: auto;
      margin-right: auto;
      max-width: 1162px;
      height: 797px;
      padding: 132px 0px 0 35px;
      display: flex;
      @media screen and (max-width: 1199px) {
        padding-right: 35px;
      }
      @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
        height: auto;
        padding-top: 25px;
        flex-direction: column;
      }
      .left-section > p {
        font-family: Lora;
        font-weight: 400;
        font-style: normal;
        font-size: 22px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
      .right-section {
        margin-left: auto;
        @media screen and (max-width: 767px) {
          margin: 0 auto 20px;
        }
      }
    }
    .banner-title {
      font-family: Lora;
      font-weight: 700;
      font-size: 56px;
      line-height: 1;
      max-width: 598px;
      margin: 0 0 20px;
      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }
    .registration-wrap {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .register-button {
      width: 224px;
      height: 46px;
      background: linear-gradient(#ffd16f, #ff865b);
      box-shadow: none;
      text-shadow: none;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      font-family: Work Sans;
      border: none;
      border-radius: 10px;
      color: white;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }
    .banner-features {
      padding-top: 15px;
      margin-bottom: 20px;
      padding-left: 15px;
      li {
        font-weight: bolder;
        list-style: disc outside;
        font-size: 18px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
    .training-eligibility-text {
      margin-top: 80px;
      font-size: 26px;
      max-width: 503px;
      @media screen and (max-width: 767px) {
        display: none;
      }
      &.hidden {
        display: none;
        margin-top: 30px;
        font-size: 20px;
        line-height: 28px;
        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
  }
  .container {
    max-width: 1162px;
  }
  .featuren-in-box {
    margin: 40px 25px 50px;
    padding: 25px;
    border: #ccc 1px solid;
    @media screen and (max-width: 767px) {
      margin: 20px 0;
    }
    .title {
      font-weight: 400;
      font-family: Lora;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: bolder;
      text-align: center;
    }
  }
  .techniques-to-thrive {
    padding-bottom: 40px;
    text-align: center;
    .title {
      font-size: 26px;
      font-family: 'Work Sans';
      font-weight: bolder;
      text-align: center;
      margin-bottom: 25px;
      @media screen and (max-width: 767px) {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }
    .content {
      font-size: 16px;
      max-width: 656px;
      margin: auto;
    }
  }
  .the-science {
    padding-top: 40px;
    padding-bottom: 30px;
    @media screen and (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 10px;
    }
    .science-box {
      display: flex;
      align-items: flex-end;
      @media screen and (max-width: 899px) {
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
      }
    }
    .content-container {
      padding-left: 50px;
      @media screen and (max-width: 899px) {
        padding-left: 0;
        .title {
          display: none;
        }
      }
    }
    .the-science-text {
      padding-left: 40px;
      padding-bottom: 20px;
      padding-top: 10px;
      @media screen and (max-width: 1162px) {
        padding-left: 0;
      }
    }
    .title {
      font-size: 56px;
      font-family: Lora;
      font-weight: bolder;
      text-align: center;
      margin-bottom: 70px;
      @media screen and (max-width: 1162px) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
      &.hidden {
        display: none;
        @media screen and (max-width: 899px) {
          display: block;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
          font-size: 36px;
        }
      }
    }
    .image-area {
      img {
        border-radius: 100%;
        @media screen and (max-width: 899px) {
          max-width: 300px;
          height: auto;
        }
      }
    }
    .content {
      display: flex;
      @media screen and (max-width: 1162px) {
        flex-wrap: wrap;
        .count {
          margin: auto;
        }
      }
      @media screen and (max-width: 899px) {
        .count {
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .research-includes {
    background-color: rgba(247, 247, 247, 1);
    .research-listing {
      display: flex;
      margin: 0 -6px;
      padding-top: 12px;
      @media screen and (max-width: 1162px) {
        flex-wrap: wrap;
      }
      @media screen and (max-width: 520px) {
        flex-direction: column;
      }
      .research-list-item {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 12px;
        text-align: center;
        flex: 1;
        margin: 0 6px;
        padding: 12px;
        @media screen and (max-width: 1162px) {
          flex: calc(33.33% - 12px);
          margin-bottom: 12px;
        }
        @media screen and (max-width: 767px) {
          flex: calc(50% - 12px);
        }
        @media screen and (max-width: 520px) {
          width: 222px;
          margin: 0 auto 12px;
          flex-direction: column;
          display: flex;
          align-items: center;
        }
        img {
          @media screen and (max-width: 520px) {
            order: -1;
          }
        }
      }
      h3 {
        font-size: 20px;
        color: rgb(49, 54, 78);
        font-family: 'Work Sans';
        font-weight: bolder;
        min-height: 66px;
        padding: 0 10px;
        @media screen and (max-width: 520px) {
          margin-top: 20px;
          min-height: auto;
        }
      }
      .description {
        padding: 20px 0 0 0;
      }
    }
    .view-research {
      font-size: 16px;
      font-family: 'Work Sans';
      text-align: center;
      padding: 35px 0 40px;
      a {
        color: rgb(49, 54, 78);
        text-decoration: underline;
      }
      @media screen and (max-width: 520px) {
        padding-top: 15px;
      }
    }
  }
  .what-to-expect {
    background: rgba(255, 255, 255, 0.69);
    background-image: url(/img/sky-program-what-expect-bg.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    &::before {
      background: rgba(255, 255, 255, 0.69);
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .container {
      position: relative;
      padding: 60px 0;
      @media screen and (max-width: 767px) {
        padding: 30px 0;
      }
    }
    .left-area {
      padding-left: 61px;
      padding-right: 60px;
      @media screen and (max-width: 1162px) {
        padding-left: 20px;
      }
      @media screen and (max-width: 1024px) {
        order: 1;
        padding-right: 20px;
      }
    }
    .right-area {
      @media screen and (max-width: 1162px) {
        padding-right: 20px;
      }
      @media screen and (max-width: 1024px) {
        text-align: center;
        margin-bottom: 20px;
        padding-right: 0;
      }
    }
    .title {
      font-family: Lora;
      font-weight: bolder;
      font-size: 56px;
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }
    .content-area {
      display: flex;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }
    .content-box {
      background-color: white;
      border-radius: 12px;
      padding: 20px;
      ul {
        margin: 16px;
      }
      li {
        margin-top: 20px;
        list-style: disc outside;
      }
    }
    .expect-img {
      border-radius: 100%;
      @media screen and (max-width: 1024px) {
        width: 267px;
        height: auto;
      }
    }
  }
  .featured-brands {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1099px) {
      flex-wrap: wrap;
      justify-content: space-around;
      img {
        margin: 0 10px;
        max-width: 100%;
        height: auto;
        &:nth-child(3) {
          order: 1;
        }
      }
    }
  }
  .whats-included {
    padding: 80px 0;
    @media screen and (max-width: 767px) {
      padding: 35px 0;
    }
    .container {
      padding: 0 60px;
      @media screen and (max-width: 767px) {
        padding: 0 20px;
      }
    }
    .title {
      font-family: Lora;
      font-weight: bolder;
      font-size: 56px;
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }
    .included-listing {
      display: flex;
      flex-wrap: wrap;

      flex: 1;
    }
    .included-list-item {
      display: flex;
      flex: 33.33%;
      padding-right: 70px;
      margin-top: 30px;
      @media screen and (max-width: 767px) {
        flex: 100%;
        padding-right: 0;
        &:first-child {
          margin-top: 5px;
        }
      }
    }
    .check-icon {
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        margin-right: 5px;
        img {
          width: 30px;
          height: auto;
        }
      }
    }
    .text {
      padding-top: 5px;
    }
  }
  .change-stories {
    background-color: rgba(247, 245, 244, 1);
    .container {
      padding: 40px 0 60px;
      @media screen and (max-width: 767px) {
        padding: 20px;
      }
    }
    .title {
      font-family: Lora;
      font-weight: bolder;
      font-size: 56px;
      text-align: center;
      margin-bottom: 30px;
      @media screen and (max-width: 767px) {
        font-size: 36px;
        margin-bottom: 10px;
      }
    }
    .story-video {
      text-align: center;
      iframe {
        @media screen and (max-width: 767px) {
          max-width: 100%;
          height: 250px;
        }
      }
    }
    .testimonial-slider-wrapper {
      position: relative;
      @media screen and (max-width: 1199px) {
        margin: 0 85px;
      }
      @media screen and (max-width: 768px) {
        margin: 0px;
      }
    }
    .testimonials {
      padding: 40px 0 10px 0;
      &::after,
      &::before {
        display: none;
      }
    }
    .swiper-slide {
      height: auto;
      background-color: white;
    }
    .testimony-item {
      flex: calc(33.33% - 20px);
      background-color: white;
      font-style: italic;
      padding: 30px;
    }
    .swiper-button-prev {
      width: 60px;
      height: 61px;
      left: -75px;
      @media screen and (max-width: 768px) {
        display: none;
      }
      &:after {
        background: url(/img/blue-round-left-arrow.webp) no-repeat top left;
        width: 60px;
        height: 61px;
        font-size: 0;
      }
    }
    .swiper-button-next {
      width: 60px;
      height: 61px;
      right: -75px;
      @media screen and (max-width: 768px) {
        display: none;
      }
      &:after {
        background: url(/img/blue-round-right-arrow.webp) no-repeat top left;
        font-size: 0;
        width: 60px;
        height: 61px;
      }
    }
    .name {
      font-family: 'Work Sans';
      font-size: 20px;
      font-weight: bolder;
      text-align: right;
      line-height: 24px;
      margin-top: 15px;
    }
    .position {
      font-family: 'Work Sans';
      font-size: 20px;
      text-align: right;
      line-height: 24px;
    }
  }
  .healing-breaths {
    .container {
      padding: 60px 0;
      @media screen and (max-width: 767px) {
        padding: 20px;
      }
    }
    .title {
      font-family: Lora;
      font-weight: bolder;
      font-size: 56px;
      text-align: center;
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }
    .org-text {
      font-size: 26px;
      text-align: center;
      max-width: 770px;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    .benefitted-orgs-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
    .org-list-item {
      padding: 30px 0 10px 0;
      margin: 0 20px;
      @media screen and (max-width: 767px) {
        flex: calc(50% - 40px);
        img {
          max-width: 100%;
          height: auto;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:nth-last-child(2) {
        margin-left: auto;
        margin-right: 60px;
        @media screen and (max-width: 767px) {
          margin: 0 20px;
        }
      }
      &:nth-last-child(1) {
        margin-right: auto;
        margin-left: 60px;
        @media screen and (max-width: 767px) {
          margin: 0 20px;
        }
      }
    }
  }
  .course-details {
    width: 369px;
    margin-top: -60px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 24px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 2px 24px 0px rgba(61, 139, 232, 0.25);

    @media screen and (max-width: 767px) {
      margin-top: 10px;
      width: 100%;
      max-width: 369px;
      margin-left: auto;
      margin-right: auto;
    }

    &__cost {
      color: #000;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;

      & span {
        color: #717171;
        text-decoration: line-through;
      }
    }

    &__place {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__instructor {
      display: grid;
      grid-template-columns: 1fr 122px;
    }

    &__place,
    &__instructor {
      .top,
      .left,
      .right,
      .bottom,
      .full {
        border: 1px solid #b0b0b0;
        border-radius: 8px;
      }

      .full {
        grid-column: 1/3;
      }

      .top {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .top.left {
        border-top-right-radius: 0;
      }

      .top.right {
        border-top-left-radius: 0;
      }

      .left + .right {
        border-left: none;
      }

      .bottom {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
      }

      .bottom.left {
        border-bottom-right-radius: 0;
      }

      .bottom.right {
        border-bottom-left-radius: 0;
      }
    }

    &__cell {
      padding: 12px;
    }

    &__table-text {
      color: #222;
      font-size: 10px;
      font-weight: 800;
      line-height: 14px;
      text-transform: uppercase;

      a {
        text-decoration: none;
      }

      span {
        display: block;
        color: #000;
        font-size: 13px;
        font-weight: 400;
        line-height: 18.2px;
        text-transform: none;

        margin-top: 2px;
      }

      &.small span {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__times {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__time {
      display: flex;
      justify-content: space-between;

      color: #222;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;

      span {
        font-size: 13px;
        font-weight: 600;
        line-height: 18.2px;
      }
    }

    &__text {
      color: #222;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        .courses-filter__button {
          width: 100%;
        }
      }
    }

    &__search {
      outline: none;
      border: none;
      width: 100%;
      height: 48px;
      border-radius: 10px;
      background: #508be1;

      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;

      &.disabled {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .progress-section {
    &.volunteer-program {
      margin-top: 0;
      padding: 80px 0;

      .achivment {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .progress-section {
    &__title {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 991px) {
  .progress-section {
    padding-top: 200px;

    &__title {
      font-size: 18px;
      text-align: center;
    }

    .featured-in {
      padding: 30px;

      &__box {
        flex-wrap: wrap;

        img {
          margin-right: 45px;
        }
      }
    }

    &.volunteer-program {
      margin-top: 0;
      padding: 40px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .progress-section {
    padding-top: 340px;

    &.sky-breath-meditation {
      padding-top: 430px;
    }

    &__title {
      font-size: 18px;
      text-align: center;
    }

    .featured-in {
      padding: 30px;

      &__box {
        img {
          margin-right: 45px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .progress-section {
    &.sky-breath-meditation {
      padding-top: 470px;
    }
  }
}

@media screen and (max-width: 500px) {
  .progress-section {
    .featured-in {
      &__box {
        img {
          &:last-child {
            height: 72px;
          }

          margin-right: 27px;
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .progress-section {
    .featured-in {
      &__box {
        img {
          margin-right: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .progress-section {
    .featured-in {
      &__box {
        img {
          height: 50px;
          margin-right: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .progress-section {
    .featured-in {
      &__box {
        img {
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 355px) {
  .progress-section {
    .featured-in {
      padding: 20px;

      &__box {
        img {
          &:last-child {
            height: 60px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 335px) {
  .progress-section {
    .featured-in {
      &__box {
        img {
          margin-right: 3px;
        }
      }
    }
  }
}

.testimonial {
  display: flex;
  margin-bottom: 30px;

  &__media {
    position: relative;
    width: 100%;
    height: 220px;
    background: #e9e9e9;
    border-radius: 16px 16px 0 0;
    overflow: hidden;

    img,
    video {
      position: relative;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &__name {
      position: absolute;
      color: #ffffff;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      font-size: 48px;
      font-weight: 500;
      letter-spacing: 0.8px;
    }

    &_gradient-1 {
      background: linear-gradient(90deg, #d0e1f0 -26%, #89beec 100%);
    }

    &_gradient-2 {
      background: linear-gradient(90deg, #9b6bbe -15%, #ff865b 115%);
    }

    &_gradient-3 {
      background: linear-gradient(90deg, #e47d6c -7%, #3c8ae8 111%);
    }
  }

  &__content {
    padding: 25px;
    border: 1px solid #e9e9e9;
    border-radius: 0 0 16px 16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    &__quote {
      font-size: 20px;
      font-weight: 600;
      font-style: italic;
      line-height: 1.3;
      color: #31364e;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
      font-style: italic;
      line-height: 1.43;
      color: #31364e;
      margin-bottom: 20px;

      p:not(:last-child) {
        margin-bottom: 5px;
      }

      &__link {
        margin-top: 5px;
        text-decoration: none;
        border-bottom: 1px dotted #3d8be8;

        &:hover {
          border-color: #89beec;
        }

        &:active {
          color: #2465b3;
          border-color: #2465b3;
        }
      }
    }

    &__person {
      margin: auto 0 0;
      font-size: 12px;
      line-height: 1.33;

      &__name {
        font-weight: 600;
        color: #31364e;
      }

      &__about {
        font-weight: 500;
        color: #9598a6;
        margin-top: 5px;
      }
    }

    &__tags {
      position: absolute;
      z-index: 10;
      bottom: calc(100% + 15px);
      left: 20px;
      width: 100%;

      span {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        font-weight: 500;
        color: #31364e;
        white-space: nowrap;
        margin-bottom: 5px;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  &:not(.testimonial_wide) {
    flex-direction: column;

    .testimonial__content {
      position: relative;
      border-top: none;
      flex-grow: 1;

      &__tags {
        width: 100%;
        width: calc(100% - 40px);
      }
    }
  }

  &_wide {
    position: relative;
    min-height: 377px;

    .testimonial {
      &__media,
      &__content {
        width: 50%;
      }

      &__media {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 16px 0 0 16px;
      }

      &__content {
        border-radius: 0 16px 16px 0;
        border-left: none;
        margin-left: 50%;

        &__tags {
          width: 50%;
          width: calc(50% - 40px);
          bottom: 15px;
        }
      }
    }
  }
}

main.daily-sky {
  @media screen and (max-width: 991px) {
    margin: auto;
  }
  .container {
    max-width: 1334px;
  }
  .title-header {
    background-color: #fffaf5;
    padding: 80px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url(/img/center-header-bg-1.webp),
      url(/img/center-header-bg-2.webp);
    background-position:
      left center,
      right center;
    background-repeat: no-repeat, no-repeat;
    @media screen and (max-width: 767px) {
      padding: 58px 16px;
      background: #fffaf5;
    }
    .page-title {
      font-family: Lora;
      font-size: 48px;
      line-height: 65px;
      text-align: center;
      font-weight: 300;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 38px;
        font-weight: 400;
      }
    }
    .page-description {
      color: #6f7283;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      max-width: 588px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .section-daily-sky-courses {
    padding: 120px 20px;
    @media screen and (max-width: 991px) {
      padding: 32px 0px;
    }
    .ds-course-listing {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(var(--n, 3), 1fr);
      @media (width < 768px) {
        gap: 16px;
        --n: 2;
      }
      @media (width < 479px) {
        --n: 1;
      }
    }
    .ds-course-item {
      position: relative;
      height: 320px;
      display: flex;
      flex-direction: column;
      .ds-image-wrap {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 100%;
          height: 320px;
          object-fit: cover;
          border-radius: 16px;
        }
      }
      .ds-course-header {
        position: relative;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .play-time {
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          padding: 2px 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          border-radius: 8px;
          color: white;
        }
        .lock-info {
          width: 28px;
          height: 28px;
          background-color: white;
          border-radius: 8px;
          font-size: 16px;
          color: #31364e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ds-course-info {
        position: relative;
        margin-top: auto;
        height: 120px;
        background: linear-gradient(
          180deg,
          rgba(61, 139, 232, 0) 0%,
          rgba(61, 139, 232, 0.74) 59.89%,
          #3d8be8 100%
        );
        border-radius: 0 0 16px 16px;
        padding: 0 24px 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .ds-course-title {
        font-family: Lora;
        font-size: 24px;
        font-weight: 500;
        line-height: 31.2px;
        text-align: center;
        color: #fff;
        margin-bottom: 12px;
      }
      .ds-course-author {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        color: #fff;
      }
    }
  }
  .section-journey-app {
    padding-bottom: 120px;
    .container {
      display: flex;
      gap: 80px;
      align-items: center;
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
      @media screen and (max-width: 767px) {
        gap: 24px;
      }
    }
    .mobile-app-placeholder {
      width: 648px;
      max-width: 100%;
      background: #f7f7f7;
      border-radius: 32px;
      padding: 114px 20px 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @media screen and (max-width: 767px) {
        padding-top: 58px;
      }
      img {
        width: 512px;
        max-width: 100%;
        margin-left: 10%;
      }
    }
    .mobile-app-info {
      .info-header {
        display: flex;
        gap: 24px;
        align-items: center;
      }
      .app-icon {
        background: white;
        border: #d9d9d9 1px solid;
        border-radius: 24px;
        width: 112px;
        height: 112px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
        }
        @media screen and (max-width: 767px) {
          width: 64px;
          height: 64px;
          padding: 0 5px;
          border-radius: 12px;
        }
      }
      .app-title {
        h2 {
          color: #9598a6;
          font-family: Lora;
          font-size: 36px;
          line-height: 44px;
          font-style: italic;
          font-weight: 400;
          margin: 0;
          @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 25px;
          }
        }
        h3 {
          font-family: Lora;
          font-size: 56px;
          font-weight: 400;
          line-height: 68.32px;
          margin: 0;
          @media screen and (max-width: 767px) {
            font-size: 32px;
            line-height: 38px;
          }
        }
      }
      ul.info-list-item {
        padding-top: 32px;
        padding-left: 15px;
        li {
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          list-style: disc;
          padding-left: 7px;
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
          & + li {
            margin-top: 15px;
          }
        }
      }
      .app-donwload-details {
        padding-top: 48px;
        @media screen and (max-width: 767px) {
          padding-top: 24px;
        }
        h4 {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 16px;
        }
      }
      .store-list {
        display: flex;
        gap: 24px;
        @media screen and (max-width: 767px) {
          gap: 12px;
        }
        img {
          height: 58px;
          max-width: 100%;
          @media screen and (max-width: 767px) {
            height: auto;
          }
        }
      }
    }
  }
}

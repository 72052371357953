.top-column {
  position: relative;
  padding: 190px 150px 235px 150px;
  background-image: url('/img/top-column-bg@2x.png');
  background-size: cover;
  background-position: 90%;
  box-shadow: inset 0px -130px 89px -60px white;

  .type-course {
    font-size: 18px;
    line-height: 1.44;
    color: #31364e;
    margin-bottom: 10px;
  }

  .course-name {
    font-size: 48px;
    color: #31364e;

    &_thin {
      font-weight: 400 !important;
    }
  }

  .course-details-list {
    list-style-type: disc;
    padding: 0 20px;
    margin-bottom: 40px;

    li {
      font-size: 18px;
      font-weight: 600;
      color: #31364e;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  .course-details {
    position: absolute;
    right: 150px;
    bottom: -195px;
    width: 369px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 24px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 2px 24px 0px rgba(61, 139, 232, 0.25);

    &__cost {
      color: #000;
      font-family: Roboto;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;

      & span {
        color: #717171;
        text-decoration: line-through;
      }
    }

    &__place {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__instructor {
      display: grid;
      grid-template-columns: 1fr 124px;
    }

    &__place,
    &__instructor {
      .top,
      .left,
      .right,
      .bottom,
      .full {
        border: 1px solid #b0b0b0;
        border-radius: 8px;
      }

      .full {
        grid-column: 1/3;
      }

      .top {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .top.left {
        border-top-right-radius: 0;
      }

      .top.right {
        border-top-left-radius: 0;
      }

      .left + .right {
        border-left: none;
      }

      .bottom {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
      }

      .bottom.left {
        border-bottom-right-radius: 0;
      }

      .bottom.right {
        border-bottom-left-radius: 0;
      }
    }

    &__cell {
      padding: 12px;
    }

    &__table-text {
      color: #222;
      font-size: 10px;
      font-weight: 800;
      line-height: 14px;
      text-transform: uppercase;

      a {
        text-decoration: none;
      }

      span {
        display: block;
        color: #000;
        font-size: 13px;
        font-weight: 400;
        line-height: 18.2px;
        text-transform: none;

        margin-top: 2px;
      }

      &.small span {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__times {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__time {
      display: flex;
      justify-content: space-between;

      color: #222;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;

      span {
        font-size: 13px;
        font-weight: 600;
        line-height: 18.2px;
      }
    }

    &__text {
      color: #222;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
    }

    &__search {
      outline: none;
      border: none;

      width: 100%;
      height: 48px;
      border-radius: 10px;
      background: #508be1;

      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;

      &.disabled {
        display: none;
      }
    }
  }

  .mobile-modal {
    position: fixed;
    top: 0;
    bottom: unset;
    height: 0;

    .close-modal {
      margin: 40px auto 0 20px;
      box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
      background-color: #ffffff;
    }

    display: none;

    .icon-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .icon {
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #ffffff;
        transition: all 0.3s ease-in-out;

        &:hover {
          border-color: #89beec;
        }

        &:active {
          border-color: #4191e9;
        }

        img {
          width: 50px;
          height: 50px;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .mobile-modal__header {
      padding: 0;
      background: none;

      &-subtitle {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        text-align: center;
        color: #31364e;
        margin: 40px 20px;
      }

      .auth-btn-wrapper {
        display: flex;
      }

      .auth-btn {
        padding: 0 0 11px 0;
        width: 50%;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #c4c5cc;
        background-color: white;
        border: none;
        border-bottom: 2px solid #e9e9e9;
        transition: all 0.3s ease-in-out;
        outline: none;

        &.active {
          color: #3d8be8;
          border-color: #3d8be8;
        }
      }
    }

    .modal-window__body {
      border: none;
    }

    form {
      margin-top: 20px;
      display: none;
      opacity: 0;
      flex-direction: column;
      transition: all 0.3s ease-in-out;

      &.active {
        display: flex;
      }

      &.show {
        opacity: 1;
      }

      input {
        padding: 12px 15px;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        margin-bottom: 15px;
        font-size: 14px;
        color: #9598a6;
        transition: all 0.3s ease-in-out;

        &.validate {
          border-color: #fa5a67;
        }

        &:hover {
          border-color: #89beec;
        }

        &:focus {
          border-color: #2465b3;
        }
      }

      .validation-input {
        text-align: left;
        font-size: 12px;
        line-height: 1.33;
        color: #fa5a67;
        margin-bottom: 15px;
      }

      .link {
        text-align: left;
      }

      .checkbox-wrapper {
        display: flex;
        margin-top: 17px;
      }

      .checkbox-text {
        font-size: 12px;
        text-align: left;
        margin-left: 10px;
      }
    }
  }

  &.silent-retreat {
    background-image: url('/img/silent-retreat-bg@2x.png');

    .type-course {
      color: white;
    }

    .course-name {
      color: white;
    }

    .course-details {
      bottom: -290px;
    }

    .course-details-list {
      li {
        color: white;
      }
    }
  }

  &.sahaj-samadhi {
    background-image: url('/img/sahaj-samadhi-bg@2x.png');
    background-position-y: 100%;
    box-shadow: none;

    .course-name {
      font-weight: normal;
      display: inline-flex;

      span {
        font-size: 20px;
      }
    }
  }

  &.sky-breath-meditation {
    background-image: url('/img/gurudev_sky.png');
    background-position-y: 5%;
    box-shadow: none;

    @media screen and (max-width: 720px) {
      background-position-x: 80%;
    }

    .type-course,
    .course-name {
      color: white;
    }
    .course-details-list li {
      color: white;
    }
    .course-name {
      max-width: 66%;
    }

    .in-person {
      bottom: -393px;
    }

    @media screen and (min-width: 720px) {
      .course-details {
        bottom: -400px;
      }
      .in-person {
        bottom: -450px;
      }
    }
  }
}

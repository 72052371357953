.program-experts {
  padding: 120px 0 100px;

  &__title {
    margin-bottom: 20px;
  }

  &__text:not(:last-child) {
    margin-bottom: 20px;
  }

  &__image {
    width: 100%;
  }
}

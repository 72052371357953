.footer-details {
  padding: 40px 0 0;

  .logo {
    margin-bottom: 30px;
  }

  .nav-details {
    margin-top: 3px;
    text-align: left;
    white-space: nowrap;

    .nav-item__title {
      font-size: 16px;
      font-weight: 600;
      color: #31364e;
    }
  }

  .dropdown-item {
    text-decoration: none;
  }

  .detail {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dropdown-toggle {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0;
      font-size: 16px;
      font-weight: 600;
      color: #31364e;
      transition: all 0.3s ease-in-out;

      &::after {
        color: #89beec;
      }

      &:hover {
        color: #6f7283 !important;
      }

      img {
        margin-right: 10px;
      }

      &:not(:first-child) {
        img {
          margin-left: 45px;
        }
      }
    }

    .input-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 15px;
      border-radius: 4px;
      border: solid 1px #e9e9e9;
      background-color: #ffffff;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: #89beec;
      }

      input {
        background: none;
        border: none;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  .nav-item a {
    font-size: 12px;
    text-decoration: none;
    color: #31364e;
  }

  .nav-item a:hover {
    color: #6f7283;
  }

  .search-block {
    margin-top: 32px;
    margin-bottom: 40px;
  }

  .social-network {
    display: flex;
    flex-direction: row;

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 4px;
      border: solid 1px #e9e9e9;
      background-color: #ffffff;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-color: #89beec;
      }

      &:active,
      &:focus {
        border-color: $piction-blue;
      }
    }
  }
}

.program-inspiration {
  padding: 100px 0 0;

  &__title {
    font-weight: bold;
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 28px;
    color: #1e2232;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 24px;
    color: #606270;
    margin-bottom: 28px;
  }

  &__button {
    width: 272px;
    border-radius: 8px !important;
    justify-content: center;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: 296px;
    color: #1e2232;
    margin-bottom: 100px;

    &-image {
      width: 48px;
      height: 48px;
      margin-bottom: 28px;
    }

    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 24px;
    }

    &-subtitle,
    &-text {
      padding-left: 28px;
    }

    &-subtitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.order .container {
  padding: 0 20px;
}

.order__title {
  font-size: 48px;
  line-height: 60px;
  font-family: Lora;
  text-align: center;
}

.order__description {
  max-width: 700px;
  margin: 0 auto 40px auto;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.stepper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  &_second {
    margin-bottom: 40px;
  }

  &__body {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__item {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: #c4c5cc;
    cursor: pointer;

    &_completed {
      color: #31364e;
    }

    &_active {
      color: #3d8be8;
    }
  }

  &__progress {
    background: #e9e9e9;
    min-width: 72px;
    height: 2px;
    border-radius: 1px;
  }
}

.volunteer-checkout {
  &__container {
    max-width: 630px;
    margin: 0 auto;
  }

  &__form {
    border: 1px solid #89beec;
    border-radius: 16px;
    margin: 0 auto;
    padding: 30px 40px 50px 30px;
    margin-bottom: 30px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }
}

.card-order {
  padding: 30px;
  border: 1px solid #89beec;
  border-radius: 16px;

  &__row {
    display: flex;
    gap: 20px;
  }

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  &__input {
    font-size: 14px;
    line-height: 18px;
    border-radius: 8px;
  }

  &__item {
    margin-bottom: 20px;
    flex: 1 1 100%;

    &_clear {
      @media (min-width: 991px) {
        margin-bottom: 0;
      }
    }

    &_empty {
      @media (max-width: 991px) {
        display: none;
      }
    }

    &_last {
      @media (max-width: 991px) {
        margin-bottom: 0;
      }
    }

    &_password {
      position: relative;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }

      & .prompt-message {
        @media (max-width: 991px) {
          display: none !important;
        }
      }
    }

    & input {
    }
  }
}

.recepit {
  &__item {
    &_head {
      border-radius: 16px 16px 0 0;
    }
  }
}

.reciept__payment-tooltip_sm {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 25px;
  color: #9598a6;

  @media (max-width: 380px) {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .order__title {
    font-size: 42px;
    line-height: 56px;
  }

  .order__description {
    font-size: 18px;
    line-height: 26px;
  }

  .card-order__row {
    flex-wrap: wrap;
    gap: 0;
  }

  .reciept__right {
    display: none;
  }

  .order__card__payment_hidden {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .order__title {
    font-size: 32px;
    line-height: 42px;
  }

  .order__description {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
  }
}

@media (max-width: 560px) {
  .volunteer-checkout__form {
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .card-order {
    padding: 0;
    border: none;
  }

  .card-order__item {
    margin-bottom: 15px;
  }

  .order__card__payment_hidden {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .order__title {
    line-height: 36px;
    text-align: left;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .order__description {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  .stepper {
    &__item {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .volunteer-checkout__continue-btn {
    width: 100%;
    justify-content: center;
  }
}

.multiple-selector__arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.select2-container--open .multiple-selector__arrow {
  transform: rotate(180deg);
}

.program-review {
  padding: 80px 0;

  &__text {
    color: $text-color;
    text-align: center;
    font-family: Lora, serif;
    font-style: italic;
    font-weight: normal;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .author {
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      position: relative;
      width: 90px;
      height: 90px;
      margin-right: 33px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:after {
        content: '“';
        position: absolute;
        top: 0;
        right: -15px;
        font-size: 60px;
        line-height: 70px;
        font-weight: 600;
        color: $text-color;
      }
    }

    &__name {
      color: $text-color;
      font-weight: 600;
      font-size: 26px;
      line-height: 34px;
    }
  }
}

.experiences {
  padding: 100px 0;
  background-color: #f7f7f7;
  background-image: url('/img/experiences-bg.png');
  background-size: cover;
  background-position-y: 100%;

  &__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    max-width: 410px;
    margin: 20px auto 40px;
  }

  .comments__item {
    min-height: unset;

    &.comment-opacity {
      opacity: 0.6;
    }
  }
}

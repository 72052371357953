.about {
  padding: 100px 0 80px 0;
  background-image: url('/img/about-bg.png');
  background-size: cover;
  background-position-y: 90%;

  &__title {
    margin-bottom: 50px;
  }

  &__logo {
    background-color: white;
    border-radius: 50%;
    margin: 0 auto 25px auto;
    width: 100px;
    height: 100px;
    box-shadow: 0 0 15px 0 rgba(61, 139, 232, 0.4);
    border: solid 1px #e9e9e9;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-size: 18px;
    color: #31364e;

    span {
      display: block;
      font-size: 20px;
      font-weight: 600;
    }
  }
}

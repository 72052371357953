.login-register-page {
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
  }
  .section-login-register {
    min-height: calc(100vh - 75px);
    background-image: url(/img/login-top-vector.png),
      url(/img/login-bottom-vector.png);
    background-position:
      top right,
      bottom left;
    background-repeat: no-repeat, no-repeat;
    display: flex;
    align-items: center;
    padding: 97px 0;
    @media screen and (max-width: 991px) {
      background: none;
    }
    .container {
      max-width: 470px;
      @media screen and (min-height: 600px) {
        margin-top: -75px;
      }
    }
    .page-title {
      font-size: 36px;
      font-weight: 500;
      line-height: 44px;
      text-align: center;
      margin: 0;
    }
    .page-description {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #6f7283;
      strong {
        font-weight: 600;
      }
    }
    .login-options {
      display: flex;
      gap: 12px;
      padding: 20px 0;
      button {
        border-radius: 16px;
        height: 44px;
        background: #f7f7f7;
        border: none;
        flex: 1;
        outline: none;
      }
    }
    .login-options-1 {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 12px;
      .login-option-item {
        background: #f7f7f7;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 12px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #6f7283;
        strong {
          font-weight: 600;
        }
        .option-icon {
          width: 32px;
          height: 32px;
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          border-radius: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .or-separator {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #6f7283;
      position: relative;
      &::before {
        content: '';
        height: 1px;
        background: #e9e9e9;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
      }
      span {
        background: white;
        padding: 0 8px;
        display: inline-block;
        position: relative;
        color: #6f7283;
      }
    }
    .form-login-register {
      padding: 20px 0 0 0;
    }
    .form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      &.checkbox {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        padding: 12px 0;
        label {
          color: #31364e;
          font-weight: 400;
          margin: 0;
          line-height: 18px;
          a {
            color: #ed994e;
            text-decoration: none;
          }
        }
      }
      label {
        color: #9598a6;
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
      select,
      input:not([type='checkbox'], [type='radio']) {
        box-shadow: 0px 1px 2px 0px #1018280d;
        border: 1px solid #e9e9e9;
        border-radius: 16px;
        height: 44px;
        width: 100% !important;
        padding: 10px 14px;
        margin-left: 0;
        margin-right: 0;
      }
      input[type='checkbox'],
      input[type='radio'] {
        border: 1px solid #ed994e;
        height: 20px;
        width: 20px;
        -webkit-appearance: none;
        border-radius: 4px;
        flex-shrink: 0;
        &:checked {
          border: none;
          &::after {
            content: url(/img/checkicon-css.svg);
            background-color: #ed994e;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-size: 12px;
          }
        }
      }
      input[type='radio'] {
        border-radius: 100%;
        border-color: #ed994e;
        display: flex;
        align-items: center;
        justify-content: center;
        &:checked {
          border: 1px solid #ed994e;
          &::after {
            content: '';
            background-color: #ed994e;
            width: 90%;
            height: 90%;
            display: block;
            border-radius: 100%;
          }
        }
      }
      &.password {
        position: relative;
      }
      .showPassBtn {
        border: none;
        background: none;
        position: absolute;
        top: 34px;
        right: 0;
        padding: 5px 14px;
        &:focus {
          outline: none;
        }
        .icon-aol:before {
          color: #98a2b3;
          font-size: 16px;
        }
      }
      input {
        &.validate {
          border-color: #dc3545;
        }
      }
      .showPassBtn.password {
        .icon-aol:before {
          content: '\e918';
        }
      }
      .showPassBtn.text {
        .icon-aol:before {
          content: '\e939';
        }
      }
    }
    .validation-input {
      color: #dc3545;
      font-size: 12px;
      padding: 5px 0;
    }
    .common-error-message {
      color: #dc3545;
      font-size: 13px;
      margin-bottom: 10px;
      text-align: center;
    }
    .form-action {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      button {
        outline: none;
      }
    }
    .apple-logo-login {
      text-align: center;
      padding: 10px 0;
      img {
        width: 70px;
      }
      input {
        &.validate {
          border-color: #dc3545;
        }
      }
    }
    .validation-input {
      color: #dc3545;
      font-size: 12px;
      padding: 5px 0;
    }
    .common-error-message {
      color: #dc3545;
      font-size: 13px;
      margin-bottom: 10px;
      text-align: center;
    }
    .form-action {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      button {
        outline: none;
      }
    }
    .apple-logo-login {
      text-align: center;
      padding: 10px 0;
      img {
        width: 70px;
      }
    }
    a.forgot-pass {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #3d8be8;
      text-decoration: none;
    }
    .submit-btn {
      height: 54px;
      padding: 0 32px;
      border-radius: 100px;
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      color: white;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      border: none;
      width: 100%;
    }
    .form-other-info {
      padding-top: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #6f7283;
      a {
        font-weight: 600;
        color: #ed994e;
        text-decoration: none;
      }
    }
  }
}
.modal {
  padding-left: 15px;
  padding-right: 15px;
  &.login-appleID,
  &.confirmation-message {
    .modal-dialog {
      max-width: 524px;
      @media screen and (max-width: 720px) {
        height: auto;
        max-width: 100%;
      }
    }
    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        margin: 0;
        @media screen and (max-width: 720px) {
          font-size: 24px;
          line-height: 31px;
        }
      }
      .close {
        color: #9598a6;
        font-size: 40px;
        right: 16px;
        background: none;
        top: 14px;
        font-weight: 300;
        &:focus {
          outline: none;
        }
      }
    }
    .modal-content {
      border-radius: 20px;
      padding: 24px 32px;
      @media screen and (max-width: 720px) {
        padding: 24px 20px;
      }
    }
    .modal-body {
      padding: 12px 0 0 0;
      margin: 0;
      .appleID-info {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #6f7283;
        padding: 0 48px;
        margin-bottom: 12px;
        strong {
          font-weight: 500;
        }
        @media screen and (max-width: 768px) {
          padding: 0;
        }
      }
      .appleID-info2 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        a {
          color: #3d8be8;
          text-decoration: none;
        }
      }
      .signing-info {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        gap: 14px;
        padding-top: 24px;
        align-items: center;
      }
    }
    .iCloudLogo {
      width: 48px;
      height: 48px;
      background: #fff;
      box-shadow:
        0px 2px 25.700000762939453px -2px #3e38360f,
        0px 2px 11.300000190734863px -2px #3e383614;
      margin-bottom: 20px;
      border-radius: 12px;
      text-align: center;
    }
    .confirmation-message-info {
      font-size: 18px;
      text-align: center;
      padding: 10px 20px 20px;
    }
  }
}
.loading-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .overlay-loader {
    &::before {
      content: '';
      display: block;
      width: 36px;
      height: 36px;
      border-radius: 36px;
      border-width: 5px;
      border-style: solid;
      border-color: #ed7347 #ed7347 #ed7347 transparent;
      animation: loader-ring 1.2s linear infinite;
    }
  }
  .loading-text {
    color: #808183;
    margin-top: 10px;
  }
  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

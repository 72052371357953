@font-face {
  font-family: 'museo_sans500_italic';
  src:
    url('/fonts/museosans/museosans_500_italic-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans_500_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans500';
  src:
    url('/fonts/museosans/museosans_500-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans_500-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans700';
  src:
    url('/fonts/museosans/museosans_700-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans_700-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans900';
  src:
    url('/fonts/museosans/museosans_900-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans_900-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans100';
  src:
    url('/fonts/museosans/museosans-100-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans-100-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans100_italic';
  src:
    url('/fonts/museosans/museosans-100italic-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans-100italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans300';
  src:
    url('/fonts/museosans/museosans-300-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans-300-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans300_italic';
  src:
    url('/fonts/museosans/museosans-300italic-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans-300italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans700_italic';
  src:
    url('/fonts/museosans/museosans-700italic-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans-700italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'museo_sans900_italic';
  src:
    url('/fonts/museosans/museosans-900italic-webfont.woff2') format('woff2'),
    url('/fonts/museosans/museosans-900italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

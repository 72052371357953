@media screen and (max-width: 991px) {
  .insight-collection3 {
    #topic-input {
      padding-left: 0;
      text-align: center;
    }

    .comments__button-next {
      right: 40px;
    }
  }
}

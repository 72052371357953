@media only screen and (min-width: 992px) {
  .testimonials {
    &__slider {
      .swiper-wrapper {
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .testimonials {
    padding: 60px 0 80px;

    &::before {
      height: 600px;
    }

    &::after {
      height: 260px;
    }

    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 1.57;
    }

    &__filter {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &__title {
        display: none;
        margin-bottom: 0;

        &_mobile {
          display: block;
        }
      }

      .filter {
        display: block;
      }

      &__buttons {
        display: none;
        justify-content: center;
        width: 100%;
        padding: 10px 0;

        &_active {
          display: flex;
        }
      }
    }

    &__feedback {
      margin-top: 90px;

      &__title {
        font-size: 24px;
      }

      &__button {
        margin: 25px auto 0;
      }
    }

    &__slider {
      overflow: visible;

      .swiper-wrapper {
        flex-wrap: nowrap;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .testimonials {
    &__filter {
      &__buttons {
        align-items: center;
        flex-direction: column;

        .btn {
          min-width: 140px;

          &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

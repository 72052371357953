@media screen and (max-width: 768px) {
  .journey-cancellation {
    height: auto;
    background-color: #fff;
    background-image: none;
    margin-top: -30px;
    padding: 0;
    .col-10 {
      max-width: 100%;
      padding: 0;
    }
    &__card-title {
      &.section-title {
        font-size: 24px;
      }
    }
    &__card-text {
      font-size: 14px;
    }
    &__card-subtitle {
      font-size: 16px;
    }
    &__card-details-item {
      font-size: 14px;
    }
    &__card-bottom-info {
      padding: 0;
      background-color: transparent;
      margin-top: 2rem;
    }
  }
  .journey-cancellation__card {
    border: 0;
    box-shadow: none;
  }
  .journey-cancellation__card-info {
    padding: 0;
    .close {
      display: block;
      float: inherit;
      margin: 40px 0 30px;
      box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #fff;
      font-weight: 400;
      &:hover {
        box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.4);
      }
      &:focus {
        outline: none;
      }
    }
  }
  .journey-cancellation__card-bottom-info {
    .btn-wrapper {
      display: block;
      text-align: center;
      .link {
        margin-bottom: 16px;
        display: block;
      }
      button {
        display: block;
        margin: 0 auto;
        padding: 0 16px;
        min-width: 205px;
        &:not(:last-child) {
          margin: 0 auto 16px;
        }
      }
    }
  }
  .journey-cancellation-footer {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .journey-cancellation {
    .btn-wrapper {
      button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .journey-cancellation {
    &__title {
      font-size: 20px;
    }

    &__text {
      font-size: 12px;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $text-color;
      margin: 20px 0 10px 0;
    }

    &__details {
      list-style-type: disc;
      padding-left: 20px;
    }

    &__details-item {
      font-size: 12px;

      span {
        color: $text-color;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-height: 610px) {
  .journey-cancellation {
    .btn-wrapper {
      position: relative;
    }
  }
}

@media screen and (min-width: 768px) {
  .journey-cancellation {
    &__card-bottom-info {
      .btn-wrapper {
        .btn-secondary {
          order: 1;
        }
      }
    }
  }
}

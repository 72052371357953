@media screen and (max-width: 991px) {
  .sidebar {
    &__header {
      padding: 13px 15px;
    }

    &__body {
      padding: 0 15px;
    }
  }
}

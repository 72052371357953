.wcf-form {
  &__fields {
    margin-bottom: 48px;

    .wcf-form__field {
      &_hidden {
        display: none;
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
  &__note {
    text-align: right;
    margin: 16px 0;
  }

  &__button {
    width: 100%;
  }

  &_hidden {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .wcf-form {
    &__fields {
      margin-bottom: 32px;

      .wcf-form__field {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.program-description {
  padding: 20px 0 40px;

  &__row {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 360px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  &__content {
    color: #1e2232;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-title {
      font-weight: bold;
      font-size: 44px;
      line-height: 60px;
      margin-bottom: 28px;
    }

    &-subtitle {
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      margin-top: 48px;
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .why-course {
    &__title {
      font-size: 30px;
    }

    &__text {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 991px) {
  .why-course {
    padding: 300px 0 100px 0;
  }
}

@media screen and (max-width: 768px) {
  .why-course {
    padding: 420px 0 60px 0;

    &__title {
      font-size: 24px;
    }

    &__text {
      font-size: 16px;
    }
  }
}

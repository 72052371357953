.additional-information {
  background: no-repeat url('/img/checkout-bg.png');
  background-size: cover;

  .information__blcok {
    border-radius: 16px;
    border: solid 1px #e9e9e9;
    background-color: #ffffff;
    min-height: 149px;
    padding: 30px;
    height: 100%;

    .information__tile {
      font-family:
        Work Sans,
        sans-serif;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: $blue;
    }

    .information__text {
      font-size: 16px;
      line-height: 1.38;
      color: $text-color;
    }
  }

  .featured-in {
    padding-top: 80px;
    padding-bottom: 220px;

    &_with-button {
      padding-bottom: 100px;
    }

    &__title {
      font-family:
        Work Sans,
        sans-serif;
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: #31364e;
      margin-bottom: 30px;
    }

    &__box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 40px;
      background-color: white;
      border-radius: 16px;

      img {
        height: 60px;
      }
    }

    &__button {
      display: block;
      margin: 30px 0 0 auto;
      width: 80px;
      height: 80px;
      border-radius: 50%;

      img {
        margin-top: 5px;
        width: 34px;
        height: 34px;
      }
    }
  }
}

.volonteer-modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.25s,
    visibility 0.25s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #181a26;
    opacity: 0.5;
  }

  &__wrapper {
    position: relative;
    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: -47px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(61, 139, 232, 0.1);
    outline: none;
    border: none;
    transition: all 0.3s ease;

    &:focus {
      border: none;
      outline: none;
    }

    &:hover svg {
      transition: all 0.3s ease;

      & path {
        fill: $blue-active;
      }
    }
  }

  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 16px;
    width: 630px;

    &__close-button {
      //position: absolute;
      //top: 0;
      //right: -47px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #ffffff;
      border: none;
      padding: 0;
      transform: rotate(45deg);
      box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 3px;
        background-color: #6f7283;
        border-radius: 2px;
        transition: background-color 0.25s;
        box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);
      }

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        transform: rotate(90deg) translate(-2px, 7px);
      }

      &:hover {
        outline: none;

        &:before,
        &:after {
          background-color: #3d8be8;
        }
      }

      &:active {
        outline: none;

        &:before,
        &:after {
          background-color: #2465b3;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__wrapper,
  &__body {
    @media (min-width: 991px) {
      height: 80vh;
    }
  }
}

._active-modal {
  opacity: 1;
  visibility: visible;
}

.volonteer-header {
  padding: 30px 70px 20px 70px;
  text-align: center;
  box-shadow: 0px 5px 25px rgba(61, 139, 232, 0.2);

  &__title {
    font-family: Lora;
    font-size: 28px;
    line-height: 40px;
    color: #31364e;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    line-height: 18px;
  }
}

.volonteer-content {
  padding: 25px 21px 25px 30px;
  overflow-y: auto;
  margin: 5px 5px 5px 0;

  &::-webkit-scrollbar {
    width: 4px;
    display: block;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c5cc;
    border-radius: 2px;
  }
}

.item-volonteer {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &_instructor {
  }

  & .custom-input {
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;

    &::placeholder {
      color: #9598a6;
    }

    color: #31364e;
  }

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  & .select2-selection {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    color: #9598a6;
    font-size: 14px;
    outline: 0;
    min-height: 48px;
    text-align: left;
    border-radius: 8px;
    border: 1px solid #e9e9e9;

    @media (max-width: 560px) {
      min-height: 40px;
    }
  }

  & .select2-selection__rendered {
    margin: 10px 10px 10px 5px;
    color: #9598a6 !important;
  }

  &.select2-dropdown--below {
    border-color: #e9e9e9 !important;
  }

  & .select2-selection__arrow {
    margin: 10px;
  }
}

.item-volonteer__instructor {
  &_select {
    width: 100%;

    & .select2 {
      width: 100%;
      border-radius: 8px;
      padding: 13px 15px;
      border: 1px solid #c4c5cc;
      font-size: 14px;
      line-height: 18px;
      color: #9598a6;
    }

    &:hover {
      border-color: $blue-hover;
    }

    &:focus {
      outline: none;
      border-color: $blue-active;
    }
  }

  &_placeholder {
  }
}

.volonteer-modal__footer {
  padding: 25px 30px;
  background: rgba(237, 153, 78, 0.1);
  display: flex;
  justify-content: flex-end;
}

.volonteer-content__item_multiselect_body .select2-selection {
  transition: all 0.3s ease;

  &:focus {
    border-color: $blue-active !important;
  }
}

// override global style
.select2-dropdown {
  border-color: $blue-active !important;

  &:focus {
    border-color: blue !important;
  }

  color: #34495e;
}

.volonteer-content__item_multiselect_body .select2-selection__rendered {
  min-height: 45px;
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
  padding: 10px 15px !important;

  @media (max-width: 560px) {
    padding: 5px 10px !important;
    min-height: 40px;
  }
}

.volonteer-content__item_multiselect_body
  .select2.select2-container
  .select2-selection--multiple {
  height: auto;
}

.volonteer-content__item_multiselect_body .select2-search__field {
  position: absolute !important;
  outline: none !important;
  resize: none;
  top: 0;
  left: 15px;
  transform: translateY(29%);
  width: 60% !important;
  z-index: 1;

  @media (max-width: 560px) {
    margin-top: 1px !important;
  }
}

.volonteer-content__item_multiselect_body .select2-selection__choice {
  color: #31364e;
  font-family: Work Sans !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: 0 !important;
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  align-items: center;
  background: #f7f7f7 !important;
  border-radius: 4px !important;
  padding: 0 8px !important;
  border: none !important;
  min-height: 25px !important;
  z-index: 10;

  &__remove {
    border: none;
    outline: none;
    background: #f7f7f7;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 1399px) {
  .volonteer-content {
    max-height: 360px;
    padding: 20px 16px 20px 25px;
  }

  .volonteer-header {
    padding: 20px 50px;
  }

  .volonteer-modal__footer {
    padding: 20px 25px;
  }
}

@media (max-width: 991px) {
  .volonteer-header__title {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 768px) {
  .volonteer-modal__body {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0);
    background-color: #ffffff;
    border-radius: 0;
    width: 100%;
    height: 100vh;
    padding: 50px 20px;
    overflow-y: auto;
    max-height: unset;
    padding-top: 0;
  }

  .volonteer-header {
    margin-top: 82px;
    box-shadow: none;
    text-align: left;
    padding: 0;
    margin-bottom: 30px;
  }

  .volonteer-content {
    max-height: none;
    padding: 0;
    margin: 0;
  }

  .volonteer-modal__wrapper {
    overflow: hidden;
    max-height: unset;
  }

  .item-volonteer__label {
    font-size: 14px;
    line-height: 22px;
  }

  .volonteer-modal__footer {
    padding: 30px 0;
    background: inherit;

    .btn-secondary {
      width: 100%;
      justify-content: center;
    }
  }

  .volonteer-modal__close {
    top: 30px;
    left: 0;
    box-shadow: 0px 5px 25px rgba(61, 139, 232, 0.2);
  }
}

@media (max-width: 560px) {
  .item-volonteer {
    & .custom-input {
      padding: 10px;
    }
  }
}

@media (max-width: 375px) {
  .item-volonteer__label {
    font-size: 14px;
    line-height: 18px;
  }

  .item-volonteer:not(:last-child) {
    margin-bottom: 15px;
  }

  .volonteer-header__title {
    font-size: 21px;
  }
}

.program-founder {
  position: relative;

  &__container {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  &__image {
    position: absolute;
    z-index: -1;

    img {
      object-fit: cover;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      background: linear-gradient(
        140deg,
        #edf5fc 7.53%,
        rgba(237, 245, 252, 0) 70%
      );
    }
  }

  &__image,
  &__image img,
  &__image:after {
    width: 100%;
    height: 100%;
  }

  &__author,
  &__text {
    color: white;
  }

  &__author {
    font-size: 18px;
    line-height: 28px;
    margin: 0;

    &_title {
      opacity: 0.5;
    }
  }

  &__text {
    margin: 10px 0 0;
    font-family: Lora, sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 30px;
    line-height: 48px;
  }
}

.marma-practitioner-training {
  .banner {
    background-image: url(/img/marma-training-banner.jpeg);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    @media screen and (max-width: 767px) {
      background-image: url(/img/marma-training-banner-mobile.jpeg);
      background-position: center bottom;
    }
    .container {
      margin-left: auto;
      margin-right: auto;
      max-width: 1440px;
      height: 588px;
      padding: 50px 100px;
      @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
        height: auto;
      }
    }
    .banner-title {
      font-family: Lora;
      font-weight: 700;
      font-size: 48px;
      line-height: 1;
      max-width: 598px;
      margin: 20px 0;
      @media screen and (max-width: 767px) {
        font-size: 28px;
      }
    }
    .register-button {
      width: 284px;
      height: 46px;
      background: linear-gradient(#ffd16f, #ff865b);
      box-shadow: none;
      text-shadow: none;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      font-family: Work Sans;
      border: none;
      border-radius: 10px;
      color: white;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }
    .banner-features {
      padding-top: 20px;
      margin-bottom: 20px;
      li {
        & + li {
          margin-top: 20px;
        }
        &:before {
          content: '\2713';
          background: #5b86ec;
          color: white;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          line-height: 19px;
          font-size: 14px;
          display: inline-block;
          margin-right: 10px;
          text-align: center;
        }
      }
    }
    .training-eligibility-text {
      margin-top: 40px;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  .container {
    &.marma-training-section {
      max-width: 1270px;
    }
  }
  .marma-heal-box {
    font-family: Lora;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    max-width: 495px;
    line-height: 1.4;
    background-image: url(/img/marma-training-eclipse.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    margin-top: 220px;
    @media screen and (max-width: 991px) {
      margin-top: 50px;
      font-size: 28px;
      background-size: contain;
    }
  }
  .marma-heal-content {
    margin-top: 120px;
    @media screen and (max-width: 991px) {
      margin-top: 50px;
    }
    p {
      margin: 15px 0;
    }
  }
  .section-title {
    font-family: Lora;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 1.4;
    margin: 15px 0;
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }
  .marma-therapy-content {
    padding-right: 200px;
    @media screen and (max-width: 991px) {
      padding-right: 0px;
      margin-top: 50px;
    }
    p {
      margin: 15px 0;
    }
  }
  .marma-therapy-image {
    margin-top: 60px;
    @media screen and (max-width: 991px) {
      margin-top: 110px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
    img {
      width: 440px;
      border-radius: 14px;
      max-width: 100%;
      height: auto;
    }
  }
  .marma-benefit-section {
    background: linear-gradient(#fff, #e7efff);
    @media screen and (max-width: 991px) {
      padding: 50px 0;
    }
    .container {
      max-width: 910px;
      text-align: center;
    }
    p {
      margin: 15px 0;
    }
  }
  .participant-experience-section {
    padding-top: 50px;
    .section-title {
      text-align: center;
    }
    .participant-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1050px;
      margin: auto;
      padding-top: 50px;
      @media screen and (max-width: 991px) {
        justify-content: space-around;
      }
      @media screen and (max-width: 767px) {
        justify-content: center;
        justify-content: space-between;
      }
    }
    .participant-item {
      border: 1px solid #ccc;
      border-radius: 14px;
      background-color: white;
      width: 308px;
      height: 385px;
      text-align: center;
      padding: 50px 18px 18px 18px;
      margin-bottom: 60px;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    .participant-picture {
      img {
        width: 73px;
        height: 73px;
        border-radius: 50%;
        border: #ccc 1px solid;
      }
    }
    .participant-name {
      font-weight: 700;
      font-family: 'Work Sans';
      font-size: 14px;
      margin-top: 15px;
    }
    .participant-position {
      font-size: 14px;
      color: rgb(216, 216, 216);
      font-weight: 700;
    }
    .participant-rating {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      span {
        margin: 0 3px;
        width: 18px;
        height: 18px;
        display: block;
        background: url(/img/marma-rating-start.png) no-repeat top center;
      }
    }
    .participant-message {
      font-weight: 400;
      font-family: 'Work Sans';
      font-size: 14px;
      font-style: italic;
      margin-top: 15px;
    }
  }
  .what-expect-section {
    background: linear-gradient(#fff, #e7efff);
    @media screen and (max-width: 991px) {
      padding: 50px 0;
    }
    .section-title {
      text-align: center;
      margin-bottom: 70px;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .marma-expect-item {
      @media screen and (max-width: 767px) {
        margin-bottom: 40px;
      }
    }
    .marma-expect-text {
      max-width: 275px;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
  .marma-energy-treatment {
    @media screen and (max-width: 991px) {
      padding: 50px 0;
    }
    .energy-treatment-image {
      img {
        border-radius: 14px;
        width: 467px;
        max-width: 100%;
        height: auto;
      }
    }
  }
  .marma-syllabus {
    .section-title {
      text-align: center;
      margin-bottom: 50px;
    }
    .col-md-6 {
      &:nth-of-type(odd) {
        padding-right: 40px;
        @media screen and (max-width: 767px) {
          padding-right: 0;
        }
      }
    }
    .syllabus-item {
      margin-bottom: 50px;
    }
    .note,
    .eligibility-info {
      text-align: center;
      max-width: 943px;
      margin: auto;
      margin-top: 20px;
    }
    .eligibility-info {
      font-size: 14px;
    }
  }
  .marma-practitioner-section {
    background: url(/img/marma-practitioner-section-bg.jpeg) no-repeat center;
    background-size: cover;
    text-align: center;
    @media screen and (max-width: 991px) {
      padding: 50px 0;
    }
    .learn-how-text {
      color: rgb(91, 134, 236);
      font-family: Lora;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
    }
    .view-upcoming-button {
      background: linear-gradient(#ffd16f, #ff865b);
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      font-family: Work Sans;
      color: white;
      border: none;
      width: 320px;
      height: 46px;
      border-radius: 10px;
      &:hover,
      &:focus {
        background: linear-gradient(#f26d45, #f2ba54);
        outline: none;
      }
      &:active {
        background: linear-gradient(#ffd16f, #ffd16f);
        outline: none;
      }
      @media screen and (max-width: 767px) {
        width: 280px;
      }
    }
  }
  .about-art-of-living--section {
    background: linear-gradient(#fff, #e7efff);
    @media screen and (max-width: 991px) {
      padding: 50px 0;
    }
    .container {
      max-width: 930px;
    }
    .section-title {
      text-align: center;
      margin-bottom: 70px;
    }
    .marma-about-aol-item {
      text-align: center;
    }
    .marma-about-aol-title {
      font-weight: 700;
      font-family: 'Work Sans';
      font-size: 20px;
      margin-top: 20px;
    }
    .marma-about-aol-text {
      font-size: 14px;
    }
  }
}

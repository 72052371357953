.full-testimonial {
  &__body {
    display: flex;
    border: solid 1px #e9e9e9;
    width: 946px;
    padding: 30px 30px 25px;

    &__media {
      position: relative;
      width: 360px;
      height: 220px;
      background: linear-gradient(90deg, #d0e1f0 -26%, #89beec 100%);
      border-radius: 12px;
      overflow: hidden;
      flex-shrink: 0;

      img,
      video {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &__name {
        position: absolute;
        color: #ffffff;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
        font-size: 48px;
        font-weight: 500;
        letter-spacing: 0.8px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 30px;

      &__quote {
        font-size: 20px;
        font-weight: 600;
        font-style: italic;
        line-height: 1.3;
        color: #31364e;
        margin-bottom: 10px;
      }

      &__text {
        font-size: 16px;
        font-style: italic;
        line-height: 1.38;
        color: #31364e;
        margin-bottom: 25px;

        p:not(:last-child) {
          margin-bottom: 5px;
        }

        &__link {
          margin-top: 5px;
          text-decoration: none;
          border-bottom: 1px dotted #3d8be8;

          &:hover {
            border-color: #89beec;
          }

          &:active {
            color: #2465b3;
            border-color: #2465b3;
          }
        }
      }

      &__person {
        margin: auto 0 0;
        font-size: 12px;
        line-height: 1.33;

        &__name {
          font-weight: 600;
          color: #31364e;
        }

        &__about {
          font-weight: 500;
          color: #9598a6;
          margin-top: 5px;
        }
      }

      &__tags {
        position: absolute;
        z-index: 10;
        bottom: calc(100% - 230px);
        left: 50px;
        width: 320px;

        span {
          display: inline-block;
          padding: 5px 10px;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.6);
          font-size: 12px;
          font-weight: 500;
          color: #31364e;
          white-space: nowrap;
          margin-bottom: 5px;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

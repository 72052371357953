.quote-section {
  padding: 160px 0;
  background-image: url('/img/sri-sri.png');
  background-size: cover;
  background-position-y: 40%;

  &__name {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    color: white;
    margin-left: 45px;

    span {
      display: block;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  &__quote {
    display: flex;
    margin-top: 13px;
    font-size: 60px;
    font-weight: 600;
    line-height: 1.36;
    color: #ffffff;

    span {
      margin-left: 15px;
      margin-top: 15px;
      font-size: 22px;
      font-weight: 400;
      font-style: italic;
      max-width: 458px;
    }
  }
}

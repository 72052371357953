.life-time {
  padding: 100px 0;
  background-image: url('/img/life-time-bg.png');
  background-size: cover;

  &__block {
    max-width: 458px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.38;
    color: #31364e;

    h6 {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .btn-secondary {
    margin-top: 40px;
  }

  &.silent-retreat {
    background-image: url('/img/energy-focus-bg.png');
    background-position-y: 80%;

    .life-time__title {
      font-size: 30px;
    }
  }
}

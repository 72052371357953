@media screen and (max-width: 991px) {
  .insight-collection {
    .top-column.meditation-page.insight-collection {
      padding: 77px 20px 0 20px !important;

      &.insight-collection3 {
        margin-bottom: 50px;
      }
    }

    .top-column.meditation-page.insight-collection
      .container-fluid
      .row
      .col-12
      .course-description {
      font-size: 16px;
      line-height: 1.38;
    }

    .top-column.what-meditation {
      padding: 100px 20px 90px;
      margin-bottom: 39px;

      .container .card {
        width: 100%;
      }
    }

    .top-column.what-meditation .container .card {
      .card-title {
        font-size: 16px;
      }

      .card-text {
        font-size: 14px;
        line-height: 1.57;
        margin-top: 8px;
      }

      &.guide-meditation {
        padding: 25px 20px;
        margin-top: 16px;
      }
    }

    .btn_outline_box {
      min-width: 100%;
    }

    .search_course_form_mobile {
      margin-bottom: 38px;
    }

    .browse-category.most-popular {
      margin-top: 38px;
    }

    .browse-category .swiper-container {
      margin-top: 18px;
    }
  }

  .insight-collection2 {
    &.top-column.meditation-page.insight-collection {
      padding: 60px 20px !important;

      .course-name {
        margin-bottom: 14px;
      }

      .container-fluid .row .col-12 {
        .type-guide {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          font-size: 16px;
          line-height: 1.38;
          margin-bottom: 5px;

          img {
            margin-bottom: 8px;
          }
        }

        .course-description {
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 19px;
        }
      }

      .accordion .card .card-header {
        margin-bottom: 5px;
      }

      .collection-video {
        margin: 23px 0 20px;

        .video-insighter-container video {
          height: 180px;
        }

        .collection-video-details img.video-expand {
          right: 20px;
          top: 18px;
        }
      }

      .collection-video-details {
        span {
          margin-bottom: 0px;
        }

        .title {
          margin-bottom: 2px;
        }
      }

      .title-modules {
        font-size: 22px;
      }

      #video-insighter-list .category-slide-item {
        margin-bottom: 15px;
      }
    }
  }
}

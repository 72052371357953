@import '../variables';

.modal-dialog {
  max-width: 620px;
  @media screen and (max-width: 720px) {
    max-width: none;
  }

  .modal-content {
    padding: 40px 25px 30px;
    border-radius: 8px;

    .logo {
      display: none;
    }

    .modal-header {
      display: flex;
      justify-content: center;
      font-family: Lora;
      border: 0;
      padding: 0;
      font-size: 30px;
      line-height: 1.33;

      .video-shrink {
        display: none;
      }
      .video-full-play,
      .video-full-pause {
        opacity: 0;
        transition: opacity 0.3s ease-out;
      }
      .close {
        color: $grey;
        right: -47px;
        top: 0;
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: $backgrounds;
        border-radius: 50%;
        padding: 0;
        margin: 0;
        opacity: 1;
      }
    }

    .modal-body {
      font-size: 16px;
      padding: 0;
      margin: 25px 0 30px;
      line-height: 1.38;

      .description {
        margin-bottom: 20px;
      }

      .date {
        font-weight: 600;
        margin-bottom: 10px;
      }

      ul {
        line-height: 1.8;
      }

      ul li {
        position: relative;
        padding-left: 15px;
        font-size: 14px;
        line-height: 1.43;
        margin-bottom: 10px;
        &::before {
          content: '';
          background-color: $orange;
          font-weight: bold;
          display: inline-block;
          width: 4px;
          height: 4px;
          position: absolute;
          left: 0;
          top: 8px;
          border-radius: 10px;
        }
      }
      .modal-gray-text {
        color: #9598a6;
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
      }
    }

    .card-wrapper {
      display: flex;
      font-weight: 600;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      .card {
        width: 275px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        border-radius: 8px;
        border: 1px solid #e9e9e9;

        &.full {
          width: 100%;
        }
        .card-body {
          padding: 0;
          font-size: 18px;
          font-weight: 600;

          .card-title {
            width: 100%;
            background-color: $light-grey-2-opacity;
            padding: 10px 0 12px;
            line-height: 1.56;
            margin-bottom: 15px;
          }

          .card-text {
            line-height: 1.56;
          }

          .prev-price {
            color: $red-error;
            text-decoration: line-through;
            font-weight: normal;
          }

          .btn {
            width: 225px;
            height: 50px;
            margin: 15px 0 25px;
            color: $text-color;
            border-radius: 16px;
            border: $black-border;

            &:hover {
              background: $text-color;
              color: $white;
            }
          }

          .btn-preffered {
            background: $button-secondary;
            color: $white;
            border: 0;

            &:hover {
              background: $button-secondary-hover;
            }
            &:active {
              background: $button-secondary-active;
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
        &.full {
          width: 100%;
        }
        & ~ .card-preffered {
          box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.05);
        }
      }

      .card-preffered {
        .card-body .card-title {
          background-color: $orange-opacity;
        }
      }
    }
  }
}

.modal {
  &.locked {
    .modal-content {
      padding: 0 !important;
      @media screen and (max-width: 720px) {
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
      }
      .card-preffered {
        border: none;
      }
      .modal-header {
        padding: 40px 25px 0 !important;
        @media screen and (max-width: 720px) {
          padding-top: 20px !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
      .modal-body {
        padding: 0 25px !important;
        margin: 10px 0 20px !important;
        @media screen and (max-width: 720px) {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
      .card-title {
        margin: 0 !important;
        text-align: right;
        padding: 0 !important;
        button {
          margin: 25px !important;
          font-weight: bold;
        }
      }
    }
  }
  &.modal_video {
    backdrop-filter: blur(12px);
    background-color: rgba(49, 54, 78, 0.2);
    .modal-dialog {
      .modal-content {
        border-radius: 16px;
        box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
        padding: 0;
        &:hover {
          .video-insighter-progress {
            .played {
              &:after {
                opacity: 1 !important;
              }
            }
          }
        }
        .modal-header {
          height: 0;
        }
        .modal-body {
          margin: 0;
          .video-container {
            font-size: 0;
          }
          video {
            width: 100%;
            border-radius: 16px 16px 0 0;
            object-fit: cover;
          }

          .video-insighter-bar {
            position: relative;
            height: 5px;
            margin-top: -5px;
            z-index: 100;

            &.hidden {
              opacity: 0;
            }
            .video-insighter-progress {
              position: absolute;
              left: 0;
              bottom: 8px;
              margin: 0;
              width: 100%;
              z-index: 999;
              transition: all 0.15s ease-out;
              cursor: pointer;
              & > div {
                position: absolute;
                left: 0;
                top: 4px;
              }
              .loaded {
                z-index: 1;
                height: 4px;
                background-color: #f0f0f0;
                opacity: 0.6;
                border-radius: 2px;
                transition: all 0.15s ease-out;
              }
              .played {
                flex-direction: row-reverse;
                z-index: 2;
                height: 4px;
                background-color: #3d8be8;
                cursor: pointer;
                max-width: 100%;
                transition: all 0.15s ease-out;
                &:after {
                  opacity: 0;
                  content: '';
                  display: flex;
                  position: absolute;
                  box-sizing: border-box;
                  top: -9px;
                  right: -7px;
                  margin-right: -4px;
                  width: 25px;
                  height: 25px;
                  border-radius: 25px;
                  background-color: #fff;
                  box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
                  border: solid 1px #e9e9e9;
                }
              }
              &:hover {
                .played {
                  &:after {
                    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
                    border: solid 1px #89beec;
                  }
                }
              }
              &:focus {
                .played {
                  &:after {
                    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
                    border: solid 1px #3d8be8;
                  }
                }
              }
            }
          }

          .video-details {
            padding: 20px 20px 20px 70px;
            .video-play {
              position: absolute;
              left: 20px;
              cursor: pointer;
            }
            .video-expand {
              position: absolute;
              right: 20px;
              cursor: pointer;
            }
            span {
              font-size: 12px;
              color: #6f7283;
              margin-bottom: 4px;
            }
            .video-title {
              line-height: 1.3;
              font-weight: 600;
              font-size: 16px;
              color: #31364e;
              margin-bottom: 5px;
            }
            p {
              font-size: 12px;
              line-height: 1.33;
              color: #31364e;
            }
          }
        }
      }
    }
    &.full {
      padding: 0 !important;
      &:hover {
        .video-full-play,
        .video-full-pause,
        .video-insighter-bar {
          z-index: 2;
          opacity: 1 !important;
        }
      }
      .modal-header {
        .close {
          position: fixed;
          top: 30px;
          right: 30px;
          z-index: 2;
        }
        .video-shrink {
          display: block;
          position: fixed;
          top: 30px;
          right: 82px;
          z-index: 2;
          cursor: pointer;
          border: 0;
          padding: 0;
          font-size: 0;
          background-color: transparent;
          img {
            padding-left: 8px;
          }
          &:focus {
            outline: none;
          }
          &:before {
            content: '';
            width: 32px;
            height: 32px;
            background-color: #fff;
            position: absolute;
            display: block;
            z-index: -1;
            border-radius: 16px;
          }
        }
        .video-full-play,
        .video-full-pause {
          position: fixed;
          display: block;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          background-color: #fff;
          border-radius: 60px;
          width: 60px;
        }
      }
      .video-container {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        font-size: 0;
        video {
          height: 100%;
          border-radius: 0 !important;
        }
        .video-insighter-bar {
          opacity: 0;
          transition: opacity 0.3s ease-out;
          bottom: 20px;
        }
      }
    }
  }
}

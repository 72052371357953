.overflow-scroll {
  overflow-y: scroll;
}

.scheduling-modal {
  z-index: 15;
  width: 100%;
  height: max-content;
  margin: auto auto;

  font-family:
    Work Sans,
    sans-serif;

  @media (min-width: 1200px) {
    max-width: 1144px;
    padding-bottom: 100px;
    padding-top: 100px;
  }

  &__template {
    display: grid;
    grid-template-columns: 689px 463px;
    column-gap: 24px;

    &.third {
      grid-template-columns: 1fr !important;
      width: 100%;
    }

    @media screen and (max-width: 1240px) {
      grid-template-columns: calc(60% - 12px) calc(40% - 12px);
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  &__total {
    @media screen and (min-width: 991px) {
      grid-row: 1/4;
      grid-column: 2/3;
    }
  }

  &__wrapper {
    padding: 24px 16px;
    border-radius: 20px;
    background: #fef5ec;
  }

  &__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // gap: 24px;

    // @media (min-width: 1200px) {
    //   gap: 40px;
    // }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (min-width: 1200px) {
      & svg {
        height: 68px;
        width: 68px;
      }
    }

    & .scheduling-modal__btn-close svg {
      width: 32px;
      height: 32px;
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 12px;
    }
  }

  &__header-text {
    margin-left: 16px;

    & h3 {
      color: #0e0e0e;
      font-family: Lora, sans-serif;
      font-size: 40px;
      line-height: 40px;

      @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 24px;
        & br {
          display: none;
        }
      }
    }

    & p {
      margin-top: 12px;

      color: #262627;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;

      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 16px;
      }
    }

    &--final-step {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-left: 0;
    }
  }

  &__content-wrapper {
    padding: 24px;
    margin-bottom: 24px;
    width: 100%;
    height: max-content;

    border-radius: 24px;
    background: #fff;

    color: #0e0e0e;

    font-size: 32px;

    & h3 {
      color: #0e0e0e;
      font-family: Lora, sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;

      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
    }

    & h4 {
      margin-top: 16px;
      font-size: 24px;
      line-height: 32px;
      font-family: Lora, sans-serif;
    }

    & p {
      margin-top: 8px;

      color: #262627;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }

    & hr {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &-required {
      color: #262627;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;

      &:before {
        content: '*';
        color: #ff9e1b;
        margin-right: 5px;
      }
    }

    &-login {
      margin-top: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      & span:first-child,
      & a {
        color: #262627;
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      & a {
        color: #fca248;
        font-weight: 500;
        text-decoration-line: underline;
      }
    }

    &-form-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;

      margin-top: 12px;

      @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
      }

      &.credit-card {
        display: none;
        grid-template-columns: 1fr 1fr 1fr;

        &.show {
          display: grid;
        }

        li:first-child {
          grid-column: 1/4;
        }
      }

      & li {
        position: relative;
      }

      & input.text-input {
        width: 100%;

        height: 48px;
        padding: 20px;

        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        color: rgba(38, 38, 39, 0.9);
        font-family:
          Work Sans,
          sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;

        &::placeholder {
          color: #fff;
        }
      }

      &
        input.text-input:not(:placeholder-shown)
        + label.label-placeholder-style,
      & input.text-input:focus + label.label-placeholder-style {
        top: -5px;
        transform: translateY(0);
        background-color: #fff;
        padding: 0px 3px;
      }

      & label.label-placeholder-style {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;

        color: rgba(38, 38, 39, 0.8);
        font-family:
          Work Sans,
          sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;

        transition: all 0.35s ease;

        @media (min-width: 1200px) {
          font-size: 16px;
          line-height: 24px;
        }

        &.required:after {
          content: '*';
          color: #ff9e1b;
          margin-left: 5px;
        }
      }

      & .pay-row {
        display: flex;

        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        padding: 16px 24px;

        &:has([type='radio']:checked) {
          border-color: #ff9e1b;
        }

        & .input-group {
          align-items: center;
        }

        & label {
          color: #fca248;
          font-family:
            Work Sans,
            sans-serif;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;

          @media (min-width: 1200px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        & [type='radio']:checked,
        & [type='radio']:not(:checked) {
          position: absolute;
          left: -9999px;
        }

        & [type='radio']:checked + label,
        & [type='radio']:not(:checked) + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          display: inline-block;
        }

        & [type='radio']:checked + label:before,
        & [type='radio']:not(:checked) + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0px, -50%);
          width: 18px;
          height: 18px;
          border-radius: 100%;
          background: #fef5ec;
        }

        & [type='radio']:checked + label:after,
        & [type='radio']:not(:checked) + label:after {
          content: '';
          width: 12px;
          height: 12px;
          background: #fca248;
          position: absolute;
          top: 50%;
          left: 3px;
          border-radius: 100%;
          transition: all 0.2s ease;
        }

        & [type='radio']:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }

        & [type='radio']:checked + label:after {
          opacity: 1;
          transform: scale(1) translate(0, -50%);
        }
      }
    }

    &-form-checkbox {
      & label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        gap: 16px;

        color: #262627;
        font-family:
          Work Sans,
          sans-serif;
        font-size: 12px;
        line-height: 20px;

        & a {
          color: #fca248;
          text-decoration-line: underline;
        }

        &:before {
          content: '';
          background-color: transparent;
          border: 2px solid #ff9e1b;
          border-radius: 50%;
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;
        }
      }

      & input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;

        &:checked + label:before {
          background-color: #ff9e1b;
        }

        &:checked + label:after {
          content: '';
          display: block;
          position: absolute;
          top: 4px;
          left: 9px;
          width: 6px;
          height: 14px;
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__content-calendar {
    margin-top: 24px;

    // @media (min-width: 1200px) {
    //   margin-top: 36px;
    // }

    & .flatpickr-calendar {
      box-shadow: none;
      width: 100%;

      &.arrowTop:before {
        content: none;
      }
    }

    & .flatpickr-months {
      align-items: center;

      & .flatpickr-month {
        order: 1;
        text-align: left;

        & .flatpickr-current-month {
          width: auto;
          left: auto;
          position: static;
        }
      }

      & .flatpickr-prev-month {
        order: 2;
      }

      & .flatpickr-next-month {
        order: 3;
        margin-left: 24px;
      }

      & .flatpickr-prev-month,
      & .flatpickr-next-month {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 0.5px solid #fca248;

        & svg {
          width: 12px;
          height: 12px;

          & path {
            fill: #fca248;
          }
        }
      }

      & .cur-month,
      & .cur-year {
        color: #000;
        font-family:
          Work Sans,
          sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
      }
    }

    & .flatpickr-days {
      padding: 0 34px;

      @media screen and (max-width: 991px) {
        padding: 0 16px;
      }
    }

    & .dayContainer {
      column-gap: 40px;
      row-gap: 12px;
      margin-top: 12px;

      @media screen and (max-width: 991px) {
        column-gap: 10px;
      }
    }

    & .flatpickr-days,
    & .dayContainer {
      width: 100%;
      min-width: auto;
      max-width: none;
    }

    & .flatpickr-day {
      width: 44px;
      max-width: 44px;
      height: 44px;
      // max-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      color: #fca248;
      border-radius: 12px;
      border: 1px solid rgba(252, 162, 72, 0.3);

      font-family: Work Sans;
      font-size: 20px;
      line-height: 44px;
      text-transform: uppercase;

      @media screen and (max-width: 991px) {
        height: 30px;
        width: 30px;
        max-width: 30px;
        max-height: 30px;
        border-radius: 4.38px;

        font-size: 14px;
        line-height: 30px;
      }

      &.today {
        border-color: rgba(252, 162, 72, 0.3);
      }

      &.prevMonthDay,
      &.nextMonthDay {
        visibility: hidden;
      }
    }

    & .flatpickr-weekdays {
      height: auto;

      & .flatpickr-weekdaycontainer {
        margin-top: 12px;
        padding: 0 34px;

        border-radius: 15px;
        background: #fef5ec;

        @media (min-width: 1200px) {
          justify-content: space-between;
        }

        @media screen and (max-width: 991px) {
          padding: 0 16px;
          border-radius: 4px;
        }

        & .flatpickr-weekday {
          color: #fca248;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-transform: uppercase;
          padding: 6px 0;

          @media (min-width: 1200px) {
            padding: 0;

            width: calc(calc(100% / 7) - 20px);
            max-width: calc(calc(100% / 7) - 20px);
            height: 44px;
            aspect-ratio: 1/1;

            display: flex;
            align-items: center;
            justify-content: center;
          }

          @media screen and (max-width: 991px) {
            padding: 8px 0;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    & .flatpickr-day.selected {
      color: #000;
      border-radius: 4.375px;
      background: #fca248;
      border-color: #fca248;

      @media (min-width: 1200px) {
        border-radius: 10px;
      }

      &:hover {
        background: #fca248 !important;
        border-color: #fca248 !important;
      }

      &.first::before {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        left: -8px;
      }

      &.last::before {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        right: -8px;
      }

      &:before {
        z-index: -1;
        position: absolute;
        content: '';
        width: 90px;
        height: 56px;
        background: #fef5ec;

        @media screen and (max-width: 991px) {
          height: 38px;
        }
      }
    }
  }

  &__content-country-select {
    & label,
    & .select2-container {
      width: 100% !important;

      & .select2-selection--single {
        height: auto;

        & .select2-selection__rendered {
          padding-left: 35px;
          padding-right: 10px;

          color: #000;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;

          white-space: break-spaces;

          @media (min-width: 1200px) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      & .select2-container--default .select2-selection--single {
        position: relative;
        padding: 16px;

        border-radius: 44px;
        border: 0.5px solid #fca248;

        &:before {
          content: url('/img/worldwide.png');
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }

        & .select2-selection__arrow {
          top: 50%;
          transform: translate(0, -50%);

          & b {
            border-color: #000000 transparent transparent transparent;
            top: 50%;
            transform: translate(0, -50%);
            margin-top: 0;
            left: 0;
            margin-left: 0;
          }
        }
      }
    }

    & .select2-container--open {
      & .select2-selection--single {
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
    }
  }

  &__content-options {
    margin-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: 991px) {
      max-height: 560px;
      overflow-y: auto;

      padding-right: 10px;
      padding-bottom: 10px;

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #fef5ec;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #fca248;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #fca248b0;
      }
    }

    @media screen and (min-width: 1240px) {
      max-height: 350px;
    }
  }

  &__content-option {
    padding: 20px;
    color: #959595;
    font-size: 16px;
    line-height: 24px;

    &-title {
      & label {
        color: #fca248;
        font-weight: 600;
        text-transform: uppercase;
      }

      & [type='radio']:checked,
      & [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
      }

      & [type='radio']:checked + label,
      & [type='radio']:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        display: inline-block;
      }

      & [type='radio']:checked + label:before,
      & [type='radio']:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0px, -50%);
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background: #fef5ec;
      }

      & [type='radio']:checked + label:after,
      & [type='radio']:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: #fca248;
        position: absolute;
        top: 50%;
        left: 3px;
        border-radius: 100%;
        transition: all 0.2s ease;
      }

      & [type='radio']:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }

      & [type='radio']:checked + label:after {
        opacity: 1;
        transform: scale(1) translate(0, -50%);
      }
    }

    &-text-with-clock {
      margin-top: 14px !important;

      color: rgba(38, 38, 39, 0.6);
      font-family:
        Work Sans,
        sans-serif;
      font-size: 16px;
      line-height: 24px;

      &:before {
        content: url('/img/clock 1.svg');

        margin-right: 8px;
      }
    }

    &-variants {
      margin-top: 12px;
      display: flex;

      @media screen and (max-width: 1240px) {
        flex-direction: column;
        gap: 8px;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      @media screen and (max-width: 1240px) {
        flex-direction: row;
      }

      @media screen and (min-width: 1241px) {
        &:first-child + & {
          position: relative;
          margin: 0 auto;

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            background-color: rgba(252, 162, 72, 0.2);
            height: 100%;
            width: 0.5px;
          }

          &::before {
            left: -12px;
          }

          &::after {
            right: -12px;
          }
        }
      }

      &-date {
        border-radius: 10px;
        background: #fef5ec;
        padding: 8px 12px;
        width: 100%;
        text-align: center;
        color: #262627;
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        @media screen and (max-width: 1240px) {
          width: 80px;
        }
      }

      &-time {
        color: #262627;
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-top: 12px;
        line-height: 16px;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding-top: 12px;
      margin-top: 12px !important;
      border-top: 0.5px solid rgba(252, 162, 72, 0.2);

      &-text-with-icon {
        &:before {
          content: url('/img/user 1.svg');
          margin-right: 8px;
        }
      }
    }
  }

  &__apple-pay {
    width: 100%;
    border: none;

    border-radius: 44px;
    background: #000;

    color: white;
    font-size: 16px;

    display: flex;
    height: 40px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 8px;
  }

  &__continue {
    margin-top: 16px;
    width: 100%;
    border: none;

    display: flex;
    height: 40px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 44px;
    background: #fca248;

    color: #fcfaf8;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;

    @media (min-width: 1200px) {
      margin-top: 24px;
      line-height: 32px;
      width: 100%;
      &:focus {
        outline: 0;
      }
    }
  }

  &__content-total {
    border-radius: 16px;
    border: 1px solid #fca248;
    padding: 20px;
    margin-bottom: 24px;

    &-title {
      color: #fca248;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
    }

    &-time {
      color: #262627;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 16px;
      line-height: 24px;
    }

    &-date-time {
      display: flex;
      align-items: center;

      margin-top: 20px;

      & .scheduling-modal__content-option-text-with-clock {
        margin-top: 0 !important;
        margin-right: 16px;
      }
    }

    &-dates {
      margin-top: 8px;
      display: inline;
      align-items: center;
      justify-content: space-between;
    }

    &-date {
      text-transform: uppercase;
      padding: 8px 12px;

      color: #262627;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 12px;
      line-height: 16px;
      border-radius: 10px;
      background: #fef5ec;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      @media (min-width: 1200px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    & hr {
      border-color: rgba(252, 162, 72, 0.2);
      margin-top: 12px;
      margin-bottom: 12px;
    }

    &-instructors-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-instructors {
      color: rgba(38, 38, 39, 0.6);
      font-family:
        Work Sans,
        sans-serif;
      font-size: 16px;
      line-height: 24px;

      &:before {
        content: url('/img/user 1.svg');
      }
    }

    &-instructors-list {
      color: #262627;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 16px;
      line-height: 24px;
    }

    &-contacts {
      display: flex;
      align-items: center;
      width: 100%;

      color: rgba(38, 38, 39, 0.6);
      font-family:
        Work Sans,
        sans-serif;
      font-size: 12px;
      line-height: 12px;

      &:before {
        content: url('/img/phone 1.svg');
        margin-right: 8px;
      }

      @media screen and (max-width: 1200px) {
        display: block;

        & p {
          display: flex;
          flex-direction: column;
          margin-top: 12px !important;
          align-items: flex-start;
          gap: 0;
        }
      }
    }

    &-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      margin-top: 0 !important;
      margin-left: auto;

      & a {
        color: #fca248;
        font-family:
          Work Sans,
          sans-serif;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.7px;
        text-decoration-line: underline;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & span {
        color: rgba(38, 38, 39, 0.9);
        font-family:
          Work Sans,
          sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    &-contact {
      color: rgba(38, 38, 39, 0.8);
      font-family:
        Work Sans,
        sans-serif;
      font-size: 12px;
      line-height: 20px;

      & a {
        color: #ff9e1b;
        text-decoration-line: underline;
      }
    }
  }

  & hr.scheduling-modal__stroke {
    border-color: #fca248;
    width: 100%;
    margin: 0;
  }

  &__practices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;

      padding-top: 12px;
      margin-top: 12px;

      border-top: 0.5px solid #fca248;
    }
  }

  &__practice {
    border-radius: 24px;
    background: #fff;

    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    & div {
      flex: 2;

      & h5 {
        color: #fca248;
        font-family:
          Work Sans,
          sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
      }

      & p {
        margin-top: 8px;

        color: #262627;
        font-family:
          Work Sans,
          sans-serif;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__content-group {
    border-radius: 16px;
    border: 1px solid rgba(252, 162, 72, 0.4);

    display: flex;
    gap: 12px;
    padding: 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    & h3 {
      color: #fca248;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
    }

    & hr {
      margin-top: 16px;
      margin-bottom: 16px;
      border-color: rgba(252, 162, 72, 0.2);
      width: 100%;
    }

    &-date-time {
      color: rgba(38, 38, 39, 0.6);
      font-family:
        Work Sans,
        sans-serif;
      font-size: 14px;
      line-height: 24px;
      margin-top: 0 !important;
      display: flex;
      align-items: center;

      &:before {
        content: url('/img/sun.svg');
        margin-right: 8px;
      }
    }

    &-date-list {
      width: 100%;
      margin-top: 8px;
      display: flex;
      align-items: stretch;
      flex-direction: column;

      gap: 8px;

      & li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .date {
          text-align: center;
          padding: 8px 12px;
          border-radius: 10px;
          background: #fef5ec;

          width: 40%;

          color: #262627;
          font-family:
            Work Sans,
            sans-serif;
          font-size: 12px;
          line-height: 16px;
        }

        & .time {
          color: #262627;
          font-family:
            Work Sans,
            sans-serif;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &-instructors {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (min-width: 1200px) {
        justify-content: flex-start;
        gap: 95px;
      }

      & span {
        color: rgba(38, 38, 39, 0.6);
        font-family:
          Work Sans,
          sans-serif;
        font-size: 14px;
        line-height: 24px;

        &:before {
          content: url('/img/user 1.svg');
          margin-right: 8px;
        }
      }

      & ul {
        color: #262627;
        font-family:
          Work Sans,
          sans-serif;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-text-row {
      width: 100%;
      margin-top: 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: rgba(38, 38, 39, 0.6);
      font-family:
        Work Sans,
        sans-serif;
      font-size: 14px;
      line-height: 24px;

      @media (min-width: 1200px) {
        justify-content: flex-start;
      }

      & .value {
        color: #262627;
      }

      & .order {
        padding: 0;

        @media (min-width: 1200px) {
          margin-right: 75px;
        }

        &:before {
          content: url('/img/hash.svg');
          margin-right: 8px;
        }
      }

      & .course {
        padding: 0;

        @media (min-width: 1200px) {
          margin-right: 120px;
        }

        &:before {
          content: url('/img/hands.svg');
          margin-right: 8px;
        }
      }

      & .total {
        padding: 0;

        @media (min-width: 1200px) {
          margin-right: 100px;
        }

        &:before {
          content: url('/img/coin.svg');
          margin-right: 8px;
        }
      }

      &--contact {
        justify-content: flex-start;

        &:before {
          content: url('/img/phone 1.svg');
          margin-right: 8px;
        }
      }
    }

    &-link {
      margin-top: 8px;

      color: #fca248;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.7px;
      text-decoration-line: underline;

      @media (min-width: 1200px) {
        margin-right: 20px;
      }
    }
  }

  &__content-share {
    margin-top: 20px;
    margin-left: 8px;

    color: #0e0e0e;
    font-family: Lora, sans-serif;
    font-size: 20px;
    line-height: 24px;
  }

  &__content-social-icons {
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media (min-width: 1200px) {
      margin-left: 40px;
      justify-content: flex-start;
    }
  }
}

.select2-dropdown {
  border-color: #fca248;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #fef5ec;
  color: #fca248;
}

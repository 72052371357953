.profile-modal {
  .course-details-card__list {
    ul {
      list-style: none;
      li {
        line-height: 1.38;
        &:before {
          content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: #ed994e; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }

      li:not(last-child) {
        //margin-bottom: 7px;
      }
    }
  }
  form input {
    padding: 12px 15px;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    margin-bottom: 15px;
    font-size: 16px;
    color: #9598a6;
    transition: all 0.3s ease-in-out;
  }
}

.profile {
  .success-message-cp {
    font-size: 12px;
    color: green;
    text-align: center;
    margin: 0px auto 16px;
  }
  .userImg {
    border-radius: 108px;
    height: 100%;
    width: 100%;
    display: inline-block;
    background: #fff url('/img/profilePH.png') no-repeat 50% / cover;
    float: right;
  }
  input:read-only {
    color: #9598a6 !important;
    border-color: #9598a6 !important;
  }
  .validation-input {
    text-align: left;
    font-size: 12px;
    line-height: 1.33;
    color: #fa5a67;
  }
  form input.validate {
    border-color: #fa5a67;
  }

  // .cover-spin {
  //   position: absolute;
  //   width: 100%;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   background-color: rgba(255, 255, 255, 0.7);
  //   z-index: 9999;
  // }
  // @-webkit-keyframes spin {
  //   from {
  //     -webkit-transform: rotate(0deg);
  //   }
  //   to {
  //     -webkit-transform: rotate(360deg);
  //   }
  // }

  // @keyframes spin {
  //   from {
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     transform: rotate(360deg);
  //   }
  // }

  // .cover-spin::after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   left: 44%;
  //   top: 40%;
  //   width: 40px;
  //   height: 40px;
  //   border-style: solid;
  //   border-color: #3d8be8;
  //   border-top-color: transparent;
  //   border-width: 4px;
  //   border-radius: 50%;
  //   -webkit-animation: spin 0.8s linear infinite;
  //   animation: spin 0.8s linear infinite;
  //   margin-top: -20 px;
  //   margin-left: -20 px;
  // }
  .mt-0 {
    .StripeElement {
      margin-top: 0 !important;
    }
  }
  .StripeElement {
    outline: none;
    border: none;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    background-color: transparent;
    font-size: 16px;
    line-height: 2;
    color: #31364e;
    margin-top: 30px;
    transition: all 0.3s ease-in-out;
  }
}

.profile-pic-section {
  .wrapper {
    position: relative;
    .camera-icon {
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0s,
        opacity 0.5s linear;
      position: absolute;
      font-size: 40px;
      width: 100%;
      height: 100%;
      background-color: #ffffff9e;
      margin: auto;
      border-radius: 83px;
      color: #3d8be8;
      .fa {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
  label:hover {
    cursor: pointer;
    .wrapper .camera-icon {
      visibility: visible;
      opacity: 1;
    }
  }
  .profile-pic {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
#prodile-pic-crop-wrapper {
  width: 100%;
  td {
    vertical-align: middle;
    text-align: center;
  }
}
.btn-wrapper {
  margin-top: 25px;

  button {
    &:not(:last-child) {
      margin-right: 25px;
    }
  }
}
.profile-body__card-img {
  width: 100%;
}

.course-mode-duration {
  display: inline-flex;
  gap: 10px;
}

.journey-starts {
  background-image: url('/img/journey-starts-bg-new.png');
  // background-position-y: 100%;
  background-size: cover;
  padding: 100px 0;
  margin-bottom: 100px;

  .container {
    position: relative;
  }

  .program-details {
    position: absolute;
    right: 0;
    top: -140px;
    width: 458px;
    padding: 30px 25px;
    border-radius: 8px;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
    border: solid 1px #e9e9e9;
    background-color: #ffffff;

    &__title {
      font-size: 22px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #31364e;
      margin-bottom: 15px;
    }

    &__schedule {
      &:not(:last-child) {
        margin-bottom: 7px;
      }

      span {
        display: block;
      }

      &-date {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #6f7283;
      }

      &-time {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #6f7283;
      }
    }
  }

  &__title {
    font-size: 30px;
    margin-bottom: 30px;
  }

  &__step {
    display: flex;
    position: relative;

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 15px;
      width: 30px;
      height: 30px;
      box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);
      background-color: #fff;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center;
      color: #31364e;
      z-index: 3;
    }

    &:not(:last-child) {
      padding-bottom: 30px;

      &::after {
        display: block;
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        bottom: 0;
        left: 15px;
        border-left: 1px dotted white;
        z-index: 1;
      }
    }

    &-title {
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: normal;
      color: #31364e;
      margin-bottom: 13px;
    }

    &-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #31364e;
    }
  }

  &__detail {
    width: 458px;
  }
}

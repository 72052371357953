.passwordless-table {
  z-index: 999;
}
.passwordless-fido-recommendation-new {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  flex-wrap: wrap;
  box-shadow: 0px 0px 5px grey;
  border-radius: 5px;
  animation: 1s slideInFromRight 1s ease-out;
  animation-fill-mode: both;
  color: #000;
  background-color: #fff;
  z-index: 999;
}
.passwordless-button-new {
  padding: 4px 20px;
  border-radius: 100px;
  background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
  color: white;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  border: none;
}
.passwordless-link-new {
  font-weight: 600;
  color: #ed994e;
  text-decoration: none;
  cursor: pointer;
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(calc(100% + 2rem));
  }
  100% {
    transform: translateX(0);
  }
}

.why-course {
  padding: 100px 0;

  &__text {
    font-size: 20px;
    line-height: 1.5;
    color: #31364e;

    span {
      display: block;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
  }
}

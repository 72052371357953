.all-courses {
  padding: 100px 20px 0;
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
  }
  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }
  section.beginner-courses,
  section.advanced-courses {
    max-width: 1304px;
    margin: auto;
    padding-bottom: 100px;
    @media screen and (max-width: 767px) {
      padding-bottom: 80px;
    }
  }
  .section-title {
    font-size: 56px;
    font-weight: 400;
    line-height: 68px;
    margin-bottom: 48px;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 40px;
    }
  }
  .courses-listing {
    display: flex;
    gap: 48px 20px;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      gap: 20px;
    }
    .course-item {
      flex: 1 0 48%;
      max-width: 49%;
      background: white;
      box-shadow: 0px 0px 46.599998474121094px 0px #d0cccc40;
      border-radius: 20px;
      @media screen and (max-width: 767px) {
        max-width: 100%;
        flex: 1 0 100%;
      }
    }
    .course-img {
      img {
        width: 100%;
        height: 240px;
        border-radius: 20px 20px 0 0;
        object-fit: cover;
        &[src='/img/courses/sleep-anxiety.webp'] {
          object-position: 90% 100%;
        }
        &[src='/img/courses/sahaj-samadhi-meditation.webp'] {
          object-position: 10% 100%;
        }
        &[src='/img/courses/art-of-living-part-1.webp'] {
          object-position: 20% 100%;
        }
        &[src='/img/courses/art-of-living-part-2.webp'] {
          object-position: 0% 0%;
        }
        &[src='/img/courses/blessings-course.webp'] {
          object-position: 90% 100%;
        }
        &[src='/img/courses/sanyam-course.webp'] {
          object-position: 0% 0%;
        }
        &[src='/img/courses/sri-sri-yoga-deep-dive.webp'] {
          object-position: 0% 0%;
        }
        &[src='/img/courses/teacher-training.webp'] {
          object-position: 100%;
        }
        &[src='/img/courses/sri-sri-marma-practitioner.webp'] {
          object-position: 100%;
        }
      }
    }
    .course-info {
      padding: 20px 16px 24px;
    }
    .course-title {
      font-family: Lora;
      font-size: 24px;
      font-weight: 500;
      line-height: 31px;
      margin-bottom: 12px;
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
    .course-desc {
      color: #6f7283;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 12px;
    }
    a.course-link {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #ed994e;
      background: url(/img/find-course-arrow.webp) no-repeat center right;
      padding: 5px 44px 5px 0;
      text-decoration: none;
      display: inline-block;
    }
  }
}

.progress-section {
  padding: 140px 0 80px 0;

  @media screen and (min-width: 720px) {
    &.sky-breath-meditation {
      padding: 270px 0 80px 0;
    }
    &.in-person {
      padding: 370px 0 80px 0;
    }
  }

  &__title {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.31;
    color: #31364e;
    margin-bottom: 40px;

    span {
      font-weight: 600;
    }
  }

  .featured-in {
    border: solid 1px #e9e9e9;
    padding: 40px;

    &__title {
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: #303650;
      margin-bottom: 30px;
    }

    &__box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: white;

      img {
        height: 60px;
      }
    }
  }

  &.volunteer-program {
    margin-top: -24px;
    padding: 0 0 80px;

    .progress-section__title {
      font-family: Lora, serif;
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
}

.aol_allModal {
  margin-top: 20px;
  .modal-dialog {
    max-width: 800px;
  }

  .modal_content {
    border-radius: 16px;
    border: solid 1px #e8f2fc;
  }

  .modal_header {
    border-width: 0px;

    .modal_title {
      color: #4b5487;
      font-size: 14px;
      line-height: 30px;
      position: relative;
      text-align: center;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .sessionDetails_videoHeader {
    background-image: url('/img/journey-starts-bg-new.png');
    background-position: center center;
    margin: 10px;
    min-height: 158px;
    padding: 42px 0;
    position: relative;
    overflow: hidden;
  }

  .modal_body {
    padding: 10px;
    min-height: 200px;
  }

  .modalClose {
    opacity: 1;
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 99999;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 1px #4b5487;
    font-size: 20px;
    font-weight: 400;
    color: #4b5487;

    &:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0 3px #4b5487;
    }
  }

  .datetime_box {
    margin: 10px 0px;

    div {
      letter-spacing: -0.05em;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #9598a6;
    }
  }
}

.img {
  width: 30px;
  height: 30px;
  border-radius: 25px;
  margin: 0px 6px 0 0px;
  border: 1px solid #ffffff;
  -webkit-box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.2);
}

.workshopCourseBlk {
  display: flex;
  margin: 20px 0 50px;
  flex-wrap: wrap;

  .leftBlk,
  .rightBlk {
    width: 50%;
    flex: 1;
  }

  .fullBlk {
    width: 100%;
    margin-top: 10px;

    p {
      margin: 15px 0px 0px 0px;
    }
  }

  .contact {
    margin: 15px 0px 0px 0px;
  }
}

.sessionDetails_ModalBody.workshop .title {
  font-size: 28px;
  line-height: 30px;
  margin: 25px 0 25px;
}

.sessionDetails_ModalBody.workshop h6 {
  color: #9598a6;
  font-family:
    Work Sans,
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 3px;
}

.sessionDetails_ModalBody.workshop p {
  color: #9598a6;
  font-family:
    Work Sans,
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 3px;
}

.sessionDetails_ModalBody.workshop p a {
  font-size: 16px;
  line-height: 20px;
}

.sessionDetails_ModalBody .extrMrg {
  margin-top: 15px;
}

.new-testimonial {
  &__body {
    width: 620px;

    &__header {
      padding: 40px 25px 20px;

      &__title {
        text-align: center;
        font-size: 28px;
        line-height: 1.43;
        font-family: Lora, serif;
        color: #31364e;
      }
    }

    &__media {
      background-color: #f7f7f7;
      padding: 25px 25px 0;
      display: flex;

      &__preview {
        width: 275px;
        height: 168px;
        margin-right: 20px;
        border-radius: 16px 16px 0 0;
        position: relative;
        background-color: #f7f7f7;
        overflow: hidden;

        &_gradient-1 {
          background: linear-gradient(90deg, #d0e1f0 -26%, #89beec 100%);
        }

        &_gradient-2 {
          background: linear-gradient(90deg, #9b6bbe -15%, #ff865b 115%);
        }

        &_gradient-3 {
          background: linear-gradient(90deg, #e47d6c -7%, #3c8ae8 111%);
        }

        &__name {
          position: absolute;
          color: #ffffff;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          width: 100%;
          font-size: 48px;
          font-weight: 500;
          letter-spacing: 0.8px;
        }

        img,
        video {
          position: relative;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__label {
        width: 275px;

        &__title {
          font-size: 16px;
          font-weight: 500;
          color: #31364e;
          margin-bottom: 10px;
        }

        &__text {
          font-size: 14px;
          line-height: 1.29;
          color: #6f7283;
          margin-bottom: 20px;
        }

        &__button {
          width: 100%;
          padding: 10px;
          height: 40px;
        }
      }
    }

    &__fields {
      padding: 25px 25px 20px;

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    &__field {
      position: relative;
      margin-bottom: 20px;

      input,
      textarea {
        border-radius: 8px;
      }

      textarea {
        display: block;
        resize: none;
        height: 140px;

        &:focus {
          outline: none;
        }
      }

      &__hint {
        display: inline-block;
        margin-top: 10px;
        font-size: 12px;
        color: #6f7283;
      }
    }

    &__footer {
      padding: 25px;
      background-color: rgba(237, 153, 78, 0.1);
      text-align: right;
      border-radius: 0 0 16px 16px;
    }
  }
}

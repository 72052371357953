@media only screen and (max-width: 1199px) {
  .program-footer {
    &__top {
      &-search {
        width: 230px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .program-footer {
    margin-bottom: 85px;

    &__top {
      padding-bottom: 16px;
    }

    &__nav {
      padding: 32px 0;

      &-email {
        margin: 12px auto 20px;
        font-size: 15px;
        line-height: 18px;
      }

      &-contacts {
        text-align: center;

        &__item {
          width: 28px;
          height: 28px;
        }
      }

      &-menu {
        &__column {
          margin-bottom: 40px;

          &-title {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 24px;
          }

          &-link {
            font-size: 14px;
            line-height: 22px;

            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    &__bottom {
      padding: 20px 0 32px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .program-footer {
    &__nav {
      &-menu {
        flex-wrap: wrap;

        &__column {
          width: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .program-footer {
    &__top {
      flex-wrap: wrap;
      justify-content: center;

      &-logo {
        margin-bottom: 40px;
      }

      &-search {
        width: 100%;
      }
    }

    &__nav {
      &-menu {
        &__column {
          width: 175px;
        }
      }
    }

    &__bottom {
      flex-direction: column-reverse;
      align-items: center;

      &-links {
        width: 100%;
        margin-bottom: 48px;
        display: flex;
        justify-content: space-between;

        &__item {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 436px) {
  .program-footer {
    &__nav {
      &-menu {
        &__column {
          width: 130px;
        }
      }
    }
  }
}

.meditation {
  padding: 100px 0px 0px;
  text-align: center;

  &__title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-bottom: 0;
  }

  &__quote {
    margin: 30px 0 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    span {
      color: $orange;
    }
  }
}

// FOR DEVELOPMENT
.reserve-card__body,
.reserve-card__body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.reserve-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background-color: #00000040;
}

// RESRVE CARD
.reserve-card {
  position: relative;
  width: 840px;
  min-width: 320px;
  height: 750px;
  padding: 20px 12px;

  background-color: #fff;
  box-shadow:
    0 0 12px rgba(0, 0, 0, 0.3),
    0 1px 5px rgba(0, 0, 0, 0.2);

  overflow-y: auto;
  overflow-x: hidden;

  &__title {
    color: #31364e;
    font-size: 24px;
    line-height: 28px;
    font-family: Lora;

    text-align: center;

    margin-bottom: 20px;
  }

  &__header {
    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 20px 0 20px;
      padding: 0 30px;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }

  &__datepicker {
    width: 148px;
    padding: 8px 16px;
    border-radius: 12px;
    border: 1px solid #e9e9e9;

    color: #707282;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;

    &-wrapper {
      position: relative;

      .icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        color: #707282;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.4px;

        transition: transform 0.25s ease;
      }

      &.open .icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &-label {
      display: none;

      color: #333;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: Roboto;

      margin-bottom: 8px;
    }
  }
}

.reserve-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: calc(15px + 14px + 20px); // offset + height font + padding
  border-bottom: 1px solid #e9e9e9;

  &__desktop {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 220px;
    height: 2px;
    background-color: #c4c5cc;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 2px solid #fd7e14;

      box-sizing: border-box;
    }

    &::before {
      left: -10px;
    }

    &::after {
      right: -10px;
    }
  }

  &__mobile {
    display: none;

    width: 100%;
    height: 4px;

    transition: background 0.3s ease;
  }

  &__mobile &__text {
    transform: translateY(-26px);
    text-align: center;
  }

  &.step1 {
    .reserve-progress__desktop {
      &::after {
        border-color: #c4c5cc;
      }

      .reserve-progress__text:last-child {
        color: #c4c5cc;
      }
    }

    .reserve-progress__mobile {
      background: linear-gradient(90deg, #ff865b 50%, #e9e9e9 50%);

      .reserve-progress__text:first-child {
        display: block;
      }

      .reserve-progress__text:last-child {
        display: none;
      }
    }
  }

  &.step2 {
    .reserve-progress__desktop {
      background-color: #fd7e14;

      &::before {
        background-color: #fd7e14;
      }

      .reserve-progress__text:first-child {
        color: #c4c5cc;
      }
    }

    .reserve-progress__mobile {
      background: linear-gradient(90deg, #ff865b 50%, #ff865b 50%);

      .reserve-progress__text:first-child {
        display: none;
      }

      .reserve-progress__text:last-child {
        display: block;
      }
    }
  }

  &__text {
    color: #31364e;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }

  &__desktop &__text:first-child {
    transform: translate(-50%, 15px);
  }
  &__desktop &__text:last-child {
    transform: translate(50%, 15px);
  }
}

.reserve-event {
  display: grid;
  grid-template-columns: 70px 1fr 120px;
  gap: 20px;
  align-items: center;

  padding: 30px 0;
  border-bottom: 1px solid #e9e9e9;

  &__date {
    &-month {
      display: block;
      margin-bottom: 4px;

      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: #fa5a67;
    }

    &-day {
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 70px;
      height: 70px;
      padding: 5px 11px 8px;

      background-color: #f7f7f7;
      border-radius: 8px;
      font-family: Roboto;
      text-align: center;

      & > p {
        height: 100%;
      }
    }
  }

  &__about {
    display: flex;
    flex-direction: column;
  }

  &__time {
    color: #31364e;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    font-family: Roboto;

    margin-bottom: 5px;
  }

  &__type {
    color: #9598a6;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    font-family: Roboto;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    padding: 15px 20px;
    outline: none;
    border: none;
    border-radius: 16px;
    background: #ff865b;
    background-image: linear-gradient(to bottom, #ffd16f, #ff865b);

    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: Roboto;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 991px) {
  .reserve-container {
    height: 200vh;
  }

  .reserve-card {
    width: 100%;

    &__header {
      &-list {
        padding: 0 6px;
        background-color: #fff;

        top: -20px;
        width: calc(100% + 24px);
        margin: 0;
        padding: 20px 15px 16px;
        transform: translateX(-12px);
      }

      &-list.sticky {
        position: sticky;
        border-bottom: 1px solid #e0e0e0;
      }

      &-title {
        display: none;
      }
    }

    &__list {
      padding: 0 6px;
    }

    &__datepicker {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 8px;
      border: 2px solid #ededed;
      box-shadow: 0px 0px 0px 4px rgba(221, 221, 221, 0.16);

      &-wrapper {
        width: 100%;

        .icon {
          transform: none;
          top: inherit;
          bottom: 10px;
        }

        &.open .icon {
          transform: rotate(180deg);
        }
      }

      &-label {
        display: block;
      }
    }
  }

  .reserve-event {
    grid-template-columns: 45px 1fr;
    row-gap: 10px;
    column-gap: 14px;
    padding: 20px 0;
    border: none;

    &__date {
      &-month {
        font-size: 12px;
      }
      &-day {
        font-size: 20px;
        line-height: 20px;
      }

      &-wrapper {
        width: 45px;
        height: 46px;
        border-radius: 4px;
      }
    }

    &__time {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
    }

    &__type {
      display: none;
    }

    &__button {
      grid-column: 1/3;
      height: 40px;

      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .reserve-progress {
    margin-top: 46px;
    padding-bottom: 0;

    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }
  }
}

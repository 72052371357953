.scheduling-page {
  .flatpickr-input {
    display: none;
  }
  background: #fffaf5;

  .scheduling-top {
    padding-top: 60px;
    padding-bottom: 32px;
    @media screen and (max-width: 991px) {
      padding-top: 40px;
      padding-bottom: 24px;
    }
    .container {
      max-width: 690px;
    }
  }
  .health-confirmation {
    label,
    span,
    p {
      font-size: 14px !important;
      color: #31364e;
      line-height: 21px;
    }
  }
  .page-title {
    font-family: Lora;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    text-align: center;
    margin-bottom: 16px;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 12px;
    }
  }
  .page-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #6f7283;
    strong {
      font-weight: 500;
    }
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }
  .scheduling-stepper {
    padding-bottom: 32px;
    @media screen and (max-width: 991px) {
      padding-bottom: 24px;
    }
    .container {
      max-width: 1000px;
    }
    .step-wrapper {
      display: flex;
      gap: 16px;
      .step {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        &.active {
          .step-icon {
            span {
              box-shadow: 0px 0px 0px 4px #8cbaf13d;
              border-color: #3d8be8;
              background-color: #3d8be8;
              outline-color: white;
            }
            &::before,
            &::after {
              background: #3d8be8;
            }
          }
          .step-text {
            color: #3d8be8;
          }
          & + .step .step-icon {
            &::before {
              background: #3d8be8;
            }
          }
        }
        &.completed {
          .step-icon {
            span {
              background-color: #3d8be8;
              border-color: #3d8be8;
              outline: none;
              display: flex;
              align-items: center;
              justify-content: center;
              &::before {
                content: '\e917';
                color: white;
                font-size: 14px;
                font-family: 'iconaol' !important;
              }
            }
            &::before,
            &::after {
              background: #3d8be8;
            }
          }
        }
        &:first-child {
          .step-icon::before {
            opacity: 0;
          }
        }
        &:last-child {
          .step-icon::after {
            opacity: 0;
          }
        }
      }
      .step-icon {
        width: 100%;
        display: flex;
        align-items: center;
        &::before,
        &::after {
          height: 2px;
          background: #e9e9e9;
          width: 50%;
          content: '';
          display: block;
        }
        &::before {
          margin-left: -8px;
        }
        &::after {
          margin-right: -8px;
        }

        span {
          background-color: white;
          border: 2px solid #e9e9e9;
          outline: 5px solid #e9e9e9;
          outline-offset: -20px;
          height: 28px;
          width: 28px;
          display: block;
          border-radius: 100%;
          flex-shrink: 0;
        }
      }
      .step-text {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        @media screen and (max-width: 991px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  .calendar-section {
    .container {
      max-width: 1030px;
    }
    .calendar-area-wrap {
      display: flex;
      gap: 40px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 32px;
      }
      .first-col {
        flex: 1;
      }
      .hide-col {
        display: none;
      }
      .second-col {
        width: 420px;
        @media screen and (max-width: 991px) {
          width: auto;
        }
      }
    }
    .scheduling-modal__content-calendar {
      margin-top: 24px;
      background: white;
      border-radius: 16px;
      padding: 20px 24px;
      & > label {
        display: block;
      }
      .flatpickr-innerContainer,
      .flatpickr-rContainer {
        display: block;
      }
      .flatpickr-current-month .cur-month {
        font-size: 18px;
        text-transform: unset;
      }
      .flatpickr-months {
        .flatpickr-prev-month,
        .flatpickr-next-month {
          height: 36px;
          width: 36px;
          border-radius: 0;
          border: none;
          svg path {
            fill: #6f7283;
          }
        }
        .flatpickr-next-month {
          margin-left: 12px;
        }
      }
      .flatpickr-weekdaycontainer {
        background: none;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        padding: 0;
        column-gap: 14px;
        .flatpickr-weekday {
          font-size: 14px;
          line-height: 20px;
          color: #31364e;
          text-transform: unset;
          max-width: unset;
          width: 100%;
        }
      }
      .flatpickr-days {
        padding: 0;
        .dayContainer {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          column-gap: 14px;
          row-gap: 12px;
          @media screen and (max-width: 991px) {
            column-gap: 8px;
          }
          .flatpickr-day {
            color: #31364e;
            font-size: 14px;
            background: #fff0e0;
            border-radius: 20px;
            border-color: #fff0e0;
            height: 56px;
            width: auto;
            max-width: unset;
            max-height: unset;
            @media screen and (max-width: 991px) {
              height: 36px;
              border-radius: 12px;
            }
            &.today {
              border-color: transparent;
            }
            &.flatpickr-disabled {
              background: none;
              border-color: transparent;
            }
            &.selected {
              border-color: #ff875b;
              &::before {
                display: none;
              }
              &:hover {
                background: #f7f7f7 !important;
              }
            }
            &.online {
              &::before {
                display: block;
                content: '';
                height: 5px;
                width: 5px;
                background: #ed994e;
                border-radius: 5px;
                position: absolute;
                bottom: 4px;
                z-index: 0;
              }
            }
            &.in-person {
              &::after {
                display: block;
                content: '';
                height: 5px;
                width: 5px;
                background: #3d8be8;
                border-radius: 5px;
                position: absolute;
                bottom: 4px;
                z-index: 0;
              }
            }
            &.online.in-person {
              &::before {
                margin-right: 8px;
              }
              &::after {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
  .checkout-section {
    .container {
      max-width: 1030px;
      padding-top: 28px;
      padding-bottom: 28px;
    }
    .discount-text-input {
      padding: 10px 14px;
      height: 44px;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      box-shadow: 0px 1px 2px 0px #1018280d;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Work Sans;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      .react-tag-remove {
        color: #ffffff;
      }

      &:focus {
        border-color: #ed994e !important;
      }
      &::placeholder {
        color: #9598a6;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #9598a6;
      }
      a {
        text-decoration: none;
        font-size: 26px;
      }

      &.error {
        border-color: red;
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #9598a6;
      }
      a {
        text-decoration: none;
        font-size: 26px;
      }
    }
    .checkout-area-wrap {
      display: flex;
      gap: 40px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
      .first-col,
      .second-col {
        flex: 1;
      }
      .payment-details {
        margin-bottom: 0;
      }
      .checkout-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 30.8px;
      }
      .checkout-subtitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #6f7283;
      }
      .payment-box {
        .selected-flag {
          outline: none;
          position: relative;
          width: 38px;
          display: flex;
          flex: 1;
          align-items: center;
          padding: 0px;

          .arrow {
            width: 8px !important;
            height: 8px !important;
            border-color: #98a2b3 !important;
            border-left: 1.5px solid !important;
            border-bottom: 1.5px solid !important;
            border-right: none !important;
            border-top: none !important;
            transform: rotate(-45deg) !important;
            top: unset !important;
          }
        }
        .form-item {
          label {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #9598a6;
          }
          input:not([type='checkbox'], [type='radio']) {
            height: unset;
          }
        }
        .section-box {
          border-top: #e9e9e9 1px solid;
          padding-top: 20px;
          margin-bottom: 20px;
          .section__title {
            font-family: 'Lora', serif;
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            .ssl-info {
              margin-left: auto;
              font-size: 16px;
              font-family: Work Sans;
              font-weight: 400;
            }
          }
          p {
            color: #6f7283;
            font-size: 16px;
            line-height: 24px;
            a {
              color: #ed994e;
              text-decoration: none;
              font-weight: 600;
            }
          }
        }
        .account-details {
          .form-inputs {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 16px;
            @media screen and (max-width: 767px) {
              gap: 12px;
            }
            .form-item {
              flex: 48%;
              @media screen and (max-width: 767px) {
                flex: 100%;
              }
              &.checkbox {
                flex: 100%;
              }
            }
          }
        }
      }
    }
    .checkout-course-info-box {
      background: white;
      border: #e9e9e9 1px solid;
      border-radius: 16px;
      padding: 14px 24px;
      display: flex;
      gap: 16px;
      @media screen and (max-width: 991px) {
        padding: 14px 16px;
      }
      & + .checkout-course-info-box {
        margin-top: 16px;
      }
      .info-box-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #edf5fc;
        border-radius: 100%;
        .icon-aol {
          font-size: 24px;
          color: #3d8be8;
        }
      }
      .info-box-details {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .info-box-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
      .info-detail {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
      .info-link {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }
    .checkout-tnc {
      margin-top: 12px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #6f7283;
      text-align: center;
      a {
        color: #6f7283;
      }
      @media screen and (max-width: 991px) {
        max-width: 175px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .note {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 18px;
      margin-bottom: 12px;
      a {
        color: #ed994e;
        text-decoration: none;
      }
    }
    .form-item {
      &.checkbox {
        flex-direction: row;
        input[type='checkbox'],
        input[type='radio'] {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
          & + label {
            display: flex;
            color: #31364e;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            &::before {
              content: '';
              height: 20px;
              width: 20px;
              min-width: 20px;
              border: #ed994e 1px solid;
              border-radius: 4px;
              margin-right: 12px;
              display: flex;
              font-weight: 900;
              justify-content: center;
              align-items: center;
            }
            a {
              display: contents;
              color: #ed994e;
            }
          }
          &:checked + label {
            &::before {
              content: url(/img/checkicon-css.svg);
              background-color: #ed994e;
              color: white;
            }
          }
        }
      }
    }
  }
  .form-item {
    .scheduling-address {
      margin: 0;
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a.react-tag-remove {
        font-size: 20px;
        text-decoration: none;
      }
    }
    .schedule-location-input {
      box-shadow: 0px 1px 2px 0px #1018280d;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      height: 44px;
      width: 100%;
      padding: 10px 14px;
      background-color: #ffffff;
      transition: all 0.3s ease-in-out;
      .schedule-location-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    label {
      color: #6f7283;
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
    select,
    input:not([type='checkbox'], [type='radio']) {
      box-shadow: 0px 1px 2px 0px #1018280d;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      height: 44px;
      width: 100%;
      padding: 10px 14px;
    }
    input[type='checkbox'],
    input[type='radio'] {
      border: 1px solid #e9e9e9;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
      border-radius: 4px;
      flex-shrink: 0;
      &:checked {
        border: none;
        &::after {
          content: url(/img/checkicon-css.svg);
          background-color: #ed994e;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
        }
      }
    }
    input[type='radio'] {
      border-radius: 100%;
      border-color: #ed994e;
      display: flex;
      align-items: center;
      justify-content: center;
      &:checked {
        border: 1px solid #ed994e;
        &::after {
          content: '';
          background-color: #ed994e;
          width: 90%;
          height: 90%;
          display: block;
          border-radius: 100%;
        }
      }
    }
  }
  .submit-btn {
    height: 54px;
    padding: 0 32px;
    border-radius: 100px;
    background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    border: none;
  }
  .cal-filters {
    display: flex;
    gap: 10px;
    & > .form-item {
      flex: 1;
      width: calc(50% - 5px);
    }
  }
  .specific-teacher-text {
    padding-top: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    a {
      color: #ed7347;
      font-weight: 600;
    }
  }
  .payment-box {
    box-shadow: 4px 6px 25.100000381469727px 0px #6e6b6824;
    background: white;
    padding: 24px;
    border-radius: 20px;
    @media screen and (max-width: 991px) {
      padding: 24px 16px;
    }
    .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      display: flex;
      flex: 1;
      align-items: center;
      padding: 0px;

      .arrow {
        width: 8px !important;
        height: 8px !important;
        border-color: #98a2b3 !important;
        border-left: 1.5px solid !important;
        border-bottom: 1.5px solid !important;
        border-right: none !important;
        border-top: none !important;
        transform: rotate(-45deg) !important;
        top: unset !important;
      }
    }
    .section-box {
      border-top: #e9e9e9 1px solid;
      padding-top: 20px;
      margin-bottom: 20px;
      .section__title {
        font-family: 'Lora', serif;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        .ssl-info {
          margin-left: auto;
          font-size: 16px;
          font-family: Work Sans;
          font-weight: 400;
        }
      }
      p {
        color: #6f7283;
        font-size: 16px;
        line-height: 24px;
        a {
          color: #ed994e;
          text-decoration: none;
          font-weight: 600;
        }
      }
    }
    .account-details {
      .form-inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 16px;
        @media screen and (max-width: 767px) {
          gap: 12px;
        }
        .form-item {
          flex: 48%;
          @media screen and (max-width: 767px) {
            flex: 100%;
          }
          &.checkbox {
            flex: 100%;
          }
        }
      }
    }
    .payment-total-box {
      padding: 12px 16px;
      border-radius: 8px;
      justify-content: space-between;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      label {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
      }
      .amount {
        font-size: 24px;
        line-height: 26px;
        font-weight: 600;
        s {
          color: #9598a6;
        }
      }
    }
    .payment-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 20px;
      .payby {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        img {
          margin-top: -13px;
          margin-left: 5px;
        }
      }
      .price-breakup {
        text-align: right;
      }
      .price-per-month {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        span {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .payment-tenure {
        color: #6f7283;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .checkout-user-info {
      color: #6f7283;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 16px;
      a {
        color: #ed994e;
        font-weight: 600;
        text-decoration: none;
      }
    }
    .checkout-fields {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 12px;
      padding: 20px 0 16px;
      border-bottom: #e6e8ec 1px solid;
      > * {
        width: calc(50% - 6px);
      }
      .fullw {
        width: 100%;
      }
    }
    .payment-price-label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #6f7283;
      padding: 8px 0;
    }
    .payment-total {
      border-top: 1px solid #b1aaaa26;
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 12px 0;
      margin-bottom: 12px;
      label {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
      }
      .amount {
        font-size: 24px;
        line-height: 26px;
        font-weight: 600;
      }
    }
    .payment-agreements {
      border-top: #e6e8ec 1px solid;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .agreement {
        &__important {
          display: flex;
          align-items: center;
          opacity: 1;
          pointer-events: all;
          margin-bottom: 15px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ee8585;
          transition: all 0.3s ease-in-out;

          &.hide {
            opacity: 0;
            pointer-events: none;
          }

          &-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .payment-actions {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .form-item {
        display: flex;
        gap: 12px;
        margin-bottom: 12px;
        label {
          color: #31364e;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          span,
          p {
            font-size: 14px !important;
            color: #31364e;
            line-height: 21px;
          }
          a {
            color: #ed994e;
            text-decoration: none;
          }
        }
      }

      .submit-btn {
        height: 54px;
        padding: 0 32px;
        border-radius: 100px;
        background: linear-gradient(
          102.78deg,
          #ffd16f -37.89%,
          #ff865b 100.28%
        );
        color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        border: none;
        width: 100%;
        margin-top: 12px;
      }
      .submit-btn.gpay {
        background: #31364e;
      }
    }
    &.center-one {
      background: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      .payment-total-box {
        box-shadow: 0px 4px 24px 8px #b1aaaa26;
        background: white;
      }
    }
  }

  .offer-box {
    background: linear-gradient(94.04deg, #ffa180 3.84%, #ed7347 109.6%);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 20px;
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: white;
      margin-bottom: 12px;
      font-family: Work Sans;
      .icon-wrap {
        display: flex;
        height: 40px;
        width: 40px;
        background-color: white;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        flex-shrink: 0;
      }
    }
    .offer-text {
      background: #ffffff26;
      padding: 12px 16px;
      border-radius: 16px;
      color: white;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
  .testimonials {
    padding-top: 80px;
    .container {
      max-width: 1030px;
    }
    .top-text {
      color: #ed994e;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: center;
    }
    .section-title {
      margin-top: 12px;
      font-size: 36px;
      font-weight: 500;
      line-height: 44px;
      text-align: center;
      margin-bottom: 0;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    .testimonials-listing {
      padding-top: 32px;
      display: flex;
      gap: 24px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
    }
    .testimonial-item {
      background: #fff;
      box-shadow: 4px 6px 25.100000381469727px 0px #6e6b6824;
      padding: 32px 24px;
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      flex: 1;
      @media screen and (max-width: 991px) {
        padding: 24px;
      }
      .author-picutre {
        margin-bottom: 16px;
        text-align: center;
        img {
          border-radius: 100px;
        }
      }
      .testimony-text {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin-bottom: 20px;
      }
      .author-name {
        margin-top: auto;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
      }
    }
  }
  .question-call {
    display: flex;
    align-items: center;
    justify-content: center;
    .call-cta {
      color: #ed994e;
      border-radius: 16px;
      border: 1px #ed994e solid;
      padding: 8px 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      background: white;
      text-decoration: none;
      text-align: center;
      strong {
        font-weight: 600;
      }
    }
  }
  .time-slot-changer {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
    .slot-info {
      padding: 5px 12px 5px 12px;
      background: #edf5fc;
      border-radius: 8px;
      color: #3d8be8;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
    button {
      width: 36px;
      height: 36px;
      background: none;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 14px;
        svg {
          path {
            fill: #6f7283 !important;
          }
        }
      }
    }
  }
  .slot-listing {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 16px;
    max-height: 50vh;
    overflow: auto;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, auto);
      max-height: calc(100vh - 250px);
    }
    .slot-item {
      background: #f7f7f7;
      padding: 12px;
      border-radius: 16px;
    }
    .slot-type {
      display: flex;
      justify-content: space-between;
      border-bottom: #e9e9e9 1px solid;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .slot-info {
      font-family: Lora;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
    }
    .slot-select {
      margin: 0 !important;
    }
    .slot-timing {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      & + .slot-timing {
        margin-top: 6px;
      }
      .slot-time {
        font-weight: 500;
      }
      .slot-date {
        display: flex;
        align-items: center;
      }
      .detailsIcon {
        height: 16px;
        width: 16px;
        margin-right: 6px;
      }
    }
  }
  .checkout-details {
    border-top: #e9e9e9 1px solid;
    padding-top: 20px;
    margin-bottom: 20px;

    .detail-item {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 14px;
      color: #31364e;
      a {
        color: #ed994e;
        text-decoration: none;
        text-wrap: wrap;
      }
      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
      .label {
        color: #6f7283;
      }
      .detailsIcon {
        width: 20px;
        height: 20px;
      }
      .value {
        word-wrap: break-word;
      }
    }
  }
}
.modal {
  padding-left: 15px;
  &.location-search {
    .modal-dialog {
      max-width: 524px;
      @media screen and (max-width: 720px) {
        height: auto;
        max-width: 100%;
      }
    }
    .modal-header {
      .title {
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        margin: 0;
        @media screen and (max-width: 720px) {
          font-size: 24px;
          line-height: 31px;
        }
      }
      .close {
        color: #9598a6;
        font-size: 40px;
        right: 16px;
        background: none;
        top: 14px;
        font-weight: 300;
        &:focus {
          outline: none;
        }
      }
    }
    .modal-content {
      border-radius: 20px;
      padding: 24px 32px;
      @media screen and (max-width: 720px) {
        padding: 24px 20px;
      }
    }
    .modal-body {
      padding: 24px 0 0 0;
      margin: 0;
      .form-item {
        margin-bottom: 24px;
      }
      .submit-btn {
        width: 100%;
        width: 100%;
        border-radius: 100px;
        margin: unset;
        display: block;
      }
    }
    .form-item {
      display: flex;
      flex-direction: column;
      label {
        color: #6f7283;
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
      select,
      input:not([type='checkbox'], [type='radio']) {
        box-shadow: 0px 1px 2px 0px #1018280d;
        border: 1px solid #e9e9e9;
        border-radius: 16px;
        height: 44px;
        width: 100% !important;
        padding: 10px 14px;
        margin-left: 0;
        margin-right: 0;
      }
      input[type='checkbox'],
      input[type='radio'] {
        border: 1px solid #e9e9e9;
        height: 20px;
        width: 20px;
        -webkit-appearance: none;
        border-radius: 4px;
        flex-shrink: 0;
        &:checked {
          border: none;
          &::after {
            content: url(/img/checkicon-css.svg);
            background-color: #ed994e;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: self-end;
            border-radius: 4px;
          }
        }
      }
      input[type='radio'] {
        border-radius: 100%;
        border-color: #ed994e;
        display: flex;
        align-items: center;
        justify-content: center;
        &:checked {
          border: 1px solid #ed994e;
          &::after {
            content: '';
            background-color: #ed994e;
            width: 90%;
            height: 90%;
            display: block;
            border-radius: 100%;
          }
        }
      }
    }
    .submit-btn {
      height: 54px;
      padding: 0 32px;
      border-radius: 100px;
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      color: white;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      border: none;
    }
  }
  &.available-time {
    .modal-dialog {
      max-width: 720px;
      @media screen and (max-width: 720px) {
        max-width: 100%;
      }
      .slot-action {
        text-align: center;
        padding-top: 24px;
        @media screen and (max-width: 720px) {
          position: absolute;
          bottom: 35px;
          width: 100%;
        }
        button {
          width: auto;
          @media screen and (max-width: 720px) {
            width: 100%;
          }
        }
      }
    }
    .modal-header {
      .title {
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        margin: 0;
        @media screen and (max-width: 720px) {
          font-size: 24px;
          line-height: 31px;
        }
      }
      .close {
        color: #9598a6;
        font-size: 40px;
        right: 16px;
        background: none;
        top: 14px;
        font-weight: 300;
        &:focus {
          outline: none;
        }
      }
    }
    .modal-content {
      border-radius: 20px;
      padding: 24px 32px;
      @media screen and (max-width: 720px) {
        padding: 24px 20px;
      }
    }
    .modal-body {
      padding: 24px 0 0 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      .form-item {
        margin-bottom: 24px;
      }
      .submit-btn {
        width: 100%;
      }
      @media screen and (max-width: 991px) {
        .time-slot-changer {
          order: 0;
        }
        .event-type-pills {
          order: 1;
          padding-top: 0;
          margin-bottom: 12px;
          .tooltip {
            bottom: auto;
            top: calc(100% + 10px);
            &::after {
              @media screen and (max-width: 991px) {
                bottom: 100%;
                top: auto;
                border-bottom-color: #fff;
                border-top-color: transparent;
              }
            }
          }
        }
        .specific-teacher-text {
          order: 3;
        }
      }
    }
    .form-item {
      label {
        color: #6f7283;
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
      select,
      input:not([type='checkbox'], [type='radio']) {
        box-shadow: 0px 1px 2px 0px #1018280d;
        border: 1px solid #e9e9e9;
        border-radius: 16px;
        height: 44px;
        width: 100%;
        padding: 10px 14px;
      }
      input[type='checkbox'],
      input[type='radio'] {
        border: 1px solid #e9e9e9;
        height: 20px;
        width: 20px;
        -webkit-appearance: none;
        border-radius: 4px;
        flex-shrink: 0;
        &:checked {
          border: none;
          &::after {
            content: url(/img/checkicon-css.svg);
            background-color: #ed994e;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: self-end;
            border-radius: 4px;
          }
        }
      }
      input[type='radio'] {
        border-radius: 100%;
        border-color: #ed994e;
        display: flex;
        align-items: center;
        justify-content: center;
        &:checked {
          border: 1px solid #ed994e;
          padding: 1px;
          &::after {
            content: '';
            background-color: #ed994e;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 100%;
          }
        }
      }
    }
    .submit-btn {
      height: 54px;
      padding: 0 32px;
      border-radius: 100px;
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      color: white;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      border: none;
      opacity: 1;
    }
    .time-slot-changer {
      display: flex;
      justify-content: center;
      gap: 12px;
      align-items: center;
      margin-bottom: 24px;
      .slot-info {
        padding: 5px 12px 5px 12px;
        background: #edf5fc;
        border-radius: 8px;
        color: #3d8be8;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
      button {
        width: 36px;
        height: 36px;
        background: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 14px;
          svg {
            path {
              fill: #6f7283 !important;
            }
          }
        }
      }
    }
    .slot-listing {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      max-height: 50vh;
      overflow: auto;
      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, auto);
        max-height: calc(100vh - 250px);
      }
      .slot-item {
        background: #f7f7f7;
        padding: 12px;
        border-radius: 16px;
      }
      .slot-type {
        display: flex;
        justify-content: space-between;
        border-bottom: #e9e9e9 1px solid;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
      .slot-info {
        font-family: Lora;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
      }
      .slot-select {
        margin: 0 !important;
      }
      .slot-timing {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 24px;
        & + .slot-timing {
          margin-top: 6px;
        }
        .slot-time {
          font-weight: 500;
        }
        .slot-date {
          display: flex;
          align-items: center;
        }
        .detailsIcon {
          height: 16px;
          width: 16px;
          margin-right: 6px;
        }
      }
    }
  }
}
.specific-teacher-text {
  padding-top: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  a {
    color: #ed7347;
    font-weight: 600;
    text-decoration: none;
  }
}
.additionalInfo {
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  padding-top: 24px;
  @media screen and (max-width: 720px) {
    font-size: 14px;
    line-height: 18px;
    padding-top: 8px;
  }
  .icon-aol {
    width: 28px;
    height: 28px;
    background: #edf5fc;
    border-radius: 80px;
    font-size: 16px;
    color: #3d8be8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.event-type-pills {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding-top: 20px;
  @media screen and (max-width: 991px) {
    gap: 6px;
  }
  > div {
    border-radius: 32px;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #fffaf5;
    padding: 6px;
    position: relative;
    @media screen and (max-width: 991px) {
      height: 40px;
    }
    @media screen and (max-width: 767px) {
      flex: 1;
      justify-content: flex-start;
      .iconaol-info-circle {
        margin-left: auto !important;
      }
    }
    &.inPerson {
      background: #edf5fc;
      .tooltip {
        @media screen and (max-width: 767px) {
          right: 0;
          left: auto;
          width: calc(100vw - 92px);
          &::after {
            left: auto;
            right: 50px;
          }
        }
      }
    }
    .iconaol-monitor-mobile,
    .iconaol-profile-users {
      font-size: 20px;
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      border-radius: 32px;
      height: 32px;
      width: 32px;
      line-height: 32px;
      color: #fff;
      @media screen and (max-width: 991px) {
        width: 28px;
        height: 28px;
        font-size: 16px;
        line-height: 28px;
      }
    }
    .iconaol-profile-users {
      background: linear-gradient(102.78deg, #3d8be8 -37.89%, #89beec 100.28%);
    }
    .iconaol-info-circle {
      font-size: 20px;
      margin-left: 4px;
    }
    .tooltip {
      background: #ffffff;
      border-radius: 32px;
      box-shadow: 4px 6px 25.100000381469727px 0px #6e6b6824;
      padding: 16px 20px;
      width: 324px;
      bottom: calc(100% + 14px);
      display: none;
      @media screen and (max-width: 991px) {
        border-radius: 16px;
        padding: 12px;
      }
      @media screen and (max-width: 767px) {
        left: 0;
        width: calc(100vw - 92px);
      }
      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
        border-width: 10px;
        margin-left: -10px;
        @media screen and (max-width: 767px) {
          left: 50px;
        }
      }
      h4 {
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 12px;
        .icon-aol {
          text-align: center;
        }
      }
      p {
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    &:hover .tooltip {
      opacity: 1;
      z-index: 2;
      display: block;
    }
  }
}

.course-silence-retreat {
  &.course-filter {
    padding-top: 0;
  }
  @media screen and (max-width: 991px) {
    padding-top: 0 !important;
  }
  @media screen and (max-width: 767px) {
    .container {
      max-width: 540px;
    }
  }

  .samadhi-top-section {
    background: url(/img/samadhi-page-bg.png) repeat-x top left;
    @media screen and (max-width: 991px) {
      background-color: #fffbf0;
    }
    @media screen and (max-width: 767px) {
      background: #fffbf0;
    }
    .banner {
      height: 402px;
      width: 100%;
      background: url(/img/Silence-Retreat-Banner.webp) no-repeat top left;
      padding: 60px 76px 50px 76px;
      background-size: auto;
      background-position: center;
      .courses-title {
        display: none;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
      &-title {
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        max-width: 480px;
        margin-bottom: 40px;
      }
      &-description {
        font-size: 18px;
        line-height: 22px;
        max-width: 550px;
      }
      @media screen and (max-width: 991px) {
        background-position: bottom center;
        height: 300px;
        padding-left: 0;
        &-title {
          font-size: 35px;
          line-height: 35px;
        }
      }
      @media screen and (max-width: 767px) {
        background: url(/img/silent-retreat-mobile.jpg) no-repeat bottom center;
        height: 600px;
        padding: 36px 32px 10px;
        background-size: auto;
        background-position: center bottom;
        &-title {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 30px;
        }
        .courses-title {
          display: block;
          margin-bottom: 24px;
        }
      }
      .hero-register-button-wrapper {
        margin-top: 50px;
        margin-bottom: 20px;
        .hero-register-button {
          background: linear-gradient(270deg, #3196e4 6%, #5bb8ff 94.33%);
          color: #fff;
          border: 0px;
          border-radius: 10px;
          padding: 1rem 4rem;
        }
        @media only screen and (max-width: 768px) {
          text-align: center;
          margin-top: 30px;
        }
      }
    }
    .content-video-container {
      padding: 80px 20px 40px;
      .content-video-area {
        display: flex;
        .section-title {
          margin: 0 0 0 0;
          font-family: 'Work Sans';
          font-size: 36px;
          line-height: 36px;
          font-weight: 600;
          max-width: 650px;
          margin-bottom: 40px;
        }
        p {
          font-size: 18px;
          line-height: 24px;
          margin: 25px 0;
        }
        .video-wrapper {
          padding: 20px 0 0 60px;
        }
        @media screen and (max-width: 991px) {
          flex-direction: column;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
          .video-wrapper {
            padding: 0;
            iframe {
              max-width: 100%;
              height: 200px;
            }
          }
        }
      }
      p {
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 25px 0;
      }
      @media screen and (max-width: 991px) {
        padding: 40px 0px;
      }
      @media screen and (max-width: 767px) {
        padding: 40px 20px;
      }
    }
  }
  .samadhi-featuers {
    display: flex;
    padding: 40px 20px 10px;
    .feature-box {
      display: flex;
      justify-items: center;
      align-items: center;
      flex-grow: 1;
    }
    .feature-icon {
      width: 88px;
    }
    .feature-text {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      max-width: 192px;
    }
    @media screen and (max-width: 991px) {
      padding: 30px 0;
      .feature-box {
        padding-right: 20px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 20px 0 0 20px;
      flex-direction: column;
      .feature-box {
        flex-direction: row;
        padding: 0;
        width: 100%;
        margin-bottom: 1rem;
      }
      .feature-text {
        font-size: 16px;
      }
    }
  }
  .samadhi-benefits-section {
    padding: 60px 40px 0;
    background: url(/img/sahaj-samadhi-benefit-moon.png) no-repeat top right;
    text-align: center;
    .section-title {
      font-family: 'Work Sans';
      font-weight: 400;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .section-description {
      font-size: 18px;
      line-height: 22px;
    }
    .samadhi-benefits-wrapper {
      padding-top: 40px;
    }
    .samadhi-benefit-box {
      height: 450px;
      display: flex;
      padding: 20px 65px 60px;
      flex-direction: column;
      &.box1 {
        background: url(/img/Breathwork.webp) no-repeat top right;
        @media screen and (max-width: 767px) {
          background-size: cover;
        }
      }
      &.box2 {
        background: url(/img/Sudarshan-Kriya.webp) no-repeat top right;
        @media screen and (max-width: 767px) {
          background-size: cover;
        }
      }
      &.box3 {
        background: url(/img/Deep-Meditations.webp) no-repeat top right;
        @media screen and (max-width: 767px) {
          background-size: cover;
        }
      }
      &.box4 {
        background: url(/img/Silence.webp) no-repeat top right;
        @media screen and (max-width: 767px) {
          background-size: cover;
        }
      }
      .benefit-title {
        margin-top: auto;
        font-family: 'Lora';
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 22px;
      }
    }
    @media screen and (max-width: 991px) {
      padding: 40px 0 0 0;
    }
    @media screen and (max-width: 767px) {
      &.container {
        width: 100%;
        background: white;
        max-width: 100%;
        padding-top: 0;
        & > * {
          max-width: 100%;
          padding: 0 15px;
          margin: auto;
        }
        .section-title {
          text-align: left;
          font-size: 26px;
          padding-left: 20px;
          padding-top: 40px;
          height: 120px;
          padding-bottom: 25px;
          background: url(/img/sahaj-samadhi-benefit-moon.png) no-repeat right
            30px;
          background-size: 200px;
        }
        .section-description {
          display: none;
        }
      }
      .samadhi-benefit-box {
        height: 450px;
        text-align: left;
        padding: 24px;
        .benefit-title {
          font-size: 28px;
          line-height: 30px;
        }
        .benefit-text {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .samadhi-benefits-wrapper {
        padding-top: 0px;
      }
    }
  }
  .register-to-unlock {
    .container {
      padding-top: 50px;
      padding-bottom: 50px;
      max-width: 450px;
      text-align: center;
    }
    .unlock-title {
      font-size: 26px;
    }
    .unlock-register {
      padding-top: 20px;
      button {
        background: linear-gradient(90deg, #7464d6 9.51%, #9d8dff 93.54%);
        color: #fff;
        border: 0px;
        border-radius: 10px;
        padding: 1rem 4rem;
      }
    }
  }
  .section-sahaj-reviews {
    padding-top: 112px;
    background: url(/img/samadhi-carousel-bg.png) repeat-x top left;
    padding-bottom: 90px;
    position: relative;
    .section-title {
      font-family: 'Work Sans';
      font-size: 36px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      margin-bottom: 70px;
    }
    .swiper-wrapper {
      display: flex;
    }
    .swiper-slide {
      align-self: stretch;
      height: auto;
    }
    .review-box {
      background: white;
      border-bottom: #17a8f1 8px solid;
      padding: 30px;
      height: 100%;
    }
    .review-title {
      font-family: 'Lora';
      font-size: 36px;
      font-weight: 400;
      line-height: 30px;
      font-style: italic;
      padding: 0 50px 30px 0;
      background: url(/img/silence-retreat-quote.png) no-repeat top right;
    }
    .review-text {
      font-family: 'Lora';
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
    .review-author {
      display: flex;
      padding-top: 25px;
      align-items: center;
      img {
        height: 55px;
        width: 55px;
        border-radius: 100%;
      }
    }
    .reviewer-photo {
      margin-right: 20px;
    }
    .reviewer-info {
      font-size: 14px;
      line-height: 20px;
    }
    .reviewer-name {
      font-weight: 600;
    }
    .reviwer-position {
      color: #6f6f6f;
    }
    .swiper-pagination {
      bottom: 0px;
    }
    .reviews-slider {
      padding-bottom: 60px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 0;
      background-size: 800px 550px;
      .swiper-slide {
        padding: 0 20px;
      }
      .section-title {
        font-size: 28px;
        line-height: 30px;
        padding: 0 20px;
        text-align: left;
        margin-bottom: 36px;
      }
      .review-box {
        padding: 20px;
      }
      .review-title {
        font-size: 28px;
        line-height: 30px;
      }
      .review-text {
        font-size: 16px;
        line-height: 20px;
      }
      .review-author {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .faq {
    padding: 50px 0;
    .section-title {
      font-family: 'Work Sans', sans-serif;
      text-align: center;
      font-size: 36px;
      line-height: 30px;
      font-weight: 600;
    }
    .card {
      border: none;
      background: none;
      border-radius: 0;
      border-bottom: #dcdcdc 1px solid;
      padding: 20px 0;
      @media screen and (max-width: 767px) {
        padding: 0px 0;
      }
      &:last-child {
        border: none;
      }
      &-header {
        background: none;
        padding: 30px 0;
        border: none;
        button.btn {
          padding: 0 50px 0 0;
          display: block;
          width: 100%;
          text-align: left;
          position: relative;
          font-size: 24px;
          line-height: 22px;
          font-weight: 600;
          color: #000000;
          &:focus,
          &:hover {
            text-decoration: none;
          }
          &::before {
            content: '+';
            font-size: 26px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0px;
            right: 0;
            transform: rotate(45deg);
          }
          &.collapsed::before {
            transform: rotate(0);
          }
          @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
      &-body {
        font-size: 20px;
        line-height: 24px;
        padding: 0;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
          padding-bottom: 20px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .section-title {
        text-align: left;
        font-size: 28px;
        line-height: 30px;
      }
      button.btn {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  .container {
    .registration-widget {
      .circle:before {
        background-color: #5bb8ff;
      }
      .fa-solid,
      .fa-solid:before {
        color: #5bb8ff;
      }
      .register-button {
        background: linear-gradient(270deg, #3196e4 6%, #5bb8ff 94.33%);
        .fa-solid:before {
          color: #ffffff;
        }
      }
      .notes {
        .content {
          /* Track */
          &::-webkit-scrollbar-track {
            background: #e5e2f8;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #3196e4;
            border-radius: 4px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #3895dc;
          }
        }
      }
      .early-bird-banner {
        background: #89c9fb;
      }
    }
  }
  .float-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    z-index: 10;
  }
  .float-wrapper {
    max-width: 1125px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      max-width: 320px;
    }
  }
  .bar-left {
    text-align: right;
    padding-right: 10px;
    @media screen and (max-width: 767px) {
      width: 50%;
      padding-right: 0px;
    }
    .bar-title {
      font-family: 'museo_sans500';
      font-size: 24px;
      line-height: normal;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 17px;
        text-align: left;
        padding: 8px 0 0 10px;
      }
    }
  }
  .bar-right {
    text-align: left;
    padding-left: 10px;
    button {
      background: linear-gradient(270deg, #3196e4 6%, #5bb8ff 94.33%);
      color: #fff;
      border: 0px;
      border-radius: 10px;
      padding: 1rem 4rem;
      white-space: nowrap;
      @media screen and (max-width: 767px) {
        padding: 10px 20px;
      }
    }
  }
  .sudarshan-kriya {
    padding-top: 112px;
    background: url(/img/samadhi-carousel-bg.png) repeat-x top left;
    padding-bottom: 80px;
    position: relative;
    .container {
      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        padding: 0;
        & > * {
          max-width: 100%;
          padding: 0 15px;
          margin: auto;
        }
      }
    }
    .section-title {
      font-family: 'Work Sans';
      font-weight: 400;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 30px;
      margin-bottom: 20px;
      @media only screen and (max-width: 768px) {
        text-align: left;
        font-size: 26px;
      }
    }
    .refreshing-silence {
      padding-top: 112px;
      .section-title {
        text-align: center;
        font-family: 'Work Sans';
        font-size: 36px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        margin-bottom: 70px;
        text-transform: inherit;
        width: 100%;
        @media only screen and (max-width: 768px) {
          text-align: left;
          font-size: 28px;
          line-height: 30px;
          padding-left: 0px;
          margin-bottom: 40px;
        }
      }
      p {
        font-family: 'Work Sans', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin: 25px 25px 25px 0px;
        @media only screen and (max-width: 768px) {
          margin: 0 10px 25px 0;
        }
      }
      .body-mind-spirit {
        background-image: url(/img/Body_mind_spirit.webp);
        background-size: contain;
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        @media only screen and (max-width: 991px) {
          height: 120px;
        }
        @media only screen and (max-width: 768px) {
          background-size: cover;
          height: 95px;
        }
      }
      .desktopView {
        display: inherit;
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }
      .mobileColumn {
        @media only screen and (max-width: 991px) {
          display: flex;
          align-items: center;
        }
        img {
          @media only screen and (max-width: 991px) {
            height: fit-content;
            width: 100%;
            overflow: hidden;
          }
        }
      }
      .mobileView {
        display: none;
        @media only screen and (max-width: 991px) {
          display: inherit;
        }
      }
    }
    .section-description {
      font-size: 18px;
      line-height: 22px;
      @media only screen and (max-width: 768px) {
        text-align: left;
        margin-bottom: 25px;
      }
    }
    .stats-1 {
      background: linear-gradient(0deg, #0997ac 0%, #9ee9ac 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 29px;
      line-height: 29px;
      @media only screen and (max-width: 991px) {
        font-size: 24px;
        font-weight: 600;
      }
    }
    .stats-2 {
      background: linear-gradient(0deg, #0bbafb 0%, #4285ec 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 29px;
      line-height: 29px;
      @media only screen and (max-width: 991px) {
        font-size: 24px;
        font-weight: 600;
      }
    }
    .stats-3 {
      background: linear-gradient(0deg, #f39459 0%, #6f5ea4 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 29px;
      line-height: 29px;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        font-weight: 600;
      }
    }
    .stats-desc {
      font-size: 21px;
      line-height: 25px;
      color: #000;
      padding-top: 35px;
      letter-spacing: -0.5px;
      display: inline-block;
      @media screen and (max-width: 768px) {
        padding-top: 0;
      }
    }
    @media only screen and (max-width: 768px) {
      background: url(/img/sudarshan-kriya-mobile-bg.png) repeat-x top left;
      background-size: 800px 1000px;
    }
  }
  .breadth-meditation {
    .breadth-meditation_content {
      .title {
        height: 90px;
        display: block;
      }
      .stats-desc {
        height: 100px;
        display: block;
        padding-top: 30px;
        @media only screen and (max-width: 991px) {
          padding-top: 20px;
          height: 150px;
        }
        @media only screen and (max-width: 768px) {
          padding-top: 0;
          height: auto;
        }
      }
    }
    .breadth-meditation__block {
      margin-bottom: 2rem;
      .breadth-meditation__title {
        margin-bottom: 1.5rem;
      }
      @media only screen and (max-width: 768px) {
        .breadth-meditation__title {
          margin: 0 1rem;
        }
      }
      .breadth-meditation_content {
        margin: 60px 30px;
        font-size: 24px;
        .title {
          height: 100px;
          display: block;
        }
      }
      @media only screen and (max-width: 768px) {
        .breadth-meditation_content {
          margin: 15px 15px;
          font-size: 1rem;
          text-align: center;
        }
      }
      @media only screen and (max-width: 768px) {
        .comments__video {
          margin: 1rem auto;
        }
      }
    }
    .breadth-meditation_box {
      display: flex;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 12px;
      padding: 20px 20px 30px 20px;
      margin: 30px 0px;
      min-height: 215px;
      align-items: flex-start;
      justify-content: flex-start;
      .breadth-meditation_logo {
        margin-right: 1rem;
        img {
          width: 57px;
          padding-top: 10px;
          height: auto;
          @media only screen and (max-width: 991px) {
            width: 35px;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
        padding: 20px;
        min-height: auto;
        border-radius: 0;
        margin: 8px 0;
      }
    }
    .register-button {
      background: linear-gradient(90deg, #ff9800 9.51%, #ff7e00 93.54%);
      color: #fff;
      border: 0px;
      border-radius: 10px;
      padding: 1rem 4rem;
      margin-top: 2rem;
    }
    @media only screen and (max-width: 768px) {
      .register-button {
        width: 100%;
      }
    }
    @media only screen and (max-width: 768px) {
      .breadth-meditation__title {
        margin-top: 2rem;
        padding: 0 45px 45px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .breadth-meditation {
      padding-top: 60px;
      padding-bottom: 20px;
    }
  }

  .feature-section {
    background-color: #ffffff;
    padding: 50px 60px 0;
    .section-title {
      font-family: 'Work Sans';
      font-weight: 400;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 30px;
      margin-bottom: 20px;
      text-align: center;
    }
    .feature_box {
      display: flex;
      flex-direction: row;
      flex-direction: column;
      padding: 1rem;
      margin: 1rem 0;
      min-height: 15rem;
      text-align: center;
      border-radius: 20px;
      .feature_logo {
        margin-bottom: 1rem;
        min-height: 9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 24px;
        img {
          width: 180px;
          height: auto;
        }
      }
      .feature-content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-left: 2rem;
        font-size: 24px;
        font-style: italic;
        line-height: normal;
      }
    }
    @media only screen and (max-width: 768px) {
      .feature_box {
        margin: 2px 0;
        height: 100px;
        min-height: unset;
        flex-direction: row;
        border-radius: 0;
        border-bottom: 1px solid #d1d1d1;
        .feature_logo {
          margin-bottom: 1rem;
          min-height: unset;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          margin: 20px 0;
          img {
            max-width: 100px;
          }
        }
        .feature-content {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: left;
          margin-left: 2rem;
          font-family: 'lora';
          font-size: 16px;
          line-height: 24px;
        }
      }
      .feature_box-no_border_line {
        border-bottom: 0;
      }
    }
    .pinkbox {
      background: #fef2f2;
    }
    .greybox {
      background: #f5f5f5;
    }
    @media only screen and (max-width: 768px) {
      padding: 50px 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .congratulations {
    height: calc(100vh - 51px - 66px);
  }
}

@media screen and (max-width: 768px) {
  .congratulations {
    height: calc(100vh - 51px - 83px);

    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &__info {
      &-text {
        font-size: 20px;
        text-align: left;
        padding: 40px 21px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .congratulations {
    height: auto;
    padding: 60px 0 120px;
  }
}

@media screen and (max-width: 400px) {
  .congratulations {
    &__info_bottom {
      padding: 30px 20px;

      button {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.program-transformations {
  overflow: hidden;
  padding: 100px 0;
  background-color: #8abfec;
  background-image: url('/img/program-transformations-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: top;

  &__title {
    margin-bottom: 40px;
  }

  &__slider {
    overflow: visible;

    &-button {
      outline: none;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
      background-image: linear-gradient(to top, #3d8be8, #89beec);
      transition: all 0.3s ease-in-out;

      &:after {
        width: 60px;
        height: 60px;
        top: 50%;
        left: 50%;
      }

      &:hover {
        outline: none;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
          ),
          $button-primary;
      }

      &:active {
        outline: none;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          $button-primary;
      }

      &_next {
        right: -90px;

        &:after {
          content: url('/img/ic-arrow-rigth.svg');
        }
      }

      &_prev {
        left: -90px;

        &:after {
          content: url('/img/ic-arrow-left.svg');
        }
      }

      &.swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }
    }

    .transformation {
      background: white;
      border: 1px solid $border-color;
      border-radius: 16px;
      padding: 25px 20px;
      width: 458px;
      height: auto;

      .author {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &__image {
          position: relative;
          width: 80px;
          height: 80px;
          margin-right: 33px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:after {
            content: '“';
            position: absolute;
            top: -5px;
            right: -15px;
            font-size: 60px;
            line-height: 70px;
            font-weight: 600;
            color: $text-color;
          }
        }

        &__name {
          color: $text-color;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }
      }

      &__text {
        margin-bottom: 10px;
        font-style: italic;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: $text-color;
      }
    }
  }
}

.calendar-online {
  .flatpickr-input {
    display: none;
  }
  &.course-filter {
    padding-top: 0;
  }
  @media screen and (max-width: 991px) {
    padding-top: 0 !important;
  }
  @media screen and (max-width: 767px) {
    .container {
      max-width: 540px;
    }
  }

  .location-container {
    position: relative;
    width: 100%;
    // margin-top: 10px;

    .result-list {
      position: absolute;
      top: 58px;
      background-color: #fff;
      width: 100%;
    }
  }

  .scheduling-modal__content-wrapper {
    background-color: transparent;
    .scheduling-modal__content-wrapper-form-checkbox label {
      color: #959595;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .calendar-top-section {
    background: url(/img/samadhi-page-bg.png) repeat-x top left;
    @media screen and (max-width: 991px) {
      background-color: #fffbf0;
    }
    @media screen and (max-width: 767px) {
      background: #fffbf0;
    }
  }
  .calendar-benefits-section {
    padding: 60px 40px 25px;
    background: url(/img/sahaj-samadhi-benefit-moon.png) no-repeat top right;
    text-align: center;
    display: flex;
    align-items: center;
    background-position: 185px 0;
    .section-title {
      font-family: 'Lora';
      font-weight: 400;
      font-size: 36px;
      font-style: italic;
      line-height: 30px;
      text-align: center;
      flex: none;
      strong {
        font-weight: 600;
        font-style: normal;
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
    .section-description {
      font-family: 'Lora';
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      margin-left: 30px;
      @media screen and (max-width: 991px) {
        text-align: center;
      }
    }
    .calendar-benefits-wrapper {
      padding-top: 40px;
    }
    @media screen and (max-width: 991px) {
      padding: 40px 0 0 0;
      display: block;
    }
    @media screen and (max-width: 767px) {
      &.container {
        width: 100%;
        background: white;
        max-width: 100%;
        padding-top: 0;
        & > * {
          max-width: 100%;
          padding: 0 15px;
          margin: auto;
        }
        .section-title {
          text-align: center;
          font-size: 26px;
          padding-left: 20px;
          padding-top: 40px;
          height: 120px;
          padding-bottom: 25px;
          background: url(/img/calendar-online-mobile.png) no-repeat right 30px;
          background-size: 200px;
          background-position-y: top;
        }

        .section-description {
          position: relative;
          top: -30px;
        }
      }
      .calendar-benefits-wrapper {
        padding-top: 0px;
      }
    }
  }
  .calendar-course-type {
    background: #fff9ed;
    padding-top: 1rem;
    padding-bottom: 3.75rem;
    border-radius: 20px;
    border: 1px solid #fff9ed;

    .section-title {
      font-family: 'Work Sans';
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      display: flex;
      align-items: center;
      .calendar_img {
        margin-right: 0.5rem;
        img {
          width: 45px;
          margin-right: 8px;
          @media screen and (max-width: 767px) {
            width: 36px;
          }
        }
      }
    }
    .calendar-benefits-wrapper {
      padding: 50px 55px;
      .paddingRight {
        padding-right: 4rem;
        @media screen and (max-width: 1199px) {
          padding-right: 2.5rem;
        }
        @media screen and (max-width: 991px) {
          padding: 0rem;
        }
      }
      .borderLeft {
        border-left: 1px solid #fda24980;
        padding-left: 4rem;
        @media screen and (max-width: 1199px) {
          padding-right: 2.5rem;
        }
        @media screen and (max-width: 991px) {
          padding: 0rem;
          border-left: 0;
        }
      }
      @media screen and (max-width: 991px) {
        padding: 25px 30px;
      }
      @media screen and (max-width: 767px) {
        padding: 15px;
      }
    }
    .scheduling-types__container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      height: 45px;
      border-radius: 16px;
      margin-top: 12px;
      background-color: transparent;
      .scheduling-types__label {
        position: relative;
      }
      .scheduling-types__input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        padding: 0;
      }
      .scheduling-types__background {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 12px);
        margin: 6px;
        border-radius: 5rem;
        background-color: #ffffff;
        color: #262627;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        transition: background 0.35s ease;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .scheduling-types__input:checked + .scheduling-types__background {
        background-color: #ffd463;
        border: 1px solid #fda24980;
      }
    }
    .course_price {
      padding-top: 38px;
      padding-bottom: 15px;
      h5 {
        font-family: 'Lora';
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      p {
        font-family: 'Work Sans';
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #7c7c7c;
      }
      @media screen and (max-width: 767px) {
        h5 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .scheduling-modal__content-calendar {
      margin-bottom: 45px;
      margin-top: 15px;
      .flatpickr-calendar {
        background: transparent;
        .flatpickr-months {
          .flatpickr-prev-month {
            background: #fda249;
            color: #ffffff;
            border: 0;
            svg {
              width: 18px;
              height: 18px;
              path {
                fill: #ffffff;
              }
            }
          }
          .flatpickr-next-month {
            background: #fda249;
            color: #ffffff;
            border: 0;
            svg {
              width: 18px;
              height: 18px;
              path {
                fill: #ffffff;
              }
            }
          }
        }
        .flatpickr-innerContainer {
          .flatpickr-weekdays {
            .flatpickr-weekdaycontainer {
              background: transparent;
              .flatpickr-weekday {
                font-family: 'Work Sans';
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #000000;
              }
            }
          }
          .flatpickr-days {
            .dayContainer {
              column-gap: 8px;
              justify-content: center;
              align-items: center;
              @media screen and (max-width: 1199px) {
                column-gap: 0px;
              }
              @media screen and (max-width: 991px) {
                column-gap: 64px;
              }
              @media screen and (max-width: 767px) {
                column-gap: 38px;
              }
              @media screen and (max-width: 520px) {
                column-gap: 7%;
              }
              @media screen and (max-width: 425px) {
                column-gap: 5.5%;
              }
              @media screen and (max-width: 385px) {
                column-gap: 4.5%;
              }
              .flatpickr-day {
                font-family: 'Work Sans';
                font-size: 14px;
                font-weight: 500;
                border-radius: 50%;
                background-color: #fff;
                border: 0px;
                &.selected {
                  background-color: #ffd463;
                  border: 1px solid #fda24980;
                  /* &::before {
                    background: #ffeab1;
                    border-radius: 24px;
                    border-bottom-right-radius: 24px;
                    right: -4px;
                    width: 80px;
                    height: 50px;
                    @media screen and (max-width: 1199px) {
                      width: 75px;
                    }
                    @media screen and (max-width: 991px) {
                      width: 112px;
                      height: 38px;
                    }
                    @media screen and (max-width: 767px) {
                      width: 80px;
                    }
                    @media screen and (max-width: 430px) {
                      width: 70px;
                      height: 34px;
                    }
                  }
                  &.first {
                    &::before {
                      background: #ffeab1;
                      border-top-left-radius: 24px;
                      border-bottom-left-radius: 24px;
                      left: -4px;
                    }
                  } */
                }
                &.selected::before {
                  position: absolute;
                  content: '';
                  background: #ffeab1;
                  border-top-left-radius: 24px;
                  border-bottom-left-radius: 24px;
                  left: -4px;
                  width: 60px;
                  height: 50px;
                  z-index: -1;
                  @media screen and (max-width: 991px) {
                    width: 75px;
                    height: 38px;
                  }
                }
                &.selected ~ .selected::before {
                  position: absolute;
                  content: '';
                  background: #ffeab1;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  border-top-right-radius: 24px;
                  border-bottom-right-radius: 24px;
                  left: -24px;
                  right: 0;
                  width: 70px;
                  height: 50px;
                  z-index: -1;
                  @media screen and (max-width: 991px) {
                    width: 110px;
                    left: -78px;
                    height: 38px;
                  }
                  @media screen and (max-width: 767px) {
                    width: 85px;
                    left: -54px;
                    height: 38px;
                  }
                  @media screen and (max-width: 520px) {
                    width: 78px;
                    left: -46px;
                    height: 38px;
                  }
                  @media screen and (max-width: 520px) {
                    width: 78px;
                    left: -46px;
                    height: 38px;
                  }
                  @media screen and (max-width: 520px) {
                    width: 70px;
                    left: -38px;
                    height: 38px;
                  }
                  @media screen and (max-width: 385px) {
                    width: 56px;
                    left: -24px;
                    height: 38px;
                  }
                }
                &.flatpickr-disabled {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
    .available-course-time {
      .available-course-heading {
        display: flex;
        align-items: center;
        .clock_img {
          margin-right: 1rem;
          img {
            @media screen and (max-width: 767px) {
              width: 36px;
            }
          }
        }
        .available-course-title {
          .section-title {
            margin-bottom: 0;
          }
          p {
            font-family: 'Work Sans';
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            color: #7c7c7c;
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }
      .scheduling-modal__content-country-select {
        margin-top: 16px;
        .select2-selection--single {
          border-radius: 8px !important;
          border: 1px solid #ff920080;
          background: #fff9ed;
          &::before {
            display: none;
          }
          .select2-selection__rendered {
            font-family: 'Work Sans';
            font-size: 14px;
            font-weight: 600;
            padding-left: 0;
          }
        }
      }
      .scheduling-types__location {
      }
      .date_selection {
        .scheduling-modal__content-ranges-title {
          font-family: 'Work Sans';
          font-size: 18px;
          font-weight: 600;
          color: #ff9200;
          margin: 25px 0 15px 0;
        }
        .scheduling-modal__content-options {
          .scheduling-modal__content-ranges {
            display: flex;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #ff920080;
            border-radius: 8px;
            &.highlight {
              background-color: #ffeab1;
              box-shadow: 0px 4px 4px 0px #ff920033;
            }
            input[type='radio'] {
              margin: 0 20px;
              -webkit-appearance: none;
              width: 22.352px;
              height: 20px;
              border-radius: 100%;
              outline: none;
              border: 2px solid #ff9200;
              &:checked {
                background-color: #fda249;
                &::after {
                  content: url(/img/checkicon-css.svg);
                  position: relative;
                  top: -4.5px;
                  left: 2.5px;
                }
              }
            }
            .scheduling-modal__content-option {
              border: 0;
              width: 100%;
              background-color: #ffffff;
              border-radius: 0px 8px 8px 0px;
              padding: 15px 30px 15px 15px;
              border-left: 1px solid #ff920080;
              .location {
                font-family: 'Work Sans';
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;
                display: flex;
                align-items: center;
                color: #31364e;
              }
              .scheduling-modal__content-ranges-variants {
                .scheduling-modal__content-ranges-row {
                  font-family: Work Sans;
                  font-size: 16px;
                  font-weight: 500;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  .scheduling-modal__content-ranges-row-date {
                    color: #959595;
                  }
                }
              }
            }
          }
        }
        &.upcoming_events {
          .scheduling-modal__content-options {
            max-height: 350px;
          }
        }
      }
      .agreement_selection {
        .agreement-options {
          .agreement-options-content {
            display: flex;
            align-items: center;
            border-radius: 8px;
            input[type='checkbox'] {
              margin: 0 20px;
              -webkit-appearance: none;
              width: 22.352px;
              height: 20px;
              border-radius: 100%;
              outline: none;
              border: 2px solid #ff9200;
              &:checked {
                background-color: #fda249;
                &::after {
                  content: url(/img/checkicon-css.svg);
                  position: relative;
                  top: -4.5px;
                  left: 2.5px;
                }
              }
            }
            .agreement-text {
              border: 0;
              width: 100%;
              padding: 15px 15px 15px 15px;
              p {
                color: #959595;
              }
            }
          }
        }
        .acknowledgment {
          margin-bottom: 1rem;
          color: #959595;
        }
        .btn-continue {
          width: 100%;
          background-color: #fda249;
          color: #ffffff;
          height: 62.5px;
          border-radius: 8px;
        }
      }
    }
  }

  .register-to-unlock {
    .container {
      padding-top: 50px;
      padding-bottom: 50px;
      max-width: 450px;
      text-align: center;
    }
    .unlock-title {
      font-size: 26px;
    }
    .unlock-register {
      padding-top: 20px;
      button {
        background: linear-gradient(90deg, #7464d6 9.51%, #9d8dff 93.54%);
        color: #fff;
        border: 0px;
        border-radius: 10px;
        padding: 1rem 4rem;
      }
    }
  }
  .float-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    z-index: 10;
  }
  .float-wrapper {
    max-width: 1125px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      max-width: 320px;
    }
  }
  .bar-left {
    text-align: right;
    padding-right: 10px;
    @media screen and (max-width: 767px) {
      width: 50%;
      padding-right: 0px;
    }
    .bar-title {
      font-family: 'museo_sans500';
      font-size: 24px;
      line-height: normal;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 17px;
        text-align: left;
        padding: 8px 0 0 10px;
      }
    }
  }
  .bar-right {
    text-align: left;
    padding-left: 10px;
    button {
      background: linear-gradient(90deg, #7464d6 9.51%, #9d8dff 93.54%);
      color: #fff;
      border: 0px;
      border-radius: 10px;
      padding: 1rem 4rem;
      white-space: nowrap;
      @media screen and (max-width: 767px) {
        padding: 10px 20px;
      }
    }
  }
}
.location-search {
  .modal-dialog {
    .modal-header {
      margin-bottom: 1rem;
      .close {
        right: 18px;
        background-color: #d4d4d4;
        top: 20px;
        color: #ffffff;
      }
    }
    .modal-body {
      p {
        font-family: 'Work Sans';
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
      }
      .location-search-field {
        margin-top: 1rem;
        display: flex;
        justify-content: space-evenly;
        .smart-input {
          height: 100%;
          input {
            height: 100%;
          }
        }
        input {
          border-radius: 8px;
          border: 1px solid rgba(255, 146, 0, 0.5019607843);
          font-family: Work Sans;
          font-size: 14px;
          font-weight: 600;
          background: #fff9ed;
          padding: 13px 15px;
          transition: all 0.3s ease-in-out;
          width: 100% !important;
          margin: 0px;
        }
        select {
          border-radius: 8px;
          border: 1px solid rgba(255, 146, 0, 0.5019607843);
          font-family: Work Sans;
          font-size: 14px;
          font-weight: 600;
          background: #fff9ed;
          padding: 13px 15px;
          transition: all 0.3s ease-in-out;
          width: 48%;
        }
        .select2-selection--single {
          border-radius: 8px !important;
          border: 1px solid rgba(255, 146, 0, 0.5019607843);
          background: #fff9ed;
          position: relative;
          padding: 16px;
          height: 100%;
          .select2-selection__rendered {
            font-family: 'Work Sans';
            font-size: 14px;
            font-weight: 600;
            padding-left: 0;
            color: #000;
            line-height: 24px;
          }
          .select2-selection__arrow {
            top: 50%;
            transform: translate(0, -50%);
          }
        }
        .miles-input,
        .location-input {
          width: 48%;
          position: relative;
          .select2 {
            width: 100% !important;
          }
          .result-list {
            position: absolute;
            top: 58px;
            background-color: #fff;
            width: 100%;
          }
          .schedule-location-input {
            border-radius: 8px;
            border: 1px solid rgba(255, 146, 0, 0.5019607843);
            font-family: Work Sans;
            font-size: 14px;
            font-weight: 600;
            background: #fff9ed;
            padding: 13px 15px;
            transition: all 0.3s ease-in-out;
            width: 100% !important;
            margin: 0px;
            display: block;
            .schedule-location-value {
              padding: 5px;
              display: inline-block;
            }
            .react-tag-remove {
              color: rgba(255, 146, 0, 1);
              text-decoration: none;
              padding-right: 10px;
              right: 0px;
              position: absolute;
              top: 6px;
              font-size: 29px;
            }
          }
        }
      }
      .find-courses {
        border-radius: 8px;
        background: #fda249;
        padding: 13px 97px;
        margin: 2.4rem auto 0;
        display: flex;
      }
    }
    @media screen and (max-width: 767px) {
      .modal-dialog {
        height: auto;
        p {
          margin-top: 20px;
        }
      }
      .modal-body {
        .location-search-field {
          padding: 0 20px;
          flex-direction: column;
          input,
          select,
          .miles-input,
          .location-input {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

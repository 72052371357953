.steps {
  padding: 100px 0;
  background-image: url('/img/steps-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f5f5f5;
  background-position-x: 100%;
  background-position-y: 100%;

  &__title {
    max-width: 555px;
    margin: 0;
    font-family: Lora;
  }

  &__list {
    margin: 30px 0 40px 0;
  }

  &__step {
    max-width: 555px;
    display: flex;
    position: relative;

    &:not(:last-child) {
      padding-bottom: 17px;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 15px;
      width: 30px;
      height: 30px;
      box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);
      background-color: #fff;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center;
      color: #31364e;
      z-index: 3;
    }

    &__text {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      max-width: 510px;
    }
  }
}

.steps__step {
  position: relative;

  &:not(:last-child) {
    span {
      &::after {
        position: absolute;
        content: '';
        background-image: url('/img/line.png');
        background-repeat: no-repeat;
        width: 1px;
        height: 100%;
        left: 15px;
        bottom: 0;
        z-index: -1;
      }
    }
  }
}

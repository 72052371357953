main.sleep-anxiety-protocol {
  @media (width <= 991px) {
    margin: 0;
  }
  .section-title {
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
  }
  .banner-section {
    background-image: url(/img/sleep-hero.webp);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    height: 738px;
    padding-top: 55px;
    @media (width <= 991px) {
      background-position: 87% 100%;
      height: unset;
      padding-top: 300px;
      padding-bottom: 50px;
    }
  }
  .banner-content-box {
    display: block;
    background: rgba(255, 255, 255, 0.31);
    border-style: none;
    border-radius: 12px;
    width: 550px;
    padding: 57px 30px 20px;
    @media (width <= 991px) {
      margin: auto;
      background: rgba(255, 255, 255, 0.8);
    }
    @media (width <= 767px) {
      width: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
    }
    .banner-tagline {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 20px;
      font-weight: 500;
      max-width: 300px;
      @media (width <= 767px) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 10px;
        order: 1;
      }
    }
    .banner-title {
      color: rgb(255, 126, 0);
      font-size: 48px;
      font-family: 'Lora';
      font-weight: 700;
      line-height: 52px;
      margin-bottom: 10px;
      @media (width <= 767px) {
        font-size: 36px;
        line-height: 40px;
        order: 2;
      }
    }
    .banner-desc {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
      @media (width <= 767px) {
        order: 5;
        margin-top: 0;
        font-size: 16px;
        line-height: 18px;
      }
    }
    .banner-features {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (width <= 767px) {
        order: 6;
        font-size: 16px;
        line-height: 18px;
      }
      li {
        display: flex;
        gap: 10px;
        .icon-aol {
          font-size: 18px;
          color: rgb(255, 126, 0);
        }
      }
    }
    .banner-actions {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      @media (width <= 767px) {
        flex-direction: column;
        gap: 10px;
        order: 4;
        margin-top: 10px;
        padding: 0 20px;
        margin-bottom: 30px;
      }
      .banner-button {
        padding: 10px 20px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        height: 60px;
        border-radius: 100px;
        background: rgba(255, 126, 0, 1);
        color: white;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
        flex: 1;
        justify-content: center;
        @media (width <= 767px) {
          height: 53px;
          flex: unset;
        }
        &:hover {
          background-color: rgb(255, 126, 0, 0.8);
        }
        &.btn-secondary {
          color: rgba(255, 126, 0, 1);
          background: white;
        }
      }
    }
    .banner-note {
      font-weight: 500;
      font-size: 14px;
      color: rgb(255, 126, 0);
      margin-top: 20px;
      @media (width <= 767px) {
        order: 3;
        margin-top: 0px;
      }
    }
  }
  .section-registration-widget {
    @media (width >= 992px) {
      margin-top: -100px;
    }
  }
  .registration-widget {
    border: none;
    box-shadow: 7px 12px 39.8px 5px #7473711f;
    .icon-aol {
      font-size: 20px;
      color: rgb(255, 126, 0);
    }
    .col {
      &.dates,
      &.location {
        padding-left: 10px;
        .instructor-content {
          margin-left: 1.2rem;
        }
      }
      &.notes-content {
        display: flex;
        gap: 20px;
      }
    }
    .additional-info-box {
      background-color: #eee;
      padding: 5px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .info-row {
        display: flex;
        gap: 5px;
        .icon-aol {
          margin-top: 2px;
        }
      }
    }
    .register-button {
      @media (width <= 767px) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  .section-progress {
    padding: 80px 0;
    .row {
      @media (width <= 991px) {
        flex-wrap: wrap;
        gap: 20px;
      }
      @media (width <= 767px) {
        gap: 0;
        padding: 0 20px;
      }
    }
    .col {
      @media (width <= 991px) {
        width: 300px;
        max-width: unset;
        flex-basis: unset;
      }
      @media (width <= 767px) {
        flex-grow: 0;
        padding: 20px 0;
        border-bottom: rgba(255, 126, 0, 0.2) 1px solid;
        margin: auto;
        &:last-child {
          border: none;
        }
      }
    }
    .progress_box {
      display: flex;
      gap: 20px;
      @media (width <= 767px) {
      }
    }
  }
  .section-waking {
    margin-bottom: 80px;
    .container {
      display: flex;
      gap: 40px;
      @media (width <= 991px) {
        flex-direction: column;
        gap: 20px;
      }
      @media (width <= 767px) {
      }
    }
    .col-img {
      img {
        @media (width <= 991px) {
          width: 380px;
        }
        @media (width <= 767px) {
          max-width: 100%;
        }
      }
    }
    .waking-content {
      padding-top: 20px;
      @media (width <= 991px) {
        padding-top: 0;
      }
      p + p {
        margin-top: 20px;
      }
    }
    .section-title {
      margin-bottom: 20px;
      @media (width <= 767px) {
        display: none;
      }
      &.mobile {
        display: none;
        font-size: 36px;
        padding: 0 20px;
        line-height: normal;
        margin-bottom: 0;
        @media (width <= 767px) {
          display: block;
        }
      }
    }
  }
  .section-neuro-balance {
    margin-bottom: 100px;
    .neuro-balance-content {
      border-radius: 14px;
      border: #ccc 1px solid;
      padding: 0 50px;
      @media (width <= 767px) {
        padding: 0 10px;
      }
    }
    .section-title {
      margin-bottom: 20px;
      text-align: center;
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      background: white;
      padding: 10px;
      max-width: 657px;
      margin-left: auto;
      margin-right: auto;
      line-height: 46px;
      margin-top: -45px;
      @media (width <= 767px) {
        font-size: 28px;
        line-height: 36px;
        margin-top: -35px;
        margin-bottom: 10px;
      }
    }
    .neuro-desc {
      font-size: 20px;
      color: #6f7283;
      max-width: 635px;
      margin: 0 auto 40px;
      line-height: normal;
      @media (width <= 767px) {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 20px;
      }
      strong {
        font-weight: 700;
      }
    }
    .neuro-balance-features {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-bottom: 50px;
      @media (width <= 767px) {
        grid-template-columns: 1fr;
        margin-bottom: 20px;
      }
      li {
        background: #ffecda;
        border-radius: 16px;
        padding: 20px 20px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        gap: 20px;
        line-height: 20px;
        @media (width <= 767px) {
          font-size: 16px;
          padding: 15px 15px;
        }
        strong {
          font-weight: 700;
        }
        .icon-aol {
          width: 35px;
          height: 35px;
          background-color: #ecf5fc;
          border-radius: 50%;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #4f95e9;
        }
      }
    }
    .additional-info {
      max-width: 578px;
      margin: auto;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 50px;
      @media (width <= 767px) {
        margin-bottom: 20px;
      }
    }
    .neuro-action {
      margin-bottom: -30px;
      text-align: center;
      .neuro-button {
        background: rgba(255, 126, 0, 1);
        height: 61px;
        width: 458px;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        border-radius: 100px;
        border: none;
        color: white;
        @media (width <= 767px) {
          width: 100%;
          height: 53px;
        }
      }
    }
  }
  .section-feedback {
    .section-title {
      margin-bottom: 20px;
      text-align: center;
      font-size: 48px;
      font-weight: 400;
      line-height: 58px;
      margin-bottom: 10px;
      @media (width <= 767px) {
        font-size: 36px;
        line-height: 46px;
      }
    }
    .section-desc {
      font-size: 20px;
      color: rgb(111, 114, 131);
      text-align: center;
      margin-bottom: 40px;
      @media (width <= 767px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }
    .feedback-container {
      display: flex;
      gap: 40px;
      @media (width <= 991px) {
        flex-direction: column;
        gap: 20px;
      }
    }
    .feedback-content {
      display: flex;
      flex-direction: column;
    }
    .feedback-rating {
      @media (width <= 767px) {
        text-align: center;
      }
    }
    .feedback-image {
      img {
        border-radius: 10px;
        @media (width <= 767px) {
          max-width: 100%;
          height: 255px;
          object-fit: cover;
          object-position: top;
        }
      }
    }
    .feedback-text {
      font-size: 36px;
      line-height: 38px;
      font-family: 'Lora';
      padding-top: 20px;
      @media (width <= 767px) {
        font-size: 18px;
        line-height: 22px;
      }
      strong {
        font-weight: 700;
      }
    }
    .feedback-author {
      font-size: 20px;
      line-height: 22px;
      margin-top: auto;
      @media (width <= 767px) {
        font-size: 16px;
        line-height: 20px;
        margin-top: 20px;
      }
    }
  }
  section.featured-in {
    margin: 100px 0;
    @media (width <= 767px) {
      margin: 50px 0;
    }
    .section-title {
      font-size: 36px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      line-height: 36px;
      @media (width <= 767px) {
        font-size: 28px;
        line-height: 36px;
        text-align: center;
      }
      .text-sm {
        font-size: 20px;
        @media (width <= 767px) {
          font-size: 16px;
        }
      }
    }
    .featured-listing {
      display: flex;
      justify-content: space-between;
      @media (width <= 991px) {
        flex-wrap: wrap;
        gap: 50px;
        justify-content: center;
      }
      @media (width <= 767px) {
        gap: 40px 30px;
      }
      .featured-item {
        @media (width <= 767px) {
          img {
            width: auto;
          }
          &:nth-child(4) {
            order: 1;
            img {
              height: 76px;
            }
          }
          &:nth-child(3) {
            order: 2;
            img {
              height: 61px;
            }
          }
          &:nth-child(1) {
            order: 3;
            img {
              height: 45px;
            }
          }
          &:nth-child(5) {
            order: 4;
            img {
              height: 45px;
            }
          }
          &:nth-child(2) {
            order: 5;
            img {
              height: 45px;
            }
          }
        }
      }
    }
  }
  section.what-you-get {
    margin-bottom: 70px;
    .container {
      border-radius: 32px;
      background: linear-gradient(#ffefcc, #ffffff);
      padding: 50px 20px;
      @media (width <= 767px) {
        padding: 30px 15px;
      }
    }
    .section-title {
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      font-weight: 400;
      margin-bottom: 40px;
      @media (width <= 767px) {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 20px;
      }
    }
    .section-desc {
      max-width: 845px;
      text-align: center;
      margin: 0 auto 50px;
      font-size: 20px;
      color: rgb(111, 114, 131);
      line-height: 26px;
      @media (width <= 767px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
      }
    }
    .get-features {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-bottom: 50px;
      max-width: 912px;
      margin: auto;
      @media (width <= 767px) {
        grid-template-columns: 1fr;
        gap: 15px;
      }
      li {
        background: white;
        border-radius: 16px;
        padding: 20px 20px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        gap: 20px;
        line-height: 20px;
        @media (width <= 767px) {
          font-size: 16px;
          padding: 15px 15px;
        }
        strong {
          font-weight: 700;
        }
        .icon-aol {
          width: 35px;
          height: 35px;
          background-color: #ecf5fc;
          border-radius: 50%;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #4f95e9;
        }
      }
    }
    .feature-tagline {
      font-weight: 500;
      font-size: 36px;
      line-height: 45px;
      text-align: center;
      font-family: 'Lora';
      margin-top: 50px;
      margin-bottom: 30px;
      @media (width <= 767px) {
        display: none;
      }
    }
    .feature-action {
      text-align: center;
      @media (width <= 767px) {
        display: none;
      }
      .feature-button {
        background: rgba(255, 126, 0, 1);
        height: 60px;
        width: 241px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        border-radius: 100px;
        border: none;
        color: white;
      }
    }
  }
  section.the-science {
    margin-bottom: 100px;
    .section-title {
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      font-weight: 400;
      margin-bottom: 20px;
      @media (width <= 767px) {
        font-size: 36px;
        line-height: 40px;
      }
    }
    .section-desc {
      text-align: center;
      margin-bottom: 30px;
      font-size: 20px;
      color: rgb(111, 114, 131);
      line-height: 22px;
      @media (width <= 767px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .video-science {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      iframe {
        aspect-ratio: 16/9;
        max-width: 100%;
        height: unset;
      }
    }
    .science-features {
      max-width: 690px;
      margin: auto;
      display: grid;
      gap: 15px;
      grid-template-columns: 1fr 1fr;
      @media (width <= 767px) {
        grid-template-columns: 1fr;
      }
    }
    .feature-box {
      background: linear-gradient(#ffecd9, #fff);
      border-radius: 8px;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      @media (width <= 767px) {
        gap: 20px;
      }
      .feature-icon {
        img {
          width: 54px;
          height: 54px;
        }
      }
      .feature-title {
        text-align: center;
        font-size: 26px;
        line-height: 29px;
        font-family: 'Lora';
        font-weight: 500;
        text-wrap: balance;
        max-width: 260px;
        margin: 0 auto 20px;
      }
      .feature-desc {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        font-family: 'Lora';
        text-wrap: balance;
        max-width: 260px;
        margin: 0 auto;
      }
    }
  }
  .section-testimonials {
    background: rgba(253, 248, 244, 1);
    .container {
      position: relative;
      padding: 60px 0;
      @media (width <= 767px) {
        padding-top: 20px;
      }
    }
    .quote-icon {
      position: absolute;
      top: -50px;
      left: 0;
      transform: translateY(-50%);
      font-weight: 400;
      font-size: 800px;
      color: rgb(236, 236, 236);
      z-index: 0;
      @media (width <= 767px) {
        top: 20px;
        transform: translateX(-50%);
        line-height: 50px;
      }
    }
    .section-title {
      text-align: center;
      font-size: 48px;
      line-height: 58px;
      font-weight: 400;
      margin-bottom: 20px;
      position: relative;
      z-index: 1;
      @media (width <= 767px) {
        font-size: 36px;
        line-height: 40px;
      }
    }
    .testimonials-listing {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      max-width: 920px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 1;
      @media (width <= 767px) {
        flex-direction: column;
        gap: 50px;
      }
    }
    .testimonial-item {
      flex: 1;
      border-right: 1px solid rgba(229, 229, 229, 1);
      padding: 0 30px;
      @media (width <= 767px) {
        border: none;
        padding: 0 20px;
      }
      &:first-child {
        padding-left: 0;
        @media (width <= 767px) {
          padding-left: 20px;
        }
      }
      &:last-child {
        border: none;
        padding-right: 0;
      }
      .author-info {
        display: flex;
        gap: 20px;
        border-radius: 100%;
        align-items: center;
      }
      .author-picutre {
        flex-shrink: 0;
        img {
          width: 75px;
          height: 75px;
          border-radius: 100%;
        }
      }
      .author-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
      .author-position {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: rgb(200, 200, 200);
      }
      .testimony-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 20px;
      }
      .testimony-text {
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
        color: rgb(105, 105, 105);
        font-style: italic;
      }
    }
  }
  section.sa-protocol {
    margin: 50px 0;
    .protocol-box {
      border-radius: 22px;
      border: #ccc 1px solid;
      background: url(/img/protocol-box-bg.jpg) no-repeat bottom right;
      background-size: cover;
      width: 1045px;
      padding: 50px 70px;
      margin: auto;
      max-width: 100%;
      @media (width <= 767px) {
        background: url(/img/protocol-box-bg-mobile.jpg) no-repeat top right;
        background-size: cover;
        height: 534px;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }
      .tagline {
        font-size: 14px;
        color: rgb(91, 134, 236);
        font-weight: 600;
        margin-bottom: 20px;
        @media (width <= 767px) {
          margin-bottom: 10px;
        }
      }
      .box-title {
        font-family: 'Lora';
        font-size: 36px;
        line-height: 38px;
        font-weight: 700;
        margin-bottom: 30px;
        @media (width <= 767px) {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 10px;
        }
      }
      .course-price {
        color: rgb(96, 98, 112);
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 20px;
        @media (width <= 767px) {
          font-size: 20px;
          line-height: 22px;
        }
        s {
          color: rgb(205, 37, 40);
        }
      }
      .box-button {
        background-color: rgba(255, 126, 0, 1);
        width: 308px;
        height: 61px;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        border-radius: 100px;
        border: none;
        color: white;
        @media (width <= 767px) {
          width: 100%;
          height: 53px;
          font-weight: 500;
        }
      }
    }
  }
  section.meet-gurudev {
    background: url(/img/meet-gurudev-bg.jpg) no-repeat top right;
    background-size: cover;
    @media (width <= 991px) {
      background-position: 100%;
    }
    @media (width <= 767px) {
      background: url(/img/meet-gurudev-bg-mobile.jpg) no-repeat top center;
    }
    .container {
      max-width: 1024px;
      padding: 120px 0 150px;
      @media (width <= 991px) {
        padding: 450px 30px 30px;
        display: flex;
        justify-content: center;
      }
    }
    .content-box {
      width: 396px;
      background: white;
      border-radius: 8px;
      padding: 40px 30px;
      @media (width <= 767px) {
        max-width: 100%;
        padding: 15px;
      }
    }
    .section-title {
      font-size: 36px;
      line-height: 45px;
      font-weight: 400;
      margin-bottom: 10px;
      @media (width <= 767px) {
        font-size: 30px;
        line-height: 36px;
      }
      span {
        color: rgb(255, 126, 0);
      }
    }
    .section-desc {
      @media (width <= 767px) {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
  .section-faq {
    margin: 50px 0;
    .section-title {
      font-size: 48px;
      line-height: 58px;
      font-weight: 400;
      margin-bottom: 50px;
      text-align: center;
      @media (width <= 767px) {
        font-size: 36px;
        line-height: 40px;
      }
    }
    .accordion {
      .card {
        padding: 1em;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
        width: 95%;
        margin: 10px auto;
        border-radius: 24px;
        border: none;
      }
      .card-header {
        padding-left: 0;
        background: none;
        border: none;
        @media (width <= 767px) {
          padding-right: 0;
        }
        button {
          display: flex;
          justify-content: space-between;
          font-family: 'Lora';
          font-size: 28px;
          line-height: normal;
          color: black;
          text-align: left;
          width: 100%;
          @media (width <= 767px) {
            font-size: 20px;
          }
          &:hover,
          &:focus {
            text-decoration: none;
          }
          &[aria-expanded='true'],
          &[aria-expanded='false'] {
            &::after {
              content: '-';
              font-size: 28px;
              border: #000 1px solid;
              border-radius: 100%;
              height: 23px;
              width: 23px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
            }
          }
          &[aria-expanded='false'] {
            &::after {
              content: '+';
            }
          }
        }
      }
      .card-body {
        color: rgb(111, 114, 131);
        font-size: 18px;
        line-height: 24px;
        @media (width <= 767px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  .reset-system {
    margin-top: 100px;
    margin-bottom: 50px;
    .section-title {
      font-size: 48px;
      line-height: 58px;
      font-weight: 400;
      margin-bottom: 50px;
      text-align: center;
      color: white;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      @media (width <= 767px) {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 30px;
      }
    }
    .reset-system-box {
      background: linear-gradient(#ff7e00, #ffecd9);
      border-radius: 32px;
    }
    .reset-system-box-inner {
      padding: 50px;
      background-image: url(/img/swirl-left.png), url(/img/swirl-right.png);
      background-repeat: no-repeat;
      background-position:
        20px 0px,
        100% 100%;
      @media (width <= 767px) {
        background: none;
        padding: 30px 20px;
      }
    }
    .reset-system-action {
      text-align: center;
      .reset-system-button {
        background: rgba(255, 126, 0, 1);
        height: 60px;
        width: 241px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        border-radius: 100px;
        border: none;
        color: white;
        @media (width <= 767px) {
          width: 100%;
          height: 53px;
        }
      }
    }
  }
}

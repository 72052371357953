@media only screen and (max-width: 1199px) {
  .testimonial-modal {
    &__body {
      width: 767px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-modal {
    &__body {
      width: 100% !important;
      height: calc(100% - 71px);
      top: 71px;
      left: 0;
      transform: none;
      border-radius: 0;
      border: none;
      padding: 0;

      &__close-button {
        top: 40px;
        right: unset;
        left: 20px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-modal {
    &__body {
      height: calc(100% - 56px);
      top: 56px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .new-testimonial {
    &__body {
      &__header {
        padding: 40px 20px 30px;
      }

      &__media {
        padding: 0;
        background: none;
        justify-content: center;

        &__preview {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin: 0;
        }

        &__label {
          display: none;
        }
      }

      &__fields {
        padding: 30px 20px 15px;
        overflow-y: scroll;
      }

      &__footer {
        padding: 15px 20px 50px;
        background: none;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .new-testimonial {
    &__body {
      &__footer {
        button {
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}

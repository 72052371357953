.icon-container-success {
  color: green;
  font-size: 45px;
}
.icon-container-error {
  color: rgb(201, 27, 27);
  font-size: 45px;
}
.success-message-container-cp {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  border-radius: 8px;
  .success-message {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-spacing: 10px;
    padding: 0px 15px;
    .icon-container {
      color: green;
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
}
.login-register-page,
.auth,
.world-culture-festival__container {
  .success-message-container {
    position: absolute;
    margin: 0 auto;
    text-align: center;
    max-width: 500px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    border-radius: 8px;
    .success-message {
      display: block;
      position: absolute;
      width: 100%;
      top: 40%;

      .icon-container {
        color: green;
        font-size: 45px;
        margin: 0 auto;
        width: 46px;
      }
    }
  }
  .info {
    font-size: 13px;
    line-height: 1.8;
    text-align: center;
    margin-top: 0;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.54);
  }

  .back-button {
    left: 22px;
    box-sizing: content-box !important;
    background: #fff;
    border-radius: 100px;
    height: 10px;
    width: 10px;
    padding: 0;
    position: absolute;
    top: 71px;
    right: 14px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    color: #333;
    z-index: 100;
    border: 6px solid #fff;
    cursor: pointer;
    line-height: 0;
    &:focus {
      outline: none;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    }
    &:hover {
      outline: none;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    }
  }
}

@media screen and (max-width: 991px) {
  .auth {
    .back-button {
      top: 179px;
    }
  }
}

.research {
  padding: 100px 0;
  background-image: url('/img/research-bg.png');
  background-size: cover;

  &__title {
    font-size: 30px;
  }

  &__list__mobile-img {
    display: none;
  }

  &__text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    max-width: 847px;
  }

  &__quote,
  &__link {
    font-size: 20px;
    font-style: italic;
    color: #31364e;
    margin: 30px 0 30px 0;
  }

  &__link {
    display: inline-block;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: #89beec;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
    border: solid 1px #f7f5f4;
    background-color: #ffffff;
    img {
      max-height: 60px;
    }
  }

  &__list-pagination {
    .swiper-pagination-bullet {
      opacity: 0.5;
      background-color: #efeeec;
      transition: all 0.3s ease-in-out;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background-color: white;
    }
  }

  &__list {
    margin-top: 40px;
    max-width: 555px;
    padding: 30px 25px 40px;
    border-radius: 8px;
    border: $grey-border;
    background-color: white;

    &__item {
      &:not(:last-child) {
        margin-bottom: 25px;
      }

      h6 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
      }

      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
      }
    }
  }

  &.sahaj-samadhi {
    background-image: url('/img/sahaj-samadhi-research.png');
    background-position-y: 100%;

    &__title {
      font-size: 36px;
    }
  }
}

.art-of-silence {
  &.course-filter {
    padding-top: 0;
  }
  @media screen and (max-width: 991px) {
    padding-top: 0 !important;
  }
  @media screen and (max-width: 767px) {
    .container {
      max-width: 360px;
    }
  }

  .samadhi-top-section {
    background: url(/img/samadhi-page-bg.png) repeat-x top left;
    @media screen and (max-width: 991px) {
      background-color: #fffbf0;
    }
    @media screen and (max-width: 767px) {
      background: #fffbf0;
    }
    .banner {
      height: 280px;
      width: 100%;
      background: url(/img/art-of-silence-banner.webp) no-repeat top left;
      padding: 60px 76px 50px 76px;
      background-size: auto;
      background-position: center;
      .container {
        @media screen and (min-width: 992px) {
          padding-left: 76px;
        }
      }
      .courses-title {
        display: none;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
      &-title {
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        max-width: 480px;
        margin-bottom: 40px;
      }
      &-description {
        font-size: 18px;
        line-height: 22px;
        max-width: 550px;
      }
      &-description-detail {
        font-size: 14px;
        line-height: 22px;
        max-width: 550px;
      }
      @media screen and (max-width: 991px) {
        background-position: bottom center;
        height: 300px;
        padding-left: 0;
        &-title {
          font-size: 35px;
          line-height: 35px;
        }
      }
      @media screen and (max-width: 767px) {
        background: url(/img/art-of-silence-banner-mobile.png) no-repeat bottom
          center;
        height: 600px;
        padding: 36px 32px 10px;
        background-size: cover;
        background-position: center bottom;
        &-title {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 30px;
        }
        .courses-title {
          display: block;
          margin-bottom: 24px;
        }
      }
      .hero-register-button-wrapper {
        margin-top: 50px;
        margin-bottom: 20px;
        .hero-register-button {
          background: linear-gradient(90deg, #57bacd 9.51%, #0398b5 93.54%);
          color: #fff;
          border: 0px;
          border-radius: 10px;
          padding: 1rem 4rem;
        }
        @media only screen and (max-width: 768px) {
          text-align: center;
          margin-top: 30px;
          .hero-register-button {
          }
        }
      }
    }

    .content-video-area {
      display: flex;
      padding: 40px 76px;
      .section-title {
        margin: 0 0 0 0;
        font-family: 'Work Sans';
        font-size: 36px;
        line-height: 36px;
        font-weight: 600;
        max-width: 650px;
        margin-bottom: 40px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        margin: 25px 0;
      }
      .video-wrapper {
        padding: 50px 0 0 60px;
      }
      @media screen and (max-width: 991px) {
        padding: 40px 0px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 20px 20px;
        .video-wrapper {
          padding: 0;
          iframe {
            width: 100%;
          }
        }
      }
    }
  }
  .samadhi-featuers {
    display: flex;
    padding: 40px 76px 10px;
    .feature-box {
      display: flex;
      justify-items: center;
      align-items: center;
      flex-grow: 1;
    }
    .feature-icon {
      width: 88px;
    }
    .feature-text {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      max-width: 200px;
    }
    @media screen and (max-width: 991px) {
      padding: 30px 0;
      .feature-box {
        padding-right: 20px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 20px 0 0 20px;
      .feature-box {
        flex-direction: column;
        padding: 0;
        &:nth-child(2) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .feature-text {
        font-size: 16px;
        padding-top: 25px;
      }
    }
  }
  .samadhi-benefits-section {
    padding: 60px 40px 0;
    background: url(/img/sahaj-samadhi-benefit-moon.webp) no-repeat top right;
    text-align: center;
    .section-title {
      font-family: 'Work Sans';
      font-weight: 400;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .section-description {
      font-size: 18px;
      line-height: 22px;
    }
    .samadhi-benefits-wrapper {
      padding-top: 40px;
    }
    .samadhi-benefit-box {
      height: 420px;
      display: flex;
      padding: 20px 65px 60px;
      flex-direction: column;
      &.box1 {
        background: url(/img/aos-benefit-deep-meditations.webp) no-repeat top
          right;
      }
      &.box2 {
        background: url(/img/aos-benefit-silence.webp) no-repeat top right;
      }
      &.box3 {
        background: url(/img/aos-benefit-yoga.webp) no-repeat top right;
      }
      &.box4 {
        background: url(/img/aos-benefit-ancient-wisdom.webp) no-repeat top
          right;
      }
      .benefit-title {
        margin-top: auto;
        font-family: 'Lora';
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 22px;
      }
    }
    @media screen and (max-width: 991px) {
      padding: 40px 0 0 0;
    }
    @media screen and (max-width: 767px) {
      &.container {
        width: 100%;
        background: white;
        max-width: 100%;
        padding-top: 0;
        & > * {
          max-width: 360px;
          margin: auto;
        }
        .section-title {
          text-align: left;
          font-size: 26px;
          padding-left: 20px;
          padding-top: 40px;
          height: 120px;
          padding-bottom: 25px;
          background: url(/img/sahaj-samadhi-benefit-moon.png) no-repeat right
            30px;
          background-size: 200px;
        }
        .section-description {
          display: none;
        }
      }
      .samadhi-benefit-box {
        height: 400px;
        text-align: left;
        padding: 24px;
        background-size: cover !important;
        background-position: top center !important;
        .benefit-title {
          font-size: 28px;
          line-height: 30px;
        }
        .benefit-text {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .samadhi-benefits-wrapper {
        padding-top: 0px;
      }
    }
  }
  .section-sahaj-reviews {
    padding-top: 112px;
    background: url(/img/samadhi-carousel-bg.png) repeat-x top left;
    padding-bottom: 90px;
    position: relative;
    .section-title {
      font-family: 'Work Sans';
      font-size: 36px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      margin-bottom: 70px;
    }
    .swiper-wrapper {
      display: flex;
    }
    .swiper-slide {
      align-self: stretch;
      height: auto;
    }
    .review-box {
      background: white;
      border-bottom: #a0b2fd 8px solid;
      padding: 30px;
      height: 100%;
    }
    .review-title {
      font-family: 'Lora';
      font-size: 36px;
      font-weight: 400;
      line-height: 30px;
      font-style: italic;
      padding: 0 50px 30px 0;
      background: url(/img/review-quote.png) no-repeat top right;
    }
    .review-text {
      font-family: 'Lora';
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
    .review-author {
      display: flex;
      padding-top: 25px;
      align-items: center;
      img {
        height: 55px;
        width: 55px;
        border-radius: 100%;
      }
    }
    .reviewer-photo {
      margin-right: 20px;
    }
    .reviewer-info {
      font-size: 14px;
      line-height: 20px;
    }
    .reviewer-name {
      font-weight: 600;
    }
    .reviwer-position {
      color: #6f6f6f;
    }
    .swiper-pagination {
      bottom: 0px;
    }
    .reviews-slider {
      padding-bottom: 60px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 0;
      .swiper-slide {
        padding: 0 20px;
      }
      .section-title {
        font-size: 28px;
        line-height: 30px;
        padding: 0 30px;
        text-align: left;
        margin-bottom: 36px;
      }
      .review-box {
        padding: 20px;
      }
      .review-title {
        font-size: 28px;
        line-height: 30px;
      }
      .review-text {
        font-size: 16px;
        line-height: 20px;
      }
      .review-author {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .container {
    &.banner2,
    &.banner3 {
      padding: 0;
    }
  }
  .aos-banner-second {
    background: url(/img/aos-banner-2.webp) no-repeat top left;
    height: 400px;
    padding: 94px 0 0 77px;
    margin: 20px 15px;
    .guru-quote {
      font-size: 42px;
      line-height: 42px;
      color: #fdf4e1;
      width: 564px;
      font-family: 'Lora';
    }
    .quote-author {
      font-size: 24px;
      line-height: 24px;
      color: #fdf4e1;
      width: 564px;
      font-family: 'Lora';
      padding-top: 37px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-left: 0;
      margin-right: 0;
      background: url(/img/aos-quote-banner-mobile.png) no-repeat bottom left;
      height: 428px;
      padding: 32px 40px;
      .guru-quote {
        font-size: 26px;
        line-height: 24px;
        width: auto;
        text-align: center;
      }
      .quote-author {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        width: auto;
        padding-top: 10px;
      }
    }
  }
  .aos-banner-third {
    background: url(/img/aos-banner-3.webp) no-repeat top left;
    height: 400px;
    padding: 75px 120px 0 493px;
    margin: 20px 15px;
    .banner-title {
      font-size: 36px;
      line-height: 46px;
      font-weight: 600;
    }
    .banner-text {
      font-size: 18px;
      line-height: 22px;
      padding-top: 20px;
      margin-bottom: 40px;
    }
    .enroll-btn {
      width: 300px;
      height: 62px;
      background: linear-gradient(90deg, #57bacd 9.51%, #0398b5 93.54%);
      border-radius: 10px;
      border: none;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      font-weight: 600;
    }
    @media screen and (max-width: 1199px) {
      background-repeat: repeat-x;
      background-position: -220px 0;
      padding: 75px 50px 0 250px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-left: 0;
      margin-right: 0;
      padding: 39px 26px 20px;
      min-height: 370px;
      background: url(/img/aos-quote-banner3-mobile.png) no-repeat top right;
      .banner-title {
        font-size: 26px;
        line-height: 30px;
        font-weight: 600;
      }
      .banner-text {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        letter-spacing: -0.5px;
      }
    }
  }
  .registration-widget {
    .circle:before {
      background-color: #0398b5;
    }
    .fa-solid,
    .fa-solid:before {
      color: #0398b5;
    }
    .register-button {
      background: linear-gradient(90deg, #57bacd 9.51%, #0398b5 93.54%);
      .fa-solid:before {
        color: #ffffff;
      }
    }
    .notes {
      .content {
        /* Track */
        &::-webkit-scrollbar-track {
          background: #ecf6fe;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #57bacd;
          border-radius: 4px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #0398b5;
        }
      }
    }
    .early-bird-banner {
      background: #0398b530;
    }
  }
  .float-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    z-index: 10;
  }
  .float-wrapper {
    max-width: 1125px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      max-width: 320px;
    }
  }
  .bar-left {
    text-align: right;
    padding-right: 10px;
    @media screen and (max-width: 767px) {
      width: 50%;
      padding-right: 0px;
    }
    .bar-title {
      font-family: 'museo_sans500';
      font-size: 24px;
      line-height: normal;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 17px;
        text-align: left;
        padding: 8px 0 0 10px;
      }
    }
  }
  .bar-right {
    text-align: left;
    padding-left: 10px;
    button {
      background: linear-gradient(90deg, #57bacd 9.51%, #0398b5 93.54%);
      color: #fff;
      border: 0px;
      border-radius: 10px;
      padding: 1rem 4rem;
      white-space: nowrap;
      @media screen and (max-width: 767px) {
        padding: 10px 20px;
      }
    }
  }
  .healing-breaths {
    .container {
      padding: 60px 0;
      @media screen and (max-width: 767px) {
        padding: 20px;
      }
    }
    .title {
      font-family: Lora;
      font-weight: bolder;
      font-size: 56px;
      text-align: center;
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }
    .org-text {
      font-size: 26px;
      text-align: center;
      max-width: 770px;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    .benefitted-orgs-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
    .org-list-item {
      padding: 30px 0 10px 0;
      margin: 0 20px;
      @media screen and (max-width: 767px) {
        flex: calc(50% - 40px);
        img {
          max-width: 100%;
          height: auto;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:nth-last-child(2) {
        margin-left: auto;
        margin-right: 60px;
        @media screen and (max-width: 767px) {
          margin: 0 20px;
        }
      }
      &:nth-last-child(1) {
        margin-right: auto;
        margin-left: 60px;
        @media screen and (max-width: 767px) {
          margin: 0 20px;
        }
      }
    }
  }
}

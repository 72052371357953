.order__card .input-block.validate-error .select-box__current {
  border-color: #fa4141;
}
.order__card .order__card__payment.validate-error .select-box__current {
  border-color: #fa4141;
}
.react-tag-container {
  outline: none;
  border: none;
  width: 250px;
  border-bottom: 1px solid #e9e9e9;
  background-color: transparent;
  font-size: 16px;
  line-height: 2;
  color: #31364e;
  transition: all 0.3s ease-in-out;
  padding: 0px;
  margin-top: 1.5rem;
  .react-tag-label {
    top: -16px;
  }
  .badge-light {
    color: #9b9b9b;
  }
  .react-tag {
    font-weight: normal;
    font-size: 16px;
    display: inline-block;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    padding-right: 18px;
  }
  .react-tag-remove {
    text-decoration: none;
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
    margin-left: 10px;
    color: rgb(75, 84, 135);
  }
}
.ball-clip-rotate > div {
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  height: 26px;
  width: 26px;
  background: transparent !important;
  display: inline-block;
  -webkit-animation: rotate 0.75s 0s linear infinite;
  animation: rotate 0.75s 0s linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
.loaded {
  transition: opacity 0.25s linear;
  opacity: 1;
}
.select-box__list {
  z-index: 5 !important;
}
.price {
  font-weight: 600;
}
.room__board {
  width: 100%;
  padding: 20px 30px;
  border-top: 1px solid #e8f2fc;

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #9598a6;
    text-transform: uppercase;
  }
  &__sub-heading {
    font-size: 12px;
    font-weight: 600;
    color: #9598a6;
    margin-top: 6px;
  }
}

.room__board {
  width: 100%;
  padding: 20px 30px;
  border-top: 1px solid #e8f2fc;

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #9598a6;
    text-transform: uppercase;
  }
  &__sub-heading {
    font-size: 12px;
    font-weight: 600;
    color: #9598a6;
    margin-bottom: 16px;
  }
}

.mobile_board {
  margin-bottom: 20px;
}

.select-room {
  position: relative;
  display: block;
  width: 100%;
  white-space: nowrap;
  color: #31364e;

  &.no-valid {
    .select-room__current {
      border-color: #fa4141;

      &::after {
        border-top-color: #fa4141;
      }
    }
  }

  &__current {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #e9e9e9;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    line-height: 2;
    transition: all 0.25s ease-in-out;
    padding: 0;
    text-align: left;

    &:hover:not(:focus) {
      border-color: #89beec;

      &::after {
        border-top-color: #89beec;
      }
    }

    &::after {
      right: 20px;
      content: '';
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #c4c5cc;
      transition: all 0.25s ease-in-out;
    }

    &:focus {
      border-color: #2465b3;

      &::after {
        transform: translateY(-50%) rotate(180deg);
        border-top-color: #2465b3;
      }

      & + .select-room__list {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__placeholder {
    border-radius: 8px;
    padding: 8px 15px;
    position: absolute;
    z-index: 0;
    color: #9598a6;
  }

  &__value {
    width: min-content;
  }

  &__input {
    display: none;

    &:checked + .select-room__input-text {
      display: flex;
      align-items: center;
    }

    &-text {
      display: none;
      position: absolute;
      z-index: 1;
      width: calc(100% - 2px);
      margin: 0;
      background-color: white;
      border-radius: 8px;
      padding: 8px 40px 8px 15px;
      justify-content: space-between;
    }
  }

  &__list {
    margin-top: 0;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    z-index: 1;
    min-width: min-content;
    padding: 8px 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    transition: 0.25s;
    background-color: white;
    width: 100%;
    border: 1px solid #2465b3;
    border-radius: 8px;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    padding: 12px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.25s ease-in-out;

    img {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: rgba(137, 190, 236, 0.15);
    }
  }
}
.reciept__payment {
  &_list {
    padding: 20px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      line-height: 1;
      color: #31364e;
      font-weight: 600;

      .discount {
        font-weight: 400;
      }

      &:not(:first-child) {
        margin-top: 15px;
      }

      &.link {
        margin-top: 5px;

        a {
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.btn-item {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }
}

.workshops_wraper {
  padding: 40px 0px;
}
.tandc p {
  margin-bottom: 1rem;
}

.hidden-border {
  border: none !important;
  padding: 0px !important;
  a {
    text-decoration: none;
  }
}

.loader-inline {
  width: 28px;
  height: 28px;
  border: 5px solid rgb(228, 223, 223);
  border-bottom-color: #3d8be8;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  right: 0px;
  top: 20px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 991px) {
  .react-tag-container {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #e9e9e9;
    padding: 15px;
    position: relative;
    .loader-inline {
      position: absolute;
      right: 10px;
      top: 20px;
    }
  }
  .loader-inline {
    position: absolute;
    right: 10px;
    top: 40px;
  }
}

.studies {
  padding: 100px 0;

  &__title {
    margin-bottom: 60px;
    text-align: center;
  }

  &__block {
    display: flex;
    align-items: center;
  }

  &__info-block {
    display: flex;
    flex-direction: column;

    p {
      font-size: 16px;
      line-height: 1.38;
      color: #9598a6;
      margin-bottom: 32px;
    }
  }

  &__number {
    margin-right: 10px;
    font-size: 80px;
    font-weight: 600;
    color: #31364e;
  }

  &__name {
    font-size: 28px;
    font-weight: 600;
    color: #31364e;
  }

  &__info {
    position: relative;
    display: flex;
    align-items: center;

    img {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 25px;
    }

    // &::before {
    //   position: absolute;
    //   content: url("/img/mask.svg");
    //   z-index: -1;
    //   top: 0;
    //   left: 25px;
    // }
  }

  &__text {
    font-size: 20px;
    line-height: 1.3;
    color: #31364e;
    margin-left: 97px;

    span {
      display: block;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  &__result {
    border-radius: 8px;
    border: solid 1px #e9e9e9;
    padding: 60px 0;
    margin-top: 60px;
  }

  &__result-title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    h6 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }

    &-img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border: solid 2px rgba(237, 153, 78, 0.1);
      border-radius: 50%;
      background-color: #ffffff;
      font-size: 28px;
      font-weight: 600;
      margin: 30px 0 22px 0;

      img {
        position: absolute;
        top: -8px;
        right: -15px;
        width: 40px;
        height: 40px;
        border: solid 1px #e9e9e9;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }

    &-text {
      h3 {
        display: none;
      }

      p {
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 500;
        color: #31364e;

        span {
          font-weight: 600;
          letter-spacing: 0.3px;
        }
      }
    }

    &_violet {
      h6 {
        color: #ed994e;
      }

      div {
        color: #ed994e;

        img {
          box-shadow: 0 0 11px 0 rgba(237, 153, 78, 0.4);
        }
      }
    }

    &_blue {
      h6 {
        color: #4191e9;
      }

      .studies__item-img {
        border: solid 2px rgba(65, 145, 233, 0.1);
      }

      div {
        color: #4191e9;

        img {
          box-shadow: 0 0 11px 0 rgba(61, 139, 232, 0.4);
        }
      }
    }
  }
}

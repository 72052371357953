@media only screen and (max-width: 991px) {
  .testimonial {
    &__content {
      padding: 20px;

      &__quote {
        font-size: 16px;
      }

      &__person {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.29;
      }

      &__tags {
        position: static;
        width: 100% !important;

        span {
          background-color: rgba(49, 54, 78, 0.1);
        }
      }
    }

    &_wide {
      flex-direction: column;

      .testimonial {
        &__media,
        &__content {
          width: 100%;
          margin: 0;
        }

        &__media {
          height: 200px;
          border-radius: 16px 16px 0 0;
          position: relative;
        }

        &__content {
          border: 1px solid #e9e9e9;
          border-radius: 0 0 16px 16px;
          border-top: none;
        }
      }
    }

    &:not(.testimonial_wide) {
      width: 315px;
      padding: 0;

      .testimonial {
        &__media {
          position: absolute;
          top: 20px;
          left: 20px;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          z-index: 1;
          overflow: visible;

          img,
          video {
            border-radius: 50%;
          }

          &::after {
            content: '“';
            position: absolute;
            top: -10px;
            right: -15px;
            font-size: 50px;
            font-weight: 600;
            color: #31364e;
          }

          &__name {
            font-size: 24px;
          }
        }

        &__content {
          border: 1px solid #e9e9e9;
          border-radius: 16px;
          padding-bottom: 15px;

          &__text {
            margin-bottom: 20px;

            p {
              display: none;
            }
          }

          &__person {
            order: -1;
            margin-left: 85px;
          }
        }
      }
    }
  }
}

.breath-meditation {
  .welcome {
    padding: 80px 0;

    a.btn-primary {
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      border: none;
      color: #fff;
    }

    a.course-link {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #ed994e;
      background: url(/img/find-course-arrow.webp) no-repeat center right;
      padding: 5px 44px 5px 0;
      text-decoration: none;
      display: inline-block;
    }

    &__container {
      display: flex;
      align-items: center;
      gap: 72px;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__heading {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;

      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }

    &__title {
      font-weight: 700;
      font-size: 44px;
      line-height: 60px;
      margin-bottom: 20px;
    }

    &__description {
      line-height: 22px;
      margin-bottom: 30px;
    }

    &__description.v1 {
      font-size: 18px;
    }

    &__description.course-highlights-info-box {
      ul {
        margin-bottom: 1rem;
        > li {
          position: relative;
          padding: 0px 0px 10px 20px;
          &::before {
            position: absolute;
            top: 0px;
            margin: 0px 0 0 -32px;
            content: '';
            vertical-align: middle;
            display: inline-block;
            background: rgb(249 115 22) url(/img/checkicon-css.svg) no-repeat
              center;
            width: 20px;
            height: 20px;
            border-radius: 4px;
          }
        }
      }
    }

    &__btn-course {
      border-radius: 8px;
      height: 52px;
      font-size: 16px;
      width: 100%;
      max-width: 188px;
      justify-content: center;

      display: none;
    }

    &__btn-calendar {
      display: inline-flex;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
      color: $orange;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 1px;
        left: 0;
        right: 0;
        background-color: $orange;
      }
    }

    &__player {
      flex-basis: 572px;
      flex-shrink: 0;
    }
  }

  .player-welcome {
    position: relative;

    &__cover {
      border-radius: 8px;
      -webkit-user-drag: none;
      width: 100%;
      height: 100%;
    }

    &__btn-play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: linear-gradient(180deg, #96bbef 0%, #5b86ec 100%);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    &__content {
      color: $white;
      position: absolute;
      bottom: 28px;
      left: 28px;
    }

    &__title {
      font: 28px/38px 'Lora';
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .schedule {
    padding-bottom: 80px;

    .scheduleDownload {
      background: linear-gradient(
          180deg,
          #ffffff 20%,
          rgba(255, 255, 255, 0.8) 55%,
          rgba(255, 255, 255, 0) 100%
        ),
        url('/img/schedule-download-bg.png');
    }

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: $blue;
      margin-bottom: 28px;
    }

    &__steps {
      display: flex;
      gap: 40px;
      align-items: flex-start;
    }

    &__step {
      flex: 0 0 316px;
    }

    &__icon {
      margin-bottom: 20px;
    }

    &__heading {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    &__text {
      line-height: 22px;
    }

    &__navigation {
      margin-top: 20px;
    }

    &__btn-select {
      height: 52px;
      border-radius: 8px;
      letter-spacing: -0.02em;
      text-transform: capitalize;
    }
  }

  .sidebar-schedule {
    flex: 0 0 372px;
    width: 372px;
    box-shadow:
      0 27px 80px rgba(7, 14, 61, 0.03),
      0 10px 29.2px rgba(7, 14, 61, 0.02),
      0 4.78464px 14.1767px rgba(7, 14, 61, 0.0166887),
      0px 2.34552px 6.94968px rgba(7, 14, 61, 0.0133113),
      0 0.9px 2.75px rgba(7, 14, 61, 0.009);
    border-radius: 12px;
    padding: 32px 40px;

    &__title {
      font: 24px/32px 'Lora';
      font-weight: 700;
      margin-bottom: 20px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__item {
      display: flex;
      gap: 16px;
      align-items: flex-start;
    }

    &__date {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__time {
      font-size: 14px;
      line-height: 22px;
      color: $darkGray;
    }
  }

  .journey {
    padding: 80px 0 230px 0;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    &__title {
      text-align: center;
      font-weight: 700;
      font-size: 44px;
      line-height: 60px;
      margin-bottom: 60px;
    }

    &__benefits {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    &__divider {
      margin-top: 33px;
      flex-shrink: 0;
    }
  }

  .benefit-journey {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;

    &__status {
      width: 80px;
      height: 80px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;

      background: $white;
      box-shadow:
        0 27px 80px rgba(7, 14, 61, 0.03),
        0 9.8px 29.2px rgba(7, 14, 61, 0.02),
        0 4.7px 14.17px rgba(7, 14, 61, 0.016),
        0 2.34px 6.9px rgba(7, 14, 61, 0.013),
        0 0.9px 2.74px rgba(7, 14, 61, 0.009);
      border-radius: 12px;
      margin-bottom: 32px;
    }

    &__number {
      position: absolute;
      top: 4px;
      right: 1px;
      font: 24px/1px 'Lora';
      font-weight: 700;
    }

    &__heading {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      font-weight: 600;
    }

    &__description {
      line-height: 22px;
      color: $grey;
    }
  }

  .download-schedule {
    display: flex;
    gap: 40px;
    flex-grow: 1;
    max-width: 672px;
    flex-direction: column;
    justify-content: center;
    padding: 52px 60px;
    border-radius: 12px;

    background-size: cover !important;
    background-repeat: no-repeat !important;

    &__title {
      font: 700 28px/38px 'Lora';
      margin: 0;
    }

    &__actions {
      display: flex;
      gap: 24px;
    }

    &__link {
      background: #000;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      width: 140px;
      height: 50px;

      font-size: 10px;
      line-height: 10px;
      color: #ffffff;
      text-decoration: none;

      font-family: 'Metropolis';
      font-weight: 500;
    }

    &__market {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
    }
  }
}

@media (max-width: 1199px) {
  .breath-meditation {
    .welcome {
      &__container {
        gap: 48px;
      }

      &__player {
        flex-basis: 480px;
      }
    }

    .schedule {
      &__container {
        flex-direction: column-reverse;
        align-items: center;
        gap: 48px;
      }

      &__content {
        text-align: center;
      }

      &__steps {
        justify-content: center;
      }
    }

    .sidebar-schedule {
      flex-basis: auto;
    }

    .journey {
      &__benefits {
        gap: 48px;
      }

      &__divider {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .breath-meditation {
    .welcome {
      &__container {
        flex-direction: column;
      }

      &__content {
        text-align: center;
        align-items: center;
      }

      &__description {
        max-width: 600px;
      }

      &__player {
        flex-basis: auto;
      }
    }

    .journey {
      &__benefits {
        flex-direction: column;
        align-items: center;
      }

      &__benefit {
        max-width: 420px;
      }
    }
  }
}

@media (max-width: 768px) {
  .breath-meditation {
    .welcome {
      padding: 20px 0 48px;

      &__title {
        font-size: 36px;
        line-height: 48px;
      }

      &__description {
        margin-bottom: 20px;
      }
    }

    .player-welcome {
      &__content {
        left: 20px;
        bottom: 20px;
      }

      &__cover {
        min-height: 215px;
        object-fit: cover;
      }
    }

    .schedule {
      padding-bottom: 60px;

      &__sidebar {
        max-width: 372px;
        width: 100%;
        padding: 28px 32px;
      }

      &__steps {
        flex-direction: column;
        gap: 48px;
      }

      &__icon {
        margin-bottom: 32px;
      }

      &__btn-select {
        width: 100%;
        height: 52px;
        font-size: 16px;
        text-align: center;
        justify-content: center;
      }

      &__step {
        flex-basis: auto;
        max-width: 315px;
      }

      &__navigation {
        margin-top: 32px;
      }
    }

    .journey {
      padding: 60px 0 230px;

      &__title {
        font: 28px/38px 'Lora';
        font-weight: 700;
        margin-bottom: 32px;
      }
    }

    .download-schedule {
      padding: 40px 24px 32px;

      &__title {
        text-align: center;

        br {
          display: none;
        }
      }

      &__actions {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 560px) {
  .breath-meditation {
    .schedule__btn-select {
      display: none;
    }

    .welcome {
      &__navigation {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      &__btn-course {
        display: flex;
        margin-right: 20px;
      }

      &__btn-calendar {
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 390px) {
  .breath-meditation {
    .schedule__step {
      max-width: 100%;
    }

    .download-schedule {
      margin: 0 -20px;
      border-radius: 0;

      &__link {
        width: 100%;
      }
    }
  }
}

@media (max-width: 360px) {
  .breath-meditation {
    .welcome {
      &__navigation {
        flex-direction: column;
        gap: 20px;
      }

      &__btn-course {
        margin-right: unset;
      }
    }
  }
}

.program-quote {
  position: relative;
  padding: 100px 0;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      object-fit: cover;
    }
  }

  &__image,
  &__image img {
    width: 100%;
    height: 100%;
  }

  &__text,
  &__author {
    color: white;
    font-weight: bold;
    text-align: center;
  }

  &__text {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 48px;
  }

  &__author {
    font-size: 24px;
    line-height: 32px;
  }
}

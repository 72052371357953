.order {
  padding: 60px 0 30px 0;

  .title {
    font-size: 48px;
    font-weight: bold;
    font-family: Lora, serif;
    text-align: center;
    color: #31364e;
    margin-bottom: 20px;

    &_thin {
      font-weight: 400 !important;
    }
  }

  &__detail {
    font-size: 20px;
    text-align: center;
    color: #31364e;
    margin-bottom: 60px;
  }

  &__form {
    .details {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 20px;
      white-space: nowrap;

      &__title {
        font-family:
          Work Sans,
          sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 0;
      }

      &__content {
        font-size: 20px;
        margin-bottom: 0;

        img {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 40px 50px 40px;
    border-radius: 16px;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
    border: solid 1px #89beec;
    background-color: #ffffff;

    &__payment-select {
      display: inline-flex;
      white-space: nowrap;
      color: #31364e;
      width: 100%;
      justify-content: space-evenly;
    }

    #address,
    #city {
      width: 100%;
    }

    .bank-card-info {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      #mm-yy,
      #cvc {
        width: 99px;
      }
    }

    .paypal-info {
      margin-top: 15px;

      &__tooltip {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        img {
          width: 24px;
          height: 24px;
        }

        span {
          margin-left: 8px;
          color: #9598a6;
          font-size: 14px;
          line-height: 1.29;
        }
      }

      &__button {
        border: none;
        outline: none;
        border-radius: 16px;
        background-color: #31b0f0;
        width: 133px;
        height: 50px;
        transition: all 0.15s ease-in-out;

        img {
          width: 93px;
        }

        &:hover {
          background-color: $blue;
        }
      }

      &__link {
        padding: 0;
        display: inline;
        background: none;
        border: none;
        font-size: 14px;
        line-height: 1.57;
        color: $blue;
        text-decoration: underline;
        text-decoration-style: dotted;

        &:hover {
          color: $blue;
        }
      }
    }

    .prompt-message {
      margin-top: 8px;
      font-size: 12px;
      line-height: 1.33;
      color: #c4c5cc;
    }

    .input-block {
      position: relative;

      &.validate-error {
        input {
          border-color: #fa4141;
        }

        .validation-message {
          display: block;
        }
      }
    }

    &__payment {
      width: 100%;
      margin: 30px 0 6px;

      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #31364e;
      }

      &-option {
        margin: 7px 0 8px;

        label {
          span.discount {
            color: #fa5a67;
          }
        }

        &_special-offer {
          span.special-offer {
            display: block;
            width: min-content;
            white-space: nowrap;
            background-color: #e8f2fc;
            padding: 5px 10px;
            font-size: 10px;
            font-weight: 600;
            line-height: 1.4;
            color: $blue;
            text-transform: uppercase;
            margin: 12px 0 5px;
            border-radius: 4px;
          }
        }
      }

      &-tooltip {
        padding-left: 30px;
        color: #9598a6;
        font-size: 14px;

        &_small {
          padding: 0;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }

    .validation-mobile-message {
      display: none;
      margin-top: 8px;
      font-size: 12px;
      line-height: 1.33;
      color: #fa4141;
    }

    input:not([type='radio']):not([type='checkbox']),
    .select-box {
      margin-top: 1.5rem;
    }

    input:not([type='radio']):not([type='checkbox']) {
      outline: none;
      border: none;
      width: 250px;
      border-bottom: 1px solid #e9e9e9;
      background-color: transparent;
      font-size: 16px;
      line-height: 2;
      color: #31364e;
      transition: all 0.3s ease-in-out;

      &::placeholder {
        color: #9598a6;
        transition: all 0.3s ease-in-out;
      }

      &.no-valid {
        border-color: #fa4141;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      &:hover {
        border-color: #89beec;
      }

      &:focus {
        border-color: #2465b3;

        &::placeholder {
          color: #c4c5cc;
        }
      }
    }
    .select-box {
      outline: none;
      border: none;
      width: 250px;
      background-color: transparent;
      font-size: 16px;
      line-height: 2;
      color: #31364e;
      transition: all 0.3s ease-in-out;

      &::placeholder {
        color: #9598a6;
        transition: all 0.3s ease-in-out;
      }

      &.no-valid {
        border-color: #fa4141;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      &:hover {
        border-color: #89beec;
      }

      &:focus {
        border-color: #2465b3;

        &::placeholder {
          color: #c4c5cc;
        }
      }
    }

    &__total {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 15px 0 10px;
      padding: 14px 15px;
      background-color: transparentize(#e8f2fc, 0.5);
      border-radius: 8px;
      color: #31364e;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      text-transform: uppercase;
    }
  }

  &__complete {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  &__security {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .security__info {
    display: inline-flex;
    flex-direction: column;
    font-size: 9px;
    line-height: 1.33;
    color: #303650;
    margin-left: 5px;

    span {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .reciept {
    border-radius: 16px;
    border: solid 1px #e8f2fc;

    &--box {
      box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
      border: solid 1px #89beec;
    }

    &__title {
      &_v1 {
        text-align: left;
        font-size: 22px;
        font-family:
          Work Sans,
          sans-serif;
        margin-bottom: 10px;
        line-height: 1;
      }
    }

    &__item {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 15px 20px;
      margin: 0;
      font-family:
        Work Sans,
        sans-serif;
      font-size: 16px;
      line-height: 1;
      color: #31364e;

      span {
        display: inline-flex;
        align-items: center;
      }

      img {
        margin-right: 10px;
      }

      &_main {
        padding: 13px 20px;
        background-color: rgba(232, 242, 252, 0.5);
        font-weight: 600;
      }

      &_list {
        padding: 20px 30px;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          line-height: 1;
          color: #31364e;
          font-weight: 600;

          .discount {
            font-weight: 400;
          }

          &:not(:first-child) {
            margin-top: 15px;
          }

          &.link {
            margin-top: 5px;

            a {
              font-size: 12px;
              font-weight: 400;
            }
          }

          &.btn-item {
            width: 100%;

            button {
              width: 100%;
            }
          }
        }
      }
    }

    &__header {
      border-bottom: 1px solid #e8f2fc;

      &_v1 {
        @media screen and (min-width: 991px) {
          padding: 20px;
        }
        .price {
          font-size: 20px;
          line-height: 1;
        }
      }
    }

    &__price-list {
      &-item {
        display: flex;
        padding: 10px 10px 10px 30px;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 1;
        color: #31364e;

        &.early {
          font-weight: 600;
          background-color: rgba(137, 190, 236, 0.1);
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &__details {
      padding: 20px;

      &_v2 {
        padding: 20px 30px 20px 45px;
      }

      &-title {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: $text-color;
      }

      &-list {
        list-style: disc;
      }

      &-item {
        color: $orange;

        span {
          color: $light-grey;
        }

        strong {
          color: $text-color;
        }

        &:not(last-child) {
          margin-bottom: 7px;
        }
      }

      .course {
        display: flex;
        flex-direction: row;

        &__photo {
          width: 97px;
          height: 97px;
          background-color: #e8f2fc;
          border-radius: 16px;
          margin-right: 35px;

          &--min-width {
            margin-right: 25px;
            margin-top: 25px;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
        }

        &__info {
          &--width {
            width: max-content;
          }

          .info__title {
            font-family:
              Work Sans,
              sans-serif;
            font-weight: 600;
            color: #9598a6;
            font-size: 16px;
            line-height: 1.25;
            margin-bottom: 3px;
          }
        }

        .info__list {
          color: #9598a6;
          font-size: 16px;
          line-height: 1.25;

          &--classic {
            list-style: disc;
            padding-left: 25px;
          }

          .info__title {
            font-family:
              Work Sans,
              sans-serif;
            font-weight: 600;
            color: #9598a6;
            font-size: 16px;
            line-height: 1.25;
            margin-bottom: 3px;
          }
        }
      }
    }

    &__more {
      border-top: solid 1px #e8f2fc;
      padding: 20px 25px;
      font-size: 12px;
      line-height: 1.33;
      color: #9598a6;
    }

    &__payment {
      width: 100%;
      padding: 15px 20px;
      border-top: 1px solid #e8f2fc;

      &__title {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        color: #9598a6;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      &-option {
        position: relative;
        margin: 7px 0 8px;

        label {
          font-size: 16px;
          width: 100%;
          display: flex;
          justify-content: space-between;

          span.discount {
            color: #fa5a67;
          }
        }

        &_special-offer {
          span.special-offer {
            display: block;
            width: min-content;
            white-space: nowrap;
            background-color: #e8f2fc;
            padding: 5px 10px 5px 34px;
            font-size: 10px;
            font-weight: 600;
            line-height: 1.4;
            color: $blue;
            text-transform: uppercase;
            margin: 12px 0 10px -20px;
            border-radius: 0 4px 4px 0;
          }
        }
      }

      &-tooltip {
        padding-left: 30px;
        color: #9598a6;
        font-size: 14px;

        &_small {
          padding: 0;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }

    &__total {
      display: flex;
      justify-content: space-between;
      padding: 14px 15px;
      background-color: transparentize(#e8f2fc, 0.5);
      border-radius: 8px;
      color: #31364e;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.38;
      text-transform: uppercase;

      @media screen and (min-width: 991px) {
        margin: 10px 15px;
      }
    }

    &__agreement {
      padding: 20px 30px 30px;

      .agreement:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .reciept__payment_list {
    .custom-checkbox + label:before {
      border-color: #ee8585;
    }
  }

  .agreement,
  .health-confirmation {
    &__group {
      display: flex;
    }

    &__group_important {
      .custom-checkbox + label:before {
        border-color: #ee8585;
      }

      .custom-checkbox:checked + label:before {
        border-color: #89beec;
        background-color: white;
      }

      .custom-checkbox:checked + label:after {
        content: url('/img/ic-tick-blue.svg');
      }
    }

    &__text {
      margin-left: 10px;
      margin-top: 4px;
      font-size: 12px;
      line-height: 1.33;
      color: #31364e;

      span {
        display: block;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

    &__important {
      display: flex;
      align-items: center;
      opacity: 1;
      pointer-events: all;
      margin-top: 15px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ee8585;
      transition: all 0.3s ease-in-out;

      &.hide {
        opacity: 0;
        pointer-events: none;
      }

      &-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .quote-section {
    padding: 60px 0;
    background-position-x: 25%;

    &__name {
      text-align: center;
      margin-left: 0;
    }

    &__quote {
      margin-top: 10px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      line-height: 1;

      span {
        line-height: 1.36;
        font-size: 20px;
        margin: 0;
      }
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  // height: 100%;
  font-family:
    Work Sans,
    sans-serif;
  color: #31364e;
  // scroll-behavior: smooth;
  // overflow: auto;
}
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  //overflow-y: scroll;
  overflow: unset;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
img {
  display: inline;
}

main {
  min-height: calc(100vh - 135px);
}
.fa-solid,
.fas {
  font-weight: 900;
}
.fa,
.fa-brands,
.fa-classic,
.fa-regular,
.fa-sharp,
.fa-solid,
.fab,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}
.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 9999;
}
.tandc {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, '.') ' ';
  }
}
.validation-input {
  text-align: left;
  font-size: 12px;
  line-height: 1.33;
  color: #fa5a67;
  margin-bottom: 15px;
}
.react-select-container-old {
  margin-top: 1.1rem;
  .react-select__control {
    outline: none;
    border: none;
    width: 250px;
    border-bottom: 1px solid #e9e9e9;
    background-color: transparent;
    font-size: 16px;
    line-height: 2;
    color: #31364e;
    transition: all 0.3s ease-in-out;
  }
  @media screen and (max-width: 991px) {
    .react-select__control {
      width: 100%;
      border-radius: 4px;
      border: solid 1px #e9e9e9;
      padding: 15px;
    }
  }
}
.react-select-container {
  .react-select__control {
    height: 44px;
    border: 1px solid #e9e9e9 !important;
    border-radius: 16px !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
    font-weight: 300 !important;
    font-size: 16px !important;
    width: 100%;
  }
  .react-select__input {
    padding: 10px 14px;
  }
  .react-select {
    &__menu {
      margin: 0.125rem auto;
    }

    &__option {
      background-color: white;

      &--is-focused {
        background-color: #fff6ec;
      }

      &--is-selected {
        background-color: #ffa180;
      }

      &--is-active {
        background-color: #ffa180;
      }

      &:active {
        background-color: #ffa180;
        color: #ffffff;
      }
    }
  }
}
.search-input-wrap {
  position: relative;
  button.search-clear {
    border: none;
    background: none;
    position: absolute;
    top: 8px;
    right: 4px;
    &:focus {
      outline: none;
    }
  }
  > div {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9;
    margin-top: -13px;
    padding-top: 2px;
  }
}
.smart-input--list-item {
  background: white;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.input-search-wrap {
  position: relative;
  .search-input {
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    height: 44px;
    width: 100%;
    font-size: 16px;
    padding-left: 46px;
    padding-right: 10px;
    &::placeholder {
      color: #9598a6;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #9598a6;
    }
    // background: url('/img/map-search-input-icon.svg') no-repeat 10px center;
  }
  .search-listing {
    max-height: calc(100% - 64px);
    margin-top: 20px;
    overflow: auto;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .search-list-item {
    padding: 20px;
    background: #f7f7f7;
    border-radius: 20px;
    font-size: 14px;
    line-height: 18px;
    flex: 48%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1179px) {
      flex: 100%;
    }
    .title {
      font-family: Lora;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 16px;
      // display: flex;
      align-items: center;
      img.icon {
        margin-right: 8px;
      }
    }
    img.icon {
      margin-right: 8px;
    }
    .info {
      margin-bottom: 14px;
      display: flex;
      align-items: flex-start;
      &.email {
        word-break: break-word;
        margin-bottom: 34px;
      }
    }
    .action-btn {
      margin-top: auto;
      .submit-btn {
        background: linear-gradient(
          102.78deg,
          #ffd16f -37.89%,
          #ff865b 100.28%
        );
        border-radius: 100px;
        font-weight: 500;
        font-size: 16px;
        border: none;
        width: 100%;
        height: 40px;
        color: white;
      }
    }
  }

  .schedule-location-input {
    align-items: center;
    display: block;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.051);
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    line-height: 24px;
    padding: 10px 35px 10px 50px;
    width: 100%;

    .schedule-location-value {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &:after {
    color: #9598a6;
    content: '';
    font-family: iconaol;
    font-size: 24px;
    left: 14px;
    line-height: 24px;
    position: absolute;
    top: 10px;
  }
}
.express-checkout-element-only {
  .StripeElement {
    width: 100%;
  }
}

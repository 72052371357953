@media only screen and (max-width: 1199px) {
  .about-program {
    &__image {
      img {
        object-position: left;
      }
    }

    &__main {
      padding: 90px 0;

      &-name {
        font-size: 40px;
        line-height: 46px;
      }
    }

    &.volunteer-program {
      .about-program__main {
        padding: 90px 0 100px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .about-program {
    margin-bottom: 106px;

    &__main {
      padding: 60px 0 40px;

      &-type,
      &-name {
        text-align: center;
      }

      &-type {
        font-size: 16px;
      }

      &-name {
        font-size: 32px;
        line-height: 38px;
      }

      &-list {
        li {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    &__details {
      padding: 28px;
      margin-bottom: -106px;

      &_small {
        padding: 30px 20px;
      }

      &-title {
        font-family: Lora, sans-serif;
        font-size: 24px;
        line-height: 32px;

        &_small {
          font-family:
            Work Sans,
            sans-serif;
          font-size: 20px;
          line-height: 28px;
        }
      }

      .details-table {
        font-size: 14px;
        overflow: hidden;
      }
    }

    &.volunteer-program {
      .about-program__main {
        padding: 60px 0 65px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .about-program {
    &__details {
      .details-table {
        &__column {
          &:first-child {
            width: 40%;
          }

          &:last-child {
            width: 60%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .about-program {
    &__main {
      &-button {
        width: 100%;
      }
    }
  }
}

.advocate-reward {
  padding: 27px;

  border: 1px solid #3d8be8;
  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 20px -5px rgba(61, 139, 232, 0.33);
  -moz-box-shadow: 0px 0px 20px -5px rgba(61, 139, 232, 0.33);
  box-shadow: 0px 0px 20px -5px rgba(61, 139, 232, 0.33);
}

.advocate-reward__title {
  font-size: 14px;
  line-height: 18px;
}

.advocate-reward__text {
  text-align: start;
  font-size: 14px;
  line-height: 18px;
}

.advocate-reward__list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.advocate-reward__button {
  padding: 8px 16px;

  font-family: inherit;
  font-size: 14px;
  font-weight: 600;

  color: white;
  background-color: #3d8be8;

  border: transparent;
  border-radius: 10px;

  cursor: pointer;

  &:hover {
    background-color: #196ed4;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
    background-color: #1264c9;
  }

  &--disabled {
    cursor: not-allowed;
    background-color: #c0c0c0;

    &:hover,
    &:active {
      background-color: #c0c0c0;
    }
  }
}

.advocate-reward__checkbox {
  width: 20px;
  height: 20px;

  accent-color: #3d8be8;
}

.advocate-reward__label {
  padding: 5px 20px;
  width: 145px;

  font-family: inherit;
  font-size: 12px;
  font-weight: 600;

  color: white;
  background-color: #e47d6c;

  border-radius: 5px;
}

.advocate-reward__select {
  width: 100%;
}

.advocate-reward--page .item-volonteer .select2-selection {
  border-color: #3d8be8;
}

.advocate-reward--page .select2-results__option {
  text-align: center;
}

.advocate-reward--page
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  justify-content: center;
  margin: 0;
}

.advocate-reward--page
  .select2-container--default.select2-container--focus
  .select2-selection--single {
  border-color: #3d8be8;
  outline: none;
}

.advocate-reward__total {
  font-size: 14px;
  font-weight: 700;
}

.demo {
  width: 382px;
}

@media (max-width: 575px) {
  .advocate-reward {
    width: 100%;
    max-width: 310px;
  }

  .advocate-reward__list {
    flex-direction: column;
    justify-content: center;
  }

  .advocate-reward__button {
    width: 100%;
    margin: 0 auto;
  }

  .advocate-reward__total {
    text-align: center;
  }

  .demo {
    width: 310px;
  }
}

@media (min-width: 576px) {
  .advocate-reward {
    width: 440px;
  }

  .advocate-reward__list {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .advocate-reward__button {
    width: auto;
    margin: 0;
  }

  .advocate-reward__total {
    text-align: start;
  }
}

// responsive mobile
@media (max-width: 320px) {
  .advocate-reward {
    width: 98%;
    max-width: 310px;
  }

  .advocate-reward--page
    .select2-container--default
    .select2-search--inline
    .select2-search__field {
    font-size: 12px;
  }

  .demo {
    width: 98%;
    max-width: 310px;
  }
}

@media (max-width: 270px) {
  .advocate-reward--page
    .select2-container--default
    .select2-search--inline
    .select2-search__field {
    font-size: 10px;
  }
}

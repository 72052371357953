/*
 * Colors
 */
$wcf-dark: #000000;
$wcf-dark-grey: #2b2b2b;
$wcf-grey: #f0f0f0;
$wcf-light-grey: #d7d7d7;
$wcf-light: #ffffff;

$wcf-orange: #ef4930;
$wcf-orange-light: #ef493091;
$wcf-yellow: #e7c22c;
$wcf-dark-blue: #26294d;

/*
 * Fonts
 */
$wcf-font-regular: 'PoppinsRegular', sans-serif;
$wcf-font-medium: 'PoppinsMedium', sans-serif;

/*
  * Shadows
  */
$wcf-shadow: 0px 1px 2px transparentize($wcf-dark, 0.92);
$wcf-shadow-hover: 0px 1px 2px transparentize($wcf-dark, 0.85);
$wcf-shadow-active: 0px 1px 2px transparentize($wcf-dark, 0.7);

/*
  * Animations
  */
$wcf-transition-effect: 0.3s ease-in-out;

@media only screen and (max-width: 1199px) {
  .full-testimonial {
    &__body {
      width: 767px;

      &__media {
        width: 275px;
        height: 175px;
      }

      &__content {
        &__tags {
          left: 40px;
          width: 255px;
          bottom: calc(100% - 195px);
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .full-testimonial {
    &__body {
      flex-direction: column;
      border: none;
      padding: 0;

      &__media {
        width: 100%;
        height: 220px;
        border-radius: 0;
      }

      &__content {
        overflow-y: scroll;
        padding: 20px 20px 100px;

        &__text,
        &__quote {
          line-height: 1.57;
        }

        &__quote {
          font-size: 16px;
        }

        &__text {
          margin-bottom: 20px;
          font-size: 14px;
        }

        &__person {
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 1.29;
        }

        &__tags {
          position: static;
          width: 100% !important;

          span {
            background-color: rgba(49, 54, 78, 0.1);
          }
        }
      }
    }
  }
}

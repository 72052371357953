.video-react .video-react-big-play-button {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: rgb(130, 173, 224);
  background: linear-gradient(
    352deg,
    rgba(130, 173, 224, 1) 0%,
    rgba(61, 139, 232, 1) 100%
  );
  border: 1px solid #3571b9;
}
.video-player {
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}
.insight-collection2.top-column.meditation-page.insight-collection
  .collection-video
  .video-insighter-container
  .video-react {
  height: 275px;
  margin: 0 auto;
  width: 100%;
}
.video-react .video-react-poster {
  background-size: cover !important;
}
.collection-video {
  width: 100%;
}
video {
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  .modal-window {
    top: unset;

    &.active {
      height: 100vh;
    }
  }

  .full-width {
    width: 100% !important;
  }

  // .auth.active {
  //   display: none !important;
  // }
  .auth {
    display: none;
    height: 0;
    background-color: white;
    top: unset;
    transition: all 0.3s ease-in-out;
    align-items: flex-start;
    overflow-y: auto;

    &.active {
      height: 100vh;
    }

    &.show {
      display: flex;
    }

    .icon-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .icon {
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        background-color: #ffffff;
        transition: all 0.3s ease-in-out;

        &:hover {
          border-color: #89beec;
        }

        &:active {
          border-color: #4191e9;
        }

        img {
          width: 50px;
          height: 50px;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .modal-window__close {
      display: none;
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      bottom: unset;
      box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);
      margin-left: 20px;
      margin-top: 40px;

      &_mobile {
        display: flex;
      }
    }

    .modal-window__card {
      box-shadow: none;
      max-width: 100%;
    }

    .modal-window__header {
      padding: 0;

      .modal-window__subtitle {
        margin: 40px 20px;
        color: $text-color;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
      }

      .auth-btn-wrapper {
        display: flex;
      }

      .auth-btn {
        padding: 0 0 11px 0;
        width: 50%;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #c4c5cc;
        background-color: white;
        border: none;
        border-bottom: 2px solid #e9e9e9;
        transition: all 0.3s ease-in-out;
        outline: none;

        &.active {
          color: #3d8be8;
          border-color: #3d8be8;
        }
      }
    }

    .modal-window__body {
      border: none;
    }

    form {
      margin-top: 20px;
      display: none;
      opacity: 0;
      flex-direction: column;
      transition: all 0.3s ease-in-out;

      &.active {
        display: flex;
      }

      &.show {
        opacity: 1;
      }

      input {
        padding: 12px 15px;
        border-radius: 4px;
        border: solid 1px #e9e9e9;
        margin-bottom: 15px;
        font-size: 16px;
        color: #9598a6;
        transition: all 0.3s ease-in-out;

        &.validate {
          border-color: #fa5a67;
        }

        &:hover {
          border-color: #89beec;
        }

        &:focus {
          border-color: #2465b3;
        }
      }

      .validation-input {
        text-align: left;
        font-size: 12px;
        line-height: 1.33;
        color: #fa5a67;
        margin-bottom: 15px;
      }

      .link {
        text-align: left;
      }

      .checkbox-wrapper {
        display: flex;
        margin-top: 17px;
      }

      .checkbox-text {
        font-size: 12px;
        text-align: left;
        margin-left: 10px;
      }
    }
  }

  .course-popup {
    .course-card {
      &__info {
        &-wrapper {
          width: 100%;
        }

        .price {
          font-size: 16px;
          padding: 4px 5px;
          border-radius: 2px;
          background-color: rgba(137, 190, 236, 0.15);

          &:not(:last-child) {
            margin-right: 10px;
          }
        }

        .course-card__course-name {
          font-size: 20px;
        }

        .course-card__date {
          font-size: 16px;
        }

        .link {
          font-size: 16px;
        }
      }
    }
  }

  .select-box {
    &__placeholder {
      border-radius: 4px;
      padding: 15px;
    }

    &__current {
      height: 64px;
      width: 100%;
      border-radius: 4px;
      border: solid 1px #e9e9e9;

      &:after {
        right: 15px;
      }
    }

    &__input-text {
      border-radius: 4px;
      padding: 15px;
      padding-right: 30px;
    }
  }

  aside {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 25;

    ~ header {
      top: 40px !important;
    }

    ~ main {
      margin-top: 91px !important;
    }
  }
}

@media screen and (min-width: 991px) and (max-height: 767px) {
  .auth {
    .modal-window__header {
      padding: 0;

      .modal-window__subtitle {
        margin: 15px 20px;
      }

      .auth-btn {
        padding: 0 0 11px 0;
        font-size: 16px;
      }
    }

    .modal-window__body {
      padding: 15px 20px;
    }

    .icon-wrapper {
      margin-bottom: 10px;

      .icon {
        img {
          width: 35px;
          height: 35px;
        }
      }
    }

    form {
      margin-top: 15px;

      input {
        padding: 10px 13px;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .validation-input {
        margin-bottom: 10px;
      }

      .checkbox-wrapper {
        margin-top: 0;
      }

      .checkbox-text {
        font-size: 12px;
        margin-left: 10px;
      }

      .btn-primary {
        font-size: 14px;
        padding: 13px 16px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  aside {
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    line-height: 1.43;

    img {
      display: none;
    }
  }

  .section-title {
    font-size: 24px;
  }

  .course-bottom-card {
    padding: 15px 0;
    z-index: 9;

    .btn-secondary {
      font-size: 14px;
    }

    &__info {
      @media screen and (max-width: 450px) {
        font-size: 16px !important;
      }

      p {
        font-size: 12px;
      }

      div {
        flex-direction: column;
        align-items: flex-start;

        h3 {
          font-size: 16px;
        }

        ul {
          list-style: none;
          padding-left: 0;

          li {
            font-size: 12px;
          }
        }
      }
    }
  }

  .btn-secondary {
    font-size: 14px;
    line-height: normal;
    height: 44px;
    padding: 13px 20px;
  }

  .btn-outline {
    font-size: 14px;
    line-height: normal;
    height: 44px;
    padding: 13px 20px;
  }
}

@media screen and (max-width: 575px) {
  .course-popup {
    .course-card {
      &__info {
        .price {
          font-size: 14px;
        }

        .course-card__course-name {
          font-size: 18px;
        }

        .course-card__date {
          font-size: 14px;
        }

        .link {
          font-size: 14px;
        }
      }
    }
  }

  .btn-primary {
    font-size: 14px;
    padding: 13px 20px;
  }
}

@media screen and (max-width: 425px) {
  .mobile-modal {
    &__header {
      button {
        width: 100%;
      }
    }
  }

  .course-bottom-card {
    &__info-course-name {
      font-size: 16px !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .course-bottom-card {
    .container {
      padding: 0 10px;
    }

    &__info {
      // border: 1px solid black;
      div {
        h3 {
          font-size: 14px;
        }

        h6 {
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 195px;
        }
      }
    }
  }

  .course-popup {
    .course-card {
      &__info {
        .price {
          font-size: 12px;
        }

        .course-card__course-name {
          font-size: 16px;
        }

        .course-card__date {
          font-size: 12px;
        }

        .link {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .course-popup {
    .course-card {
      padding: 15px 10px;

      &__info {
        .price {
          font-size: 12px;
          padding: 3px;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 345px) {
  .course-bottom-card__info-course-name {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 340px) {
  .mobile-modal {
    &__header {
      .new-price {
        font-size: 16px;
      }
    }
  }
}

.program-footer {
  background: rgba(50, 25, 60, 0.02);
  margin-bottom: 90px;

  &__top {
    padding: 32px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
      height: 56px;
    }

    &-search {
      width: 272px;
      position: relative;

      &__icon {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        opacity: 0.4;

        ~ .program-footer__top-search__field {
          padding-left: 32px;
        }
      }

      &__field {
        padding: 7px 11px;
        width: 100%;
        border: 1px solid rgba(50, 25, 60, 0.02);
        background: rgba(50, 25, 60, 0.02);
        border-radius: 4px;
        transition: border-color 0.25s ease-in-out;
        color: #31364e;
        font-size: 13px;
        line-height: 16px;

        &::placeholder {
          opacity: 0.4;
        }

        &:focus {
          border-color: #31364e;
        }
      }
    }
  }

  &__nav {
    padding: 20px 0;

    a,
    a:hover {
      text-decoration: none;
    }

    &-email {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #1e2232;
      margin-bottom: 20px;
      width: min-content;

      &:hover {
        color: #606270;
      }
    }

    &-contacts {
      &__item {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }

        &:hover {
          opacity: 0.6;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    &-menu {
      display: flex;
      justify-content: space-between;

      &__column {
        &-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #1e2232;
          margin-bottom: 20px;
        }

        &-link {
          display: block;
          font-size: 12px;
          line-height: 14px;
          color: #1e2232;

          &:hover {
            color: #606270;
          }

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 43px;

    &-copyright,
    &-links {
      font-size: 12px;
      line-height: 14px;
      color: #606270;
    }

    &-links {
      &__item,
      &__item:hover {
        text-decoration: none;
      }

      &__item {
        display: inline-block;
        text-decoration: none;
        color: #606270;

        &:hover {
          color: #1e2232;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

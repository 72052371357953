.questionnaire-results {
  padding-bottom: 100px;
  &.new {
    padding-top: 60px;
    @media screen and (max-width: 767px) {
      padding-top: 24px;
    }
  }
  .container {
    @media screen and (min-width: 1400px) {
      max-width: 1304px;
    }
  }
  .question-box {
    max-width: 100%;
  }
  .back-btn {
    width: 150px;
  }
  h1.section-title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    margin-bottom: 0;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  .result-boxes {
    margin-top: 48px;
    display: flex;
    gap: 40px;
    @media screen and (max-width: 991px) {
      margin-top: 20px;
      flex-direction: column;
      gap: 20px;
    }
  }
  .result-box {
    background: linear-gradient(
      180deg,
      #d7e9f9 0%,
      #e9f3fb 55%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 24px;
    border-radius: 40px;
    &.recommendation {
      background: linear-gradient(
        180deg,
        #ffefcc 0%,
        rgba(255, 217, 204, 0.37) 55%,
        rgba(255, 217, 204, 0) 100%
      );
      .first-info {
        color: #ed994e;
      }
    }
    @media screen and (max-width: 991px) {
      padding: 20px 16px;
    }
    .first-info {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #3d8be8;
      margin-bottom: 12px;
      @media screen and (max-width: 991px) {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
    .box-title {
      font-family: Lora;
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      text-align: center;
      @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 29px;
      }
    }
    .picture-box {
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      margin-top: 56px;
      @media screen and (max-width: 991px) {
        margin-top: 24px;
      }
      .picture-title {
        background: linear-gradient(
          180deg,
          rgba(61, 139, 232, 0) 0%,
          rgba(61, 139, 232, 0.74) 59.89%,
          #3d8be8 100%
        );
        height: 115px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        font-family: Lora;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        color: white;
        text-align: center;
        padding: 60px 20px 20px 20px;
        @media screen and (max-width: 991px) {
          height: 128px;
          font-size: 18px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 10px;
        }
      }
      img {
        width: 100%;
        @media screen and (min-width: 992px) {
          height: 369px;
          object-fit: cover;
        }
      }
    }
    .box-desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin-top: 16px;
      color: #6f7283;
      margin-bottom: 24px;
      @media screen and (max-width: 991px) {
        margin: 12px 0 32px;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .recommended-course-info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 48px;
      @media screen and (max-width: 991px) {
        margin-bottom: 32px;
      }
      li {
        display: flex;
        gap: 20px;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 991px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .label {
        width: 124px;
        font-weight: 600;
        flex-shrink: 0;
        @media screen and (max-width: 991px) {
          width: 104px;
        }
      }
    }
    .course-payment-options {
      display: flex;
      gap: 16px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
      a.payment-option {
        padding: 12px;
        display: flex;
        background: white;
        border-radius: 16px;
        flex-direction: column;
        flex: 1;
        text-align: center;
        color: #31364e;
        text-decoration: none;
        border: #ffffff 2px solid;
        &.selected {
          border: #ff865b 2px solid;
        }
        @media screen and (max-width: 991px) {
          height: 112px;
          flex: auto;
        }
      }
      .first-info {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #31364e;
        margin-bottom: 6px;
        img {
          margin: -12px 0 0 4px;
        }
      }
      .price {
        font-family: Lora;
        font-size: 28px;
        font-weight: 500;
        line-height: 38px;
        sub {
          font-size: 16px;
          top: 0px;
          position: relative;
        }
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #6f7283;
      }
    }
    .course-actions {
      display: flex;
      gap: 20px;
      margin-top: 40px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        margin-top: 32px;
        gap: 12px;
      }
      .btn-wrap {
        display: flex;
        flex-direction: column;
        flex: 1;
        .desc {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          display: block;
          color: #6f7283;
          margin-top: 4px;
        }
      }
      .submit-btn {
        background: linear-gradient(
          102.78deg,
          #ffd16f -37.89%,
          #ff865b 100.28%
        );
        height: 64px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: white;
        border-radius: 16px;
        border: none;
        &.learn {
          background: linear-gradient(
            102.78deg,
            #3d8be8 -37.89%,
            #89beec 100.28%
          );
        }
        @media screen and (max-width: 991px) {
          height: 48px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  &.new {
    .question-box {
      padding: 48px;
      @media screen and (max-width: 767px) {
        padding: 32px 16px;
      }
    }
    h1.section-title {
      font-size: 40px;
      line-height: 44px;
      max-width: 618px;
      margin: 0 auto;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    .result-boxes {
      flex-direction: column;
      @media screen and (max-width: 767px) {
        margin-top: 28px;
        .first-info {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .result-box {
      background: none;
      padding: 0;
      .box-title {
        font-size: 36px;
        line-height: 44px;
        max-width: 618px;
        margin: 0 auto;
        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 31px;
        }
      }
      &.recommendation {
        border-top: 1px solid #e9e9e9;
        padding-top: 48px;
        border-radius: 0;
      }
      .box-desc {
        font-size: 16px;
        line-height: 24px;
        max-width: 530px;
        margin: 16px auto 0;
        @media screen and (max-width: 991px) {
          margin: 12px 0 0px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .courses-nearby {
      .container {
        padding-top: 32px;
        padding-bottom: 0;
      }
      .section-title {
        font-size: 28px;
        line-height: 36px !important;
        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 28px !important;
          margin-bottom: 20px;
          font-weight: 600;
        }
      }
      .courses-slider {
        .slick-list {
          padding-top: 12px;
          padding-bottom: 0;
          @media screen and (max-width: 767px) {
            padding-bottom: 0 !important;
          }
        }
      }
      .course-types .form-item {
        margin-bottom: 0;
      }
      .courses-nearby-actions {
        button {
          border: #3d8be8 1.5px solid;
          background: white;
          color: #3d8be8;
          @media screen and (max-width: 767px) {
            height: 48px;
            width: 100%;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
      .courses-slider .slide {
        .course-item-top {
          padding-bottom: 14px;
        }
        .course-item-date {
          flex: unset;
          padding: 8px 14px;
          font-size: 22px;
          line-height: 28px;
        }
        .course-instructors,
        .course-location,
        .course-date {
          border-bottom: #e9e9e9 1px solid;
          align-items: center;
          &::before {
            font-size: 16px;
            height: 16px;
            width: 16px;
            background: none;
            margin-right: 8px;
          }
        }
        .course-date {
          align-items: flex-start;
          border-bottom: none;
          &::before {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .testimonials {
    margin-top: 60px;
    padding-top: 60px;
    border-top: 1px solid #e9e9e9;
    padding-bottom: 0;
    @media screen and (max-width: 991px) {
      margin-top: 20px;
      padding-top: 20px;
    }
    .section-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      text-align: center;
      margin-bottom: 48px;
      @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 0;
      }
    }
  }
  .testimonials-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 60px;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: 24px;
    }
    .testimonial-box {
      width: calc(50% - 60px);
      display: flex;
      flex-direction: column;
      border-top: #b2b6c9 1px solid;
      padding-top: 40px;
      @media screen and (min-width: 992px) {
        &:nth-child(1),
        &:nth-child(2) {
          padding-top: 24px;
          border-top: none;
        }
      }
      @media screen and (max-width: 991px) {
        width: 100%;
        padding-top: 24px;
        border-color: #e9e9e9;
        &:nth-child(1) {
          border-top: none;
        }
      }
    }
    .testimonial-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 24px;
      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .testimonial-info {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      align-items: center;
      .author {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 23px;
        img {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
  .trustpilot-wrap {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #555555;
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}
.intro-series-slider {
  padding-top: 40px;
  @media screen and (max-width: 767px) {
    padding-top: 24px;
  }
  .slick-track {
    display: flex;
  }
  .slick-list {
    margin: 0 28px;
    @media only screen and (max-width: 991px) {
      padding: 0px !important;
      margin: 0;
    }
  }
  .slide {
    margin: 12px;
    height: inherit;
    @media only screen and (max-width: 991px) {
      margin: 10px;
    }
  }
  .intro-item-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    .day {
      color: #3d8be8;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .intro-video-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      padding-top: 4px;
      margin-bottom: 12px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .intro-video-player {
      height: 220px;
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      margin-top: auto;
    }
    .video-thumb {
      img {
        width: 100%;
        height: 220px;
        object-fit: cover;
      }
      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
    .video-locked {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #1314184d;
      backdrop-filter: blur(36.400001525878906px);
      padding: 10px;
      gap: 16px;
      .icon-aol {
        height: 48px;
        width: 48px;
        font-size: 24px;
        background-color: white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .text {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        max-width: 246px;
        margin: 0 auto;
        color: white;
        a {
          color: #89beec;
        }
      }
    }
  }
  .slick-arrow {
    box-shadow:
      0px 2px 4px -2px #0000000f,
      0px 4px 8px -2px #0000001a;
    height: 48px;
    width: 48px;
    border: 1px solid #e9e9e9;
    background-color: #ffffff;
    border-radius: 48px;
    z-index: 1;
    &::before {
      content: '\e937';
      font-family: 'iconaol' !important;
      color: #31364e;
      opacity: 1;
      font-size: 20px;
    }
    &.slick-next {
      &::before {
        content: '\e938';
      }
    }
    &.slick-disabled {
      opacity: 0.25;
      cursor: default;
    }
  }
}

.deep-dive-retreat {
  .banner {
    background-image: url(/img/deep-dive-retreat-banner.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    text-align: center;
    position: relative;
    @media screen and (max-width: 767px) {
      background-image: url(/img/deep-dive-banner-mobile.png);
      background-position: center;
    }
    &:before {
      position: absolute;
      background: rgba(255, 255, 255, 0.7);
      height: 764px;
      width: 100%;
      top: 0;
      left: 0;
      content: '';
    }
    .container {
      margin-left: auto;
      margin-right: auto;
      height: 764px;
      padding: 134px 100px 0;
      z-index: 1;
      position: relative;
      @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        height: auto;
        padding-bottom: 20px;
      }
    }
    .banner-logo {
      @media screen and (max-width: 767px) {
        margin-bottom: 70px;
      }
    }
    .banner-title {
      color: rgb(49, 54, 78);
      font-size: 88px;
      font-family: Lora;
      font-weight: 400;
      font-style: normal;
      line-height: 1;
      span {
        color: rgb(149, 152, 166);
        font-size: 55px;
        font-family: 'Work Sans';
        font-weight: 400;
        @media screen and (max-width: 767px) {
          font-size: 36px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 52px;
      }
    }
    .register-button {
      width: 341px;
      height: 59px;
      background: linear-gradient(#ffd16f, #ff865b);
      box-shadow: none;
      text-shadow: none;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      font-family: Work Sans;
      border: none;
      border-radius: 10px;
      color: white;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        width: 256px;
        height: 54px;
        font-size: 16px;
      }
    }
    .banner-text {
      font-size: 28px;
      color: rgb(49, 54, 78);
      font-family: Lora;
      font-weight: 400;
      max-width: 648px;
      padding-top: 20px;
      margin: auto;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    .eligibility-text {
      margin-top: 20px;
      font-size: 14px;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }
  .container {
    &.marma-training-section {
      max-width: 1270px;
    }
  }

  .section-title {
    font-family: Lora;
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    line-height: 1.4;
    margin: 15px 0;
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }
  h4 {
    font-size: 20px;
    font-weight: 400;
  }
  h5 {
    font-size: 20px;
  }
  .deep-dive-video {
    @media screen and (max-width: 991px) {
      margin-top: 40px;
      margin-bottom: 50px;
    }
  }
  .deep-dive-first-section {
    .section-title {
      margin-bottom: 25px;
    }
    p {
      @media screen and (min-width: 992px) {
        padding-right: 150px;
        padding-top: 20px;
      }
    }
  }
  .deep-dive-featuers {
    background: rgba(247, 247, 247, 1);
    @media screen and (max-width: 767px) {
      .col-md-4:first-child {
        .dd-feature-item {
          margin-top: 0;
        }
      }
      .dd-feature-item {
        margin-top: 50px;
      }
    }
    .dd-feature-icon {
      margin-bottom: 20px;
      img {
        border: #eb9956 1px solid;
        border-radius: 18px;
        padding: 15px;
        width: 60px;
        height: 60px;
        box-sizing: unset;
      }
    }
    .dd-feature-text {
      max-width: 276px;
    }
  }
  .deep-dive-benefits {
    .section-title {
      color: rgb(235, 153, 86);
      max-width: 700px;
      text-align: center;
      margin: auto;
      line-height: 1.3;
      margin-bottom: 50px;
    }
    .benefit-you-get {
      background: rgba(243, 209, 182, 0.15);
      padding: 50px;
      border-radius: 18px;
      @media screen and (min-width: 992px) {
        padding: 50px 80px 50px 60px;
        margin-right: 10px;
      }
      h5 {
        font-weight: 400;
        margin-bottom: 20px;
      }
      ul {
        padding-left: 30px;
        li {
          position: relative;
          margin-bottom: 20px;
          line-height: 1.8;
          &::before {
            content: '\2713';
            background: #ffae01;
            color: white;
            width: 19px;
            height: 19px;
            border-radius: 50%;
            line-height: 19px;
            font-size: 14px;
            text-align: center;
            position: absolute;
            top: 5px;
            left: -30px;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .benefit-pictures {
      @media screen and (max-width: 991px) {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
      }
      img {
        border-radius: 18px;
        height: 313px;
        width: 327px;
        max-width: 100%;
        object-fit: cover;
        opacity: 0.6;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      img:nth-child(2) {
        opacity: 0.5;
        height: 201px;
        width: 327px;
        margin-top: 30px;
        @media screen and (max-width: 991px) {
          margin-top: 0px;
          height: 327px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
    .dd-view-schedule {
      margin-top: 40px;
      text-align: center;
      a {
        color: #eb9956;
        text-decoration: none;
      }
    }
    .dd-register-text {
      font-size: 20px;
      font-style: italic;
      text-align: center;
      max-width: 700px;
      margin: 10px auto 0;
    }
    .dd-register-button {
      text-align: center;
    }
    .register-button {
      width: 250px;
      height: 58px;
      background: linear-gradient(#ffd16f, #ff865b);
      box-shadow: none;
      text-shadow: none;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: Work Sans;
      border: none;
      border-radius: 16px;
      color: white;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }
  #dd-schedule.modal {
    .modal-header .close {
      border: #000 1px solid;
      right: -17px;
    }
    .modal-body {
      h3 {
        text-align: center;
      }
      h5 {
        text-align: center;
      }
      table {
        font-size: 16px;
        td {
          padding-right: 20px;
        }
        td:empty {
          height: 20px;
        }
        margin-bottom: 20px;
      }
      .orange-text {
        color: #ff7f00;
      }
      .note {
        font-size: 12px;
        font-style: italic;
      }
    }
  }
  section.dd-testimonial {
    background: rgba(111, 114, 131, 1);
    .container {
      text-align: center;
      max-width: 900px;
    }
    .rating {
      img {
        width: 100px;
        height: 24px;
      }
    }
    .testimonial-text,
    .testimonial-author {
      font-family: 'Work Sans';
      font-weight: 300;
      font-style: normal;
      font-size: 24px;
      color: white;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
  .dd-yt-video-section {
    .section-title {
      color: rgb(235, 153, 86);
      line-height: 1.2;
      @media screen and (min-width: 992px) {
        padding-right: 70px;
      }
    }
    .section-description {
      font-size: 20px;
      font-style: italic;
      @media screen and (min-width: 992px) {
        padding-right: 90px;
      }
    }
    .yt-wrapper {
      background: white;
      box-shadow: 10px 0px 30px #d9d9d9;
      border-style: none;
      border-radius: 18px;
      padding: 20px;
      @media screen and (max-width: 991px) {
        margin-top: 40px;
      }
      @media screen and (max-width: 767px) {
        iframe {
          height: 250px;
        }
      }
    }
  }
  .section-upcoming-dates {
    background-image: url(/img/dd-web-panel-gradient-lighter.jpeg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      display: block;
      background: rgba(255, 255, 255, 0.57);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .container {
      position: relative;
    }
    h5 {
      font-weight: 400;
    }
    .session-info {
      color: rgb(119, 119, 119);
      font-size: 14px;
    }
    .note {
      font-size: 11px;
      color: rgb(119, 119, 119);
      max-width: 340px;
      margin: 15px auto 50px;
      a {
        color: rgb(119, 119, 119);
        text-decoration: underline;
      }
    }
    .register-button {
      width: 250px;
      height: 58px;
      background: linear-gradient(#ffd16f, #ff865b);
      box-shadow: none;
      text-shadow: none;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: Work Sans;
      border: none;
      border-radius: 16px;
      color: white;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }
  .section-certified-instructor {
    .instructor-list {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
      @media screen and (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .instructor {
        @media screen and (max-width: 991px) {
          margin: 0 10px 20px;
        }
        &-photo {
          margin-bottom: 10px;
          img {
            width: 138px;
            height: 139px;
            border-radius: 50%;
          }
        }
        &-name {
          font-size: 24px;
          line-height: 1;
          margin-bottom: 15px;
        }
        &-more {
          text-transform: uppercase;
          font-size: 16px;
          line-height: 19px;
          a {
            color: #9598a6;
            text-decoration: none;
          }
        }
      }
    }
    .modal-header .close {
      border: #000 1px solid;
      right: -17px;
    }
    .modal-content {
      p {
        color: rgb(111, 115, 131);
        font-size: 14px;
        text-align: justify;
        line-height: 24px;
      }
    }
  }
  .section-contact-info {
    .contact-info {
      color: rgb(111, 114, 131);
      a {
        color: rgb(111, 114, 131);
      }
    }
  }
  .about-art-of-living--section {
    background: linear-gradient(#fff, #e7efff);
    @media screen and (max-width: 991px) {
      padding: 50px 0;
    }
    .container {
      max-width: 930px;
    }
    .section-title {
      text-align: center;
      margin-bottom: 70px;
    }
    .marma-about-aol-item {
      text-align: center;
    }
    .marma-about-aol-title {
      font-weight: 700;
      font-family: 'Work Sans';
      font-size: 20px;
      margin-top: 20px;
    }
    .marma-about-aol-text {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    .modal {
      padding: 15px !important;
      .modal-body {
        padding: 15px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .modal-dialog {
      top: 50%;
      transform: translateY(-50%) !important;
      max-width: 840px;
    }
    .modal-content {
      padding: 25px 0px 0px;
      max-height: 90vh;
    }
  }
  .modal-content {
    border-radius: 0;
    box-shadow:
      0 0 12px rgba(0, 0, 0, 0.3),
      0 1px 5px rgba(0, 0, 0, 0.2);
    .modal-header .close {
      border: #fff 3px solid;
      right: -17px;
      background: black;
      color: white;
      font-size: 28px;
      font-weight: 400;
      line-height: 25px;
      top: -17px;
      outline: none;
    }
  }
  .modal.fade {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-dialog .modal-content .modal-body {
    padding: 0px 60px 30px;
    margin: 25px 0 0px;
    max-height: 90vh;
    overflow: auto;
  }
}
body.modal-open {
  overflow: auto;
}

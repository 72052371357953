.wcf-auth-selector {
  display: flex;
  width: 100%;

  &__button {
    display: block;
    width: 50%;
    font-family: $wcf-font-regular;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    white-space: nowrap;
    background-color: $wcf-light;
    outline: none;
    border-radius: 0;
    padding: 15px 25px 14px;
    color: transparentize($wcf-dark, 0.5);
    border: 1px solid rgba(17, 17, 17, 0.25);
    transition: all $wcf-transition-effect;

    &:hover {
      box-shadow: $wcf-shadow-hover;
    }

    &:active {
      box-shadow: $wcf-shadow-active;
    }

    &:focus {
      outline: none;
    }

    &_active {
      font-family: $wcf-font-medium;
      color: $wcf-light;
      border-color: $wcf-dark;
      background-color: $wcf-dark;
    }
  }
}

.courses-nearby {
  .container {
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 1334px;
    @media only screen and (max-width: 991px) {
      padding: 30px 0;
    }
  }
  .section-title {
    font-size: 70px;
    line-height: 84px !important;
    padding: 0;
    margin-bottom: 24px;
    font-weight: 400;
    @media only screen and (max-width: 991px) {
      font-size: 32px;
      line-height: 38px !important;
      margin-bottom: 32px;
      padding: 0 15px;
    }
  }
  .course-types {
    display: flex;
    gap: 16px;
    justify-content: center;
    @media only screen and (max-width: 991px) {
      padding: 0 15px;
    }
    .form-item {
      background-color: #f7f7f7;
      border-radius: 32px;
      padding: 6px;
    }
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      height: 0px;
      width: 0px;
    }
    label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: #31364e;
      margin: 0;
      &::after {
        height: 20px;
        width: 20px;
        min-width: 20px;
        border: #ddd5d0 1.5px solid;
        border-radius: 20px;
        content: '';
        display: block;
      }
      .icon-aol {
        background: linear-gradient(
          102.78deg,
          #3d8be8 -37.89%,
          #89beec 100.28%
        );
        width: 32px;
        height: 32px;
        border-radius: 100%;
        font-size: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        &.iconaol-monitor-mobile {
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
        }
      }
    }
    input[type='radio']:checked {
      + label {
        &::after {
          border-color: #fff;
          border-width: 2px;
          content: '';
          display: flex;
          align-items: center;
          justify-content: center;
          color: #e47d6c;
          background: #e47d6c;
          box-shadow: 0px 0px 0px 2px #e47d6c;
          width: 16px;
          height: 16px;
          min-width: 16px;
          margin-right: 2px;
          margin-left: 2px;
        }
      }
    }
  }
  .course-location-select {
    margin-top: 16px;
    @media only screen and (max-width: 991px) {
      padding: 0 15px;
    }
    .form-item {
      display: flex;
      flex-direction: column;
      max-width: 321px;
      margin: 0 auto;

      + .form-item {
        margin-top: 12px;
      }
      label {
        font-size: 14px;
        color: #6f7283;
        font-weight: 500;
        margin-bottom: 6px;
      }
      select {
        padding: 10px 14px;
        height: 44px;
        border: 1px solid #e9e9e9;
        border-radius: 16px;
        box-shadow: 0px 1px 2px 0px #1018280d;
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #31364e;
      }
      input[type='text'] {
        height: 60px;
        border-radius: 16px;
        border: 1px solid #e9e9e9;
        padding: 10px 20px;
      }
    }
  }
  .courses-slider {
    max-width: 1254px;
    margin: auto;
    .slick-list {
      padding: 36px 0;
      margin: 0 28px;
      @media only screen and (max-width: 1279px) {
        padding: 22px 0px 22px 0px !important;
        margin: 0 0 28px;
      }
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      padding-left: 12px;
      padding-right: 12px;
      height: inherit;
      @media only screen and (max-width: 1279px) {
        padding-left: 0px;
        padding-right: 0px;
      }
      > div {
        height: 100%;
      }
    }
    .slide {
      box-shadow: 0px 4px 24px 8px #b1aaaa26;
      background-color: #ffffff;
      border-radius: 16px;
      padding: 20px 16px;
      height: 100%;
      @media only screen and (max-width: 991px) {
        margin: 10px;
      }
      .course-item-box {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .course-item-top {
        .row {
          display: flex;
          gap: 12px;
          margin: 0;
        }
      }
      .course-item-price {
        background: linear-gradient(94.04deg, #ffa180 3.84%, #ed7347 109.6%);
        padding: 4px 10px;
        border-radius: 8px;
        font-family: Lora;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        color: #fff;
        text-align: center;
      }
      .course-item-date {
        flex: 1;
        background-color: #edf5fc;
        text-align: center;
        padding: 6px 5px;
        color: #3d8be8;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        border-radius: 8px;
      }
      .payment-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: #e9e9e9 1px solid;
        .payby {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          display: flex;
          gap: 6px;
          align-items: center;
          img {
            height: 22px;
            margin-top: -10px;
          }
        }
        .price-breakup {
          text-align: right;
        }
        .price-per-month {
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          span {
            font-size: 16px;
            font-weight: 500;
          }
        }
        .payment-tenure {
          color: #6f7283;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .course-item-content {
        padding-bottom: 32px;
        label {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 4px;
        }
        & > div {
          padding: 8px 0;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      .course-instructors,
      .course-location,
      .course-date {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: flex-start;
        &::before {
          content: '\e90c';
          font-family: 'iconaol' !important;
          line-height: 16px;
          text-align: center;
          font-size: 20px;
          color: #3d8be8;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          margin-right: 14px;
          background-color: #edf5fc;
          border-radius: 100%;
        }
      }
      .course-location {
        &::before {
          content: '\e90b';
        }
      }
      .course-date {
        &::before {
          content: '\e90a';
        }
      }
      .course-actions {
        margin-top: auto;
        button {
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          padding: 10px 32px;
          border-radius: 100px;
          width: 100%;
          color: #ffffff;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          height: 44px;
        }
      }
    }
    .slick-arrow {
      box-shadow:
        0px 2px 4px -2px #0000000f,
        0px 4px 8px -2px #0000001a;
      height: 48px;
      width: 48px;
      border: 1px solid #e9e9e9;
      background-color: #ffffff;
      border-radius: 48px;
      z-index: 1;
      &::before {
        content: '\e937';
        font-family: 'iconaol' !important;
        color: #31364e;
        opacity: 1;
        font-size: 20px;
      }
      &.slick-next {
        &::before {
          content: '\e938';
        }
      }
      &.slick-next {
        &::before {
          content: '\e938';
        }
      }
      &.slick-disabled {
        opacity: 0.25;
        cursor: default;
      }
    }
  }
  .courses-nearby-actions {
    display: flex;
    justify-content: center;
    button {
      background: linear-gradient(102.78deg, #3d8be8 -37.89%, #89beec 100.28%);
      border-radius: 100px;
      padding: 0 32px;
      height: 60px;
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

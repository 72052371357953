.user-profile-page {
  background-color: #fffaf5;
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
    padding-top: 0px;
  }
  .showPassBtn {
    border: none;
    background: none;
    position: absolute;
    top: 34px;
    right: 0;
    padding: 5px 14px;
    &:focus {
      outline: none;
    }
    .icon-aol:before {
      color: #98a2b3;
      font-size: 24px;
    }
  }
  input {
    &.validate {
      border-color: #dc3545;
    }
  }
  .showPassBtn.password {
    .icon-aol:before {
      content: '\e918';
    }
  }
  .showPassBtn.text {
    .icon-aol:before {
      content: '\e939';
    }
  }
  .container {
    max-width: 1348px;
    padding-top: 50px;
    @media screen and (max-width: 991px) {
      padding-top: 24px;
    }
  }
  .user-info-grid {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .new-journey-header {
    margin-top: 18px;
    text-align: center;
    font-weight: 400;
    color: #6f7283;
    font-family: Work Sans;
    font-size: 14px;
  }
  .new-journey-btn-wrapper {
    justify-content: center;
    margin-top: 25px;
  }
  .link-modal {
    width: 100%;
    text-align: center;
    justify-content: center;
    border-radius: 100px;
  }
  .user-info-box {
    background: white;
    box-shadow: 0px 4px 32px 0px #95300017;
    width: 320px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 32px 24px 32px;
    flex-shrink: 0;
    @media screen and (min-width: 560px) and (max-width: 991px) {
      width: 100%;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0 20px;
      grid-template-areas:
        'profilePicture username'
        'profilePicture userType'
        'profilePicture userSince'
        'profilePicture newJourneyHeader'
        'profilePicture newJourneyButtonWrap'
        'profilePicture userLogout';
    }
    @media screen and (max-width: 559px) {
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      order: 1;
    }
    .profile-picture {
      width: 120px;
      height: 120px;
      background: linear-gradient(94.04deg, #ffa180 3.84%, #ed7347 109.6%);
      color: white;
      font-size: 40px;
      font-weight: 600;
      line-height: 65.45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-bottom: 24px;
      position: relative;
      grid-area: profilePicture;
      @media screen and (max-width: 991px) {
        margin-bottom: 0;
      }
      .profile-pic {
        position: absolute;
        top: 0;
        left: 0;
      }
      .camera-icon {
        top: 0;
        left: 0;
        display: none;
        position: relative;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        border-radius: 100%;
        input[type='file'] {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          -webkit-appearance: none;
          opacity: 0;
        }
      }
      &:hover .camera-icon {
        display: flex;
      }
    }
    .user-name {
      font-family: Lora;
      font-size: 28px;
      font-weight: 500;
      line-height: 36.4px;
      text-align: center;
      margin-bottom: 12px;
      grid-area: username;
      @media screen and (max-width: 991px) {
        text-align: left;
      }
    }
    .user-type,
    .user-since {
      color: #6f7283;
      font-family: Work Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      grid-area: userType;
    }
    .user-since {
      font-weight: 400;
      grid-area: userSince;
    }

    .user-logout {
      margin-top: 24px;
      border-top: #e9e9e9 1px solid;
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding-top: 24px;
      grid-area: userLogout;
      @media screen and (max-width: 991px) {
        padding-top: 10px;
        margin-top: 10px;
      }
      a {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        color: #3d8be8;
        .icon-aol {
          font-size: 20px;
        }
      }
    }
  }
  .profile-info-box {
    flex: 1;
    @media screen and (min-width: 992px) {
      max-width: calc(100vw - 385px);
    }
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
    .tab-content {
      display: none;
    }

    .tab-content.active {
      display: block;
    }

    .profile-tabs {
      margin-bottom: 10px;
      ul {
        display: flex;
        gap: 10px;
        overflow-x: auto;
        padding-bottom: 10px;
        li {
          display: flex;
        }
        a {
          background: #fff;
          border: #e9e9e9 1px solid;
          height: 36px;
          padding: 0px 14px;
          border-radius: 32px;
          color: #6f7283;
          font-size: 16px;
          font-weight: 500;
          line-height: 34px;
          text-decoration: none;
          white-space: nowrap;
          &.active {
            background: linear-gradient(
              102.78deg,
              #3d8be8 -37.89%,
              #89beec 100.28%
            );
            color: white;
            border: transparent 1px solid;
          }
        }
      }
    }
    .profile-form-box {
      background: white;
      box-shadow: 0px 0px 40px 0px #2323340a;
      border-radius: 24px;
      padding: 24px;
      @media screen and (max-width: 767px) {
        padding: 16px;
      }
      .profile-form-wrap {
        gap: 12px;
        display: flex;
        flex-wrap: wrap;

        .form-item .selected-flag {
          outline: none;
          position: relative;
          width: 38px;
          display: flex;
          flex: 1;
          align-items: center;
          padding: 0px;
        }
        .second .react-tel-input .selected-flag .arrow {
          top: unset;
        }
      }
      .col-1-1 {
        flex: 100%;
      }
      .col-1-2 {
        flex: calc(50% - 6px);
        @media screen and (max-width: 767px) {
          flex: 100%;
        }
      }
      .form-item.card-number {
        flex: 1;
        @media screen and (max-width: 767px) {
          flex: 100%;
        }
      }
      .relative {
        position: relative;
      }
      .input-info {
        color: #9598a6;
        margin-top: 6px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
      button.showPass {
        border: none;
        background: none;
        color: #98a2b3;
        font-size: 16px;
        height: 16px;
        width: 16px;
        padding: 0;
        line-height: 16px;
        right: 15px;
        top: 41px;
        position: absolute;
      }
      .form-item.expiry,
      .form-item.cvv {
        max-width: 112px;
        @media screen and (max-width: 767px) {
          max-width: unset;
          flex: calc(50% - 6px);
        }
      }
      .form-actions {
        margin-top: 8px;
        border-top: #e9e9e9 1px solid;
        padding-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        button {
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          height: 44px;
          width: 203px;
          border-radius: 100px;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          border: none;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          &.secondary-btn {
            border: 1.5px solid #ff865b;
            background: white;
            color: #e47d6c;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .form-title-wrap {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        .form-title-text {
          font-weight: 500;
        }
        .form-title-icon {
          font-size: 20px;
          width: 46px;
          height: 32px;
          border: #eaecf0 1px solid;
          border-radius: 8px;
          text-align: center;
          line-height: 34px;
        }
      }
    }
  }
  .state-dropdown {
    display: flex;
    flex-direction: column;
    .react-select__single-value {
      color: black;
      font-size: 16px;
      font-weight: 400;
    }
    label {
      font-size: 14px;
      color: #9598a6;
      margin-bottom: 6px;
    }
  }
  .edit-icon {
    background-color: hsla(0, 0%, 100%, 0.522);
    color: #3d8be8;
    position: absolute;
    right: 15px;
    top: 35px;
    text-align: left;
  }
  .form-item {
    display: flex;
    flex-direction: column;
    position: relative;
    label {
      font-size: 14px;
      color: #9598a6;
      margin-bottom: 6px;
    }
    .input-msg {
      font-size: 12px;
      padding-top: 4px;
      line-height: 18px;
      font-weight: 400;
    }
    .eye-button {
      position: absolute;
      top: 32px;
      right: 16px;
      display: block;
      background-color: $wcf-light;
      padding: 4px;
      width: 32px;
      height: 32px;
      text-align: center;
      border: none;
      img {
        width: 24px;
        height: 24px;
      }
      &:focus {
        outline: none;
      }
    }
    select {
      padding: 10px 14px;
      height: 44px;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
    input:not(
        [type='file' i],
        [type='image' i],
        [type='checkbox' i],
        [type='radio' i]
      ) {
      padding: 10px 14px;
      height: 44px;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      box-shadow: 0px 1px 2px 0px #1018280d;
      width: 100%;
      &:focus {
        border-color: #ed994e;
      }
      &::placeholder {
        color: #9598a6;
        opacity: 1; /* Firefox */
      }
      &.error {
        border-color: red;
      }
      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #9598a6;
      }
      a {
        text-decoration: none;
        font-size: 26px;
      }
    }
    &.checkbox,
    &.radio {
      flex-direction: row;
      input[type='checkbox'],
      input[type='radio'] {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
        & + label {
          &::before {
            content: '';
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: #ed994e 1px solid;
            border-radius: 4px;
            margin-right: 12px;
            display: flex;
            font-weight: 900;
            justify-content: center;
            align-items: center;
          }
          a {
            display: contents;
            color: #ed994e;
          }
        }
        &:checked + label {
          &::before {
            content: url(/img/checkicon-css.svg);
            background-color: #ed994e;
            color: white;
          }
        }
      }
      input[type='radio'] {
        & + label {
          &::before {
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: #ed994e 1.5px solid;
            border-radius: 20px;
          }
        }
        &:checked + label {
          &::before {
            content: '\25cf';
            background-color: white;
            color: #ed994e;
            font-size: 27px;
            line-height: unset;
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 0px 0 2px 1px;
          }
        }
      }
      label {
        padding-left: 0;
        display: flex;
        align-items: center;
        color: #31364e;
      }
    }
  }
  .accordion--past-courses {
    .past-accordion-item {
      background-color: white;
      border-radius: 24px;
      box-shadow: 0px 0px 40px 0px #2323340a;
      padding: 16px 24px;
      margin-bottom: 12px;
    }
    .past-accordion-header {
      button.btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover,
        &:focus {
          text-decoration: none;
        }
        &[aria-expanded='true'] {
          .icon-aol {
            transform: rotate(90deg);
          }
        }
      }
      .icon-aol {
        color: #6f7283;
        font-size: 24px;
        transform: rotate(-90deg);
        transition: transform 0.5s ease;
      }
      .course-heading {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: #31364e;
      }
      .course-count {
        margin-top: 4px;
        color: #ed994e;
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .course-listing {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      flex: 1;
      padding-top: 16px;
      margin-bottom: 20px;
      .course-list-item {
        display: flex;
        gap: 12px;
        flex: 1 0 48%;
        max-width: 49%;
        @media screen and (max-width: 767px) {
          max-width: 100%;
          flex: 1 0 100%;
        }
      }
      .course-number {
        background-color: #edf5fc;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        color: #2465b3;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
      .course-info {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        gap: 8px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .past-courses-stats {
    display: flex;
    gap: 20px;
    margin-bottom: 32px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .stats-info {
      flex: 1;
      background: #edf5fc;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px 16px;
    }
    .number {
      font-family: Lora;
      font-size: 36px;
      font-weight: 400;
      line-height: 45px;
      text-align: center;
      color: #3d8be8;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #3d8be8;
    }
  }
  .courses-history {
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .course-history-info {
      display: flex;
      gap: 14px;
      padding-top: 16px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .ch-info-pill {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 16px;
        background: #f7f7f7;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        .icon-aol {
          color: #3d8be8;
          font-size: 20px;
        }
      }
    }
    .history-accordion-item {
      position: relative;
      padding-bottom: 24px;
      &::before {
        content: '';
        border-radius: 2px;
        width: 2px;
        height: calc(100% - 32px);
        background: #ed994e;
        position: absolute;
        left: 11px;
        top: 28px;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
      &.active {
        .history-accordion-header {
          h2 {
            button {
              color: #ed994e;
            }
          }
          .iconaol-Tick {
            box-shadow: 0px 0px 0px 4px #ed994e40;
            &::before {
              content: '';
              height: 8px;
              width: 8px;
              background: white;
              border-radius: 8px;
            }
          }
        }
      }
    }
    .history-accordion-header {
      h2 {
        display: flex;
        align-items: center;
        button {
          color: #31364e;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          font-family: 'Work Sans';
          padding: 0 0 0 12px;
          background: none;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:hover,
          &:focus {
            text-decoration: none;
          }
          .icon-aol {
            font-size: 24px;
          }
          &[aria-expanded='true'] {
            .icon-aol::before {
              content: '\e922';
            }
          }
        }
      }
      .iconaol-Tick {
        background: #ed994e;
        color: white;
        height: 24px;
        width: 24px;
        font-size: 12px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .history-accordion-body {
      padding: 0 0 0 36px;
    }
  }
  .no-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    @media screen and (max-width: 767px) {
      margin-bottom: 32px;
    }
    .no-events-icon {
      background: #fffaf5;
      border-radius: 16px;
      padding: 12px;
      color: #d6cec9;
      font-size: 42px;
      line-height: 42px;
    }
    .no-events-text {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      margin: 20px 0 0 0;
      text-align: center;
    }
    .find-event-text {
      color: #6f7283;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 15px;
      text-align: center;
      a {
        font-weight: 500;
        color: #3d8be8;
        text-decoration: none;
      }
    }
  }
  .preffered-upcoming-events {
    h2.title {
      font-size: 28px;
      font-weight: 500;
      line-height: 36.4px;
      text-align: center;
      margin-bottom: 25px;
    }
    .course-listing {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      flex: 1;
      .selected-filter-wrap {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      .course-item {
        flex: 1 0 48%;
        max-width: 49%;
        background: white;
        border: #e9e9e9 1px solid;
        border-radius: 16px;
        padding: 20px 16px;
        background: #ffffff;
        position: relative;
        @media screen and (max-width: 991px) {
          max-width: 100%;
          flex: 1 0 100%;
        }
        &:hover {
          .course-actions {
            display: flex;
          }
        }
      }
      .course-item-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 14px;
      }
      .course-title {
        font-family: Lora;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 12px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
        .icon-aol {
          background: linear-gradient(
            102.78deg,
            #3d8be8 -37.89%,
            #89beec 100.28%
          );
          font-size: 18px;
          color: white;
          padding: 6px;
          border-radius: 12px;
          margin-right: 8px;
        }
      }
      .course-duration {
        background: #edf5fc;
        border-radius: 8px;
        padding: 5px 12px;
        color: #3d8be8;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        &.online {
          background: #fff5eb;
          color: #ed994e;
        }
      }
      .course-price {
        background: linear-gradient(94.04deg, #ffa180 3.84%, #ed7347 109.6%);
        border-radius: 8px;
        padding: 4px 12px;
        color: white;
        font-family: Lora;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
      .course-instructors,
      .course-location {
        margin-bottom: 14px;
        padding: 14px 0;
        border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          width: 16px;
          height: 16px;
          display: block;
          background: url(/img/blue-icon-profile.webp) no-repeat center;
          background-size: contain;
          flex-shrink: 0;
          margin-left: 4px;
          margin-right: 12px;
        }
      }
      .course-location {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        &::before {
          background: url(/img/blue-icon-location.png) no-repeat center;
          background-size: 16px;
        }
        & + .course-instructors {
          padding-top: 12px;
          border-top: none;
        }
      }
      .course-timing {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          width: 16px;
          height: 16px;
          display: block;
          background: url(/img/blue-icon-calendar.webp) no-repeat center;
          background-size: contain;
          flex-shrink: 0;
          margin-left: 4px;
          margin-right: 12px;
        }
        & + & {
          margin-top: 6px;
        }
      }
      .course-actions {
        background: linear-gradient(
          102.78deg,
          #f5f7f8 -37.89%,
          #ffffff 100.28%
        );
        padding: 24px 20px;
        gap: 12px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-radius: 0 0 16px 16px;
        display: none;
        @media screen and (max-width: 1023px) {
          display: flex;
          padding: 14px 0 0;
          background: none;
          position: relative;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        button {
          flex: 1;
          border-radius: 100px;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          @media screen and (max-width: 767px) {
            flex: unset;
          }
          &.btn-secondary {
            border: #ff865b 1.5px solid;
            color: #e47d6c;
            background: #f5f7f8;
          }
          &.btn-primary {
            border: none;
            color: #fff;
            background: linear-gradient(
              102.78deg,
              #ffd16f -37.89%,
              #ff865b 100.28%
            );
          }
        }
      }
    }
  }
  .user-preferences {
    h2.title {
      font-size: 22px;
      font-weight: 500;
      line-height: 28.6px;
      margin-bottom: 4px;
    }
    .desc {
      color: #6f7283;
      font-size: 16px;
    }
    .centers-listing {
      display: flex;
      gap: 15px;
      margin-top: 18px;
      padding-bottom: 24px;
      border-bottom: #e9e9e9 1px solid;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .center-item {
        background: #f7f7f7;
        border-radius: 20px;
        padding: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        &.add-new {
          display: flex;
          border: #e9e9e9 2px dashed;
          background: none;
          gap: 12px;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #3d8be8;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          cursor: pointer;
          .icon-aol {
            background: #edf5fc;
            padding: 10px;
            border-radius: 12px;
            font-size: 24px;
            color: #3d8be8;
          }
        }
      }
      .item-top-row {
        display: flex;
        gap: 8px;
        padding-bottom: 2px;
      }
      .number {
        height: 30px;
        min-width: 30px;
        width: 30px;
        background: linear-gradient(
          102.78deg,
          #3d8be8 -37.89%,
          #89beec 100.28%
        );
        font-family: Lora;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
      }
      .city {
        font-family: Lora;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
      }
      .delete-item {
        border: none;
        background: none;
        font-size: 20px;
        padding: 0;
        margin-left: auto;
        color: #31364e;
        &:focus {
          border: none;
          outline: none;
        }
      }
      .center-other-info {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        gap: 16px;
        margin-top: 14px;
        word-break: break-word;
        .icon-aol {
          font-size: 20px;
          color: #3d8be8;
        }
      }
      .action-btn {
        padding-top: 40px;
        margin-top: auto;
        .submit-btn {
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          border-radius: 100px;
          font-weight: 500;
          font-size: 16px;
          border: none;
          width: 100%;
          height: 40px;
          color: white;
        }
      }
    }
    .preferred-teachers {
      padding-top: 24px;
      .teachers-listing {
        padding-top: 18px;
        display: flex;
        gap: 15px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        .teachers-item {
          background: #f7f7f7;
          padding: 20px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          gap: 8px;
          flex: calc(100% - 8px);
        }
        .teacher-photo {
          width: 30px;
          height: 30px;
          background: #e47d6c;
          border-radius: 30px;
          font-size: 10.23px;
          font-weight: 600;
          line-height: 16.36px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
          }
        }
        .delete-item {
          border: none;
          background: none;
          font-size: 20px;
          padding: 0;
          margin-left: auto;
          &:focus {
            border: none;
            outline: none;
          }
        }
        .teacher-name {
          font-family: Lora;
          font-size: 18px;
          font-weight: 500;
          line-height: 23.4px;
        }
      }
    }
  }
  .refer-section {
    .top-logo {
      text-align: center;
      margin-bottom: 12px;
    }
    h1.title {
      text-align: center;
      font-size: 26px;
      font-weight: 500;
      line-height: 33.8px;
      margin-bottom: 20px;
    }
    .section-desc {
      color: #6f7283;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin-bottom: 40px;
    }
    .refer-section-items {
      display: flex;
      gap: 24px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    .refer-section-col {
      flex: calc(50% - 12px);
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .refer-section-card {
      border-radius: 32px;
      background: white;
      box-shadow: 0px 0px 12px 0px #23233412;
      padding: 32px 24px;
      @media screen and (max-width: 767px) {
        padding: 16px;
      }
      .title {
        font-size: 22px;
        font-weight: 500;
        line-height: 28.6px;
        margin-bottom: 8px;
      }
      .desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #6f7283;
      }
      .redeem-coupons {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-top: 24px;
        input[type='radio'] {
          position: absolute;
          opacity: 0;
          height: 0px;
          width: 0px;
        }
        label {
          border: #e9e9e9 2px solid;
          border-radius: 16px;
          padding: 12px 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          display: flex;
          gap: 8px;
          align-items: center;
          color: #31364e;
          margin: 0;
          &::before {
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: #ddd5d0 1.5px solid;
            border-radius: 20px;
            content: '';
            display: block;
          }
        }
        input[type='radio']:checked {
          + label {
            border: #ff865b 2px solid;
            font-weight: 500;
            &::before {
              border-color: #fff;
              border-width: 2px;
              content: '';
              display: flex;
              align-items: center;
              justify-content: center;
              color: #e47d6c;
              background: #e47d6c;
              box-shadow: 0px 0px 0px 2px #e47d6c;
              width: 16px;
              height: 16px;
              min-width: 16px;
              margin-right: 2px;
              margin-left: 2px;
            }
          }
        }
        .btn-primary {
          background: linear-gradient(
            102.78deg,
            #3d8be8 -37.89%,
            #89beec 100.28%
          );
          height: 40px;
          color: white;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          border-radius: 100px;
          padding: 0;
          margin-top: 12px;
        }
      }
      .share-rewards {
        padding-top: 16px;
        .share-reward-stats {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 15px;
          .stat-item {
            background: #edf5fc;
            border-radius: 16px;
            color: #3d8be8;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .value {
              font-family: Lora;
              font-size: 32px;
              font-weight: 600;
              line-height: 32px;
              margin-bottom: 4px;
            }
            label {
              font-size: 14px;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: 0.02em;
              text-transform: uppercase;
            }
          }
        }
        .tracking-bar-wrap {
          padding-top: 24px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          label {
            font-size: 14px;
            line-height: 18px;
            color: #6f7283;
            font-weight: 500;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            gap: 6px;
            a {
              line-height: 10px;
            }
            .icon-aol {
              color: #9598a6;
              font-size: 16px;
            }
          }
          .progress {
            height: 12px;
            background: #f7f7f7;
            border-radius: 360px;
          }
          .progress-bar {
            border: none;
            background: linear-gradient(
              102.78deg,
              #3d8be8 -37.89%,
              #89beec 100.28%
            );
            height: 12px;
          }
        }
      }
      .refer-friend {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        text-align: center;
        label {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 14px;
        }
        button.btn-primary,
        button.btn-secondary {
          background: linear-gradient(
            102.78deg,
            #ffd16f -37.89%,
            #ff865b 100.28%
          );
          height: 44px;
          padding: 0;
          color: white;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          justify-content: center;
          .icon-aol {
            font-size: 24px;
          }
        }
        button.btn-secondary {
          border: #ff865b 1.5px solid;
          background: white;
          color: #e47d6c;
        }
        .or-separator {
          display: flex;
          align-items: center;
          position: relative;
          justify-content: center;
          margin: 24px 26px;
          gap: 12px;
          &::before,
          &::after {
            height: 1px;
            background-color: #e9e9e9;
            content: '';
            flex: 1;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #9598a6;
          }
        }
        input[type='text'] {
          height: 46px;
          border-radius: 12px;
          padding: 14px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          &:disabled {
            background: #f7f7f7;
            border: none;
          }
        }
        .refer-desc {
          padding-top: 8px;
          margin-bottom: 14px;
          color: #9598a6;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
        .tac {
          margin-top: 24px;
          padding-top: 24px;
          border-top: #e9e9e9 1px solid;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #9598a6;
        }
      }
    }
    .accordion {
      margin-top: 32px;
      padding-top: 32px;
      border-top: #e9e9e9 1px solid;
    }
    .refer-accordion-item {
      background: #ffffff;
      padding: 24px;
      border-radius: 24px;
      & + .refer-accordion-item {
        margin-top: 20px;
      }
      &:has(> .collapse.show) {
        box-shadow: 4px 6px 25.1px 0px #6e6b6824;
      }
      .refer-accordion-header {
        button {
          font-size: 22px;
          font-weight: 500;
          line-height: 28.6px;
          color: #23262f;
          display: flex;
          justify-content: space-between;
          &:hover,
          &:focus {
            text-decoration: none;
          }
          .icon-aol {
            font-size: 24px;
          }
          &[aria-expanded='true'] {
            .icon-aol::before {
              content: '\e922';
            }
          }
        }
      }
      .reward-accordion-body {
        padding-top: 16px;
        color: #6f7283;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
.modal {
  &.add-new-modal,
  &.refer-email-modal {
    @media screen and (max-width: 720px) {
      padding: 0 !important;
    }
    .modal-dialog {
      max-width: 891px;
      @media screen and (max-width: 720px) {
        max-width: 100%;
        align-items: flex-end;
      }
    }
    .modal-header {
      .title {
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        margin: 0;
        @media screen and (max-width: 720px) {
          font-size: 24px;
          line-height: 31px;
        }
      }
      .close {
        color: #9598a6;
        font-size: 40px;
        right: 16px;
        background: none;
        top: 14px;
        font-weight: 300;
        &:focus {
          outline: none;
        }
      }
    }
    .modal-content {
      border-radius: 20px;
      padding: 24px 32px;
      @media screen and (max-width: 720px) {
        padding: 24px 20px 100px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        min-height: unset;
        height: 100vh;
        overflow: auto;
      }
    }
    .modal-body {
      padding: 24px 0 0 0;
      margin: 0;
      a {
        color: #ed994e;
        text-decoration: none;
      }
      .desc {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #6f7283;
        margin-bottom: 24px;
        margin-top: -10px;
        strong {
          font-weight: 600;
          color: #3d8be8;
        }
      }
      .form-item {
        input:not(
            [type='file' i],
            [type='image' i],
            [type='checkbox' i],
            [type='radio' i]
          ) {
          padding: 10px 14px;
          height: 44px;
          border: 1px solid #e9e9e9;
          border-radius: 16px;
          box-shadow: 0px 1px 2px 0px #1018280d;
          width: 100%;
          &:focus {
            border-color: #ed994e;
          }
          &::placeholder {
            color: #9598a6;
            opacity: 1; /* Firefox */
          }
          &.error {
            border-color: red;
          }
          &::-ms-input-placeholder {
            /* Edge 12 -18 */
            color: #9598a6;
          }
          a {
            text-decoration: none;
            font-size: 26px;
          }
        }
      }
      .centers-listing {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 15px;
        margin-top: 18px;
        height: calc(100vh - 300px);
        overflow: auto;
        @media screen and (max-width: 767px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        .center-item {
          background: #f7f7f7;
          border-radius: 20px;
          padding: 20px;
          flex: 1;
          display: flex;
          flex-direction: column;
          border: transparent 2px solid;
          &.selected {
            background: white;
            border: #ff865b 2px solid;
            .center-select-actions {
              button {
                border-color: transparent;
                background: #f7f7f7;
                color: #31364e;
                &:focus {
                  outline: none;
                }
              }
            }
          }
          &.add-new {
            display: flex;
            border: #e9e9e9 2px dashed;
            background: none;
            gap: 12px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #3d8be8;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            cursor: pointer;
            .icon-aol {
              background: #edf5fc;
              padding: 10px;
              border-radius: 12px;
              font-size: 24px;
              color: #3d8be8;
            }
          }
        }
        .item-top-row {
          display: flex;
          gap: 8px;
          padding-bottom: 2px;
          .icon-aol {
            height: 30px;
            width: 30px;
            background: linear-gradient(
              102.78deg,
              #3d8be8 -37.89%,
              #89beec 100.28%
            );
            font-family: Lora;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
          }
        }
        .city {
          font-family: Lora;
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          text-align: left;
        }

        .center-other-info {
          display: flex;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          gap: 16px;
          margin-top: 14px;
          word-break: break-word;
          .icon-aol {
            font-size: 20px;
            color: #3d8be8;
          }
        }
        .center-select-actions {
          margin-top: auto;
          padding-top: 17px;
          button {
            border: #ff865b 1px solid;
            background: white;
            color: #ff865b;
            width: 100%;
            height: 40px;
            border-radius: 100px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .modal-actions {
        margin-top: 24px;
        display: flex;
        justify-content: center;
      }
    }
    .submit-btn {
      height: 54px;
      padding: 0 32px;
      border-radius: 100px;
      background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
      color: white;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      border: none;
    }
  }
  .input-search-wrap {
    position: relative;
    &::after {
      content: '\e900';
      font-family: 'iconaol';
      font-size: 24px;
      color: #9598a6;
      position: absolute;
      left: 14px;
      top: 10px;
      line-height: 24px;
    }
    input {
      height: 44px;
      border-radius: 16px;
      border: #e9e9e9 1px solid;
      box-shadow: 0px 1px 2px 0px #1018280d;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      width: 100%;
      padding: 10px 35px 10px 50px;
    }
    .schedule-location-input {
      height: 44px;
      border-radius: 16px;
      border: #e9e9e9 1px solid;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      width: 100%;
      padding: 10px 35px 10px 50px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
    }
    .schedule-location-value {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &.add-new-modal {
    .modal-actions {
      @media screen and (max-width: 767px) {
        position: fixed;
        bottom: 32px;
        width: 100%;
        left: 0;
        padding: 0 20px;
        .submit-btn {
          width: 100%;
        }
      }
    }
  }
}
.modal {
  &.refer-email-modal {
    .modal-dialog {
      max-width: 550px;
      @media screen and (max-width: 720px) {
        max-width: 100%;
        align-items: center;
        padding: 0 20px;
        .modal-content {
          padding: 24px;
          border-radius: 20px;
        }
      }
    }
  }
}

.program-experience {
  padding: 100px 0 80px;

  &__quote {
    position: relative;
    padding-top: 88px;
    margin-top: 80px;

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 404px;
      height: 202px;
      background: linear-gradient(180deg, #96bbef 0%, #5b86ec 100%);
      border-top-left-radius: 202px;
      border-top-right-radius: 202px;
      border-bottom: 0;
      opacity: 0.08;
      transform: translateX(-50%);
    }

    &-title {
      font-weight: bold;
      font-size: 44px;
      line-height: 60px;
      color: #1e2232;
      margin-bottom: 28px;
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
      color: #606270;
    }
  }

  &__item {
    position: relative;
    color: #1e2232;
    padding-left: 52px;

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
    }

    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 12px;
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
    }

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: 991px) {
  .footer_courses {
    // margin-bottom: 102px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 20px 10px;

    &-navbar {
      flex-direction: column;
      align-items: center;

      .copyright {
        font-size: 12px;
      }

      &__item {
        margin-right: 10px;

        & a {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .footer_courses {
    // margin-bottom: 94px;
  }
}

@media screen and (max-width: 375px) {
  .footer {
    &-navbar {
      &__item {
        margin-right: 5px;
      }
    }
  }

  .footer_courses {
    // margin-bottom: 87px;
  }
}

.insight-collection {
  .top-column.meditation-page.insight-collection
    .container-fluid
    .row
    .col-12
    .course-description {
    font-family:
      Work Sans,
      sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
  }

  .course-name {
    margin-bottom: 16px;
  }

  .top-column.meditation-page.insight-collection.insight-collection3 {
    padding: 122px 150px 50px 150px;
    margin-bottom: -60px;
  }

  &.meditation .browse-category .swiper-slide {
    margin-right: 0px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    margin-top: -30px;
  }

  .top-column.what-meditation {
    margin-bottom: 80px;
    padding: 118px 0 100px 150px;

    .type-course {
      font-size: 30px;
    }

    .container .card {
      width: 750px;

      &.guide-meditation {
        margin-top: 28px;
      }

      .card-title {
        font-size: 24px;
        line-height: 1.42;
      }

      .card-text {
        margin-top: 9px;
        font-size: 20px;
        line-height: 1.5;
      }
    }
  }

  .details {
    padding: 120px 0 40px 0;
  }

  .search_course_form_mobile {
    .filter--button {
      border-radius: 4px;
      padding: 4px 10px;
      border: 1px solid #e9e9e9;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-out;
    }

    #filter-count {
      font-size: 14px;
      display: none;
      font-weight: 600;
      color: #c4c5cc;
      margin-left: 5px;
    }
  }
}

.insight-collection2 {
  &.top-column .type-course {
    margin-bottom: 8px;
  }

  &.insight-collection .course-name {
    margin-bottom: 9px;
  }

  &.top-column.meditation-page.insight-collection {
    .container-fluid .row .col-12 .course-description {
      font-size: 16px;
      line-height: 25px;
      max-width: 100%;
    }

    .course-description {
      margin-bottom: 14px;
    }

    .accordion .card {
      .card-header {
        margin-bottom: -6px;
      }

      .card-body ul li {
        margin-bottom: 8px;
      }
    }

    .collection-video {
      max-width: 500px;
      height: fit-content;

      .video-insighter-container video {
        height: 275px;
        margin: 0 auto;
        width: 100%;
      }

      .collection-video-details span {
        margin-bottom: 7px;
      }

      .collection-video-details img.video-expand {
        right: 59px;
        top: 22px;
      }

      &:hover .video-insighter-progress .played:after {
        top: -10px !important;
      }
    }

    #video-insighter-list .category-slide-item {
      padding: 25px 20px 15px 95px;
      margin-bottom: 20px;

      .insight-item-details {
        .card-duration {
          margin-bottom: 4px;
        }

        .card-text {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 9px;
        }
      }
    }
  }
}

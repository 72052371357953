.program-banner {
  position: relative;
  padding: 100px 0;
  color: white;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      object-fit: cover;
    }
  }

  &__image,
  &__image img {
    width: 100%;
    height: 100%;
  }

  &__title {
    font-weight: bold;
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 36px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  &__price {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 36px;

    &_previous {
      text-decoration: line-through;
    }
  }

  &__button {
    width: 272px;
    border-radius: 8px !important;
    justify-content: center;
  }
}

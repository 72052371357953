@media screen and (max-width: 1199px) {
  .program-founder {
    &__container {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }
}

@media screen and (max-width: 991px) {
  .program-founder {
    &__container {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__image img {
      object-position: 75%;
    }

    &__author {
      font-size: 16px;
      line-height: 24px;

      &_title {
        font-weight: 500;
      }
    }

    &__text {
      font-size: 24px;
      line-height: 36px;
    }
  }
}

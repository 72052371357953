.testimonial-modal {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.25s,
    visibility 0.25s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #181a26;
    opacity: 0.5;
  }

  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 16px;
    width: 946px;

    &__close-button {
      position: absolute;
      top: 0;
      right: -47px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #ffffff;
      border: none;
      padding: 0;
      transform: rotate(45deg);
      box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 3px;
        background-color: #6f7283;
        border-radius: 2px;
        transition: background-color 0.25s;
        box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);
      }

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        transform: rotate(90deg) translate(-2px, 7px);
      }

      &:hover {
        outline: none;

        &:before,
        &:after {
          background-color: #3d8be8;
        }
      }

      &:active {
        outline: none;

        &:before,
        &:after {
          background-color: #2465b3;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  &_active {
    opacity: 1;
    visibility: visible;
  }
}

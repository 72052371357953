@media screen and (max-width: 1199px) {
  .how-it-works {
    min-height: unset;
    &_alt {
      padding: 70px 0 80px 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .how-it-works {
    padding: 60px 0 75px 0;

    &__text {
      font-size: 16px;
      max-width: 400px;
    }

    &__list {
      h2 {
        font-size: 22px;
      }

      .how-it-works__item {
        padding: 25px 20px;
        font-size: 14px;

        p {
          font-size: 16px;
        }

        ul {
          li {
            margin-top: 10px;
          }
        }
      }
    }

    &.silent-retreat {
      .silent-retreat__title {
        font-size: 22px;
      }

      .silent-retreat-condition {
        font-size: 14px;
        margin-top: 25px;

        span {
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .how-it-works {
    &__text {
      max-width: 335px;
    }
  }
}

.powerful {
  padding: 100px 0;
  background-color: #a9caed;
  background-image: url('/img/powerful-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;

  &__message {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #31364e;
    margin-bottom: 0;
  }

  &__title {
    margin: 30px 0 40px 0;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 600;
    color: #31364e;
    margin-bottom: 0;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  &__item {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #31364e;

    img {
      box-shadow: 0 0 15px 0 rgba(61, 139, 232, 0.4);
      border: solid 1px #e9e9e9;
      border-radius: 50%;
      background-color: #ffffff;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 1.4;
    color: #31364e;
    margin-bottom: 80px;

    span {
      display: block;

      &:not(:first-child) {
        margin-top: 15px;
      }

      b {
        font-weight: 600;
      }
    }
  }

  &__block {
    padding: 50px 0;
    border-radius: 8px;
    box-shadow:
      0 2px 25px 0 rgba(61, 139, 232, 0.2),
      0 0 30px 0 rgba(137, 190, 236, 0.4);
    border: solid 1px #e9e9e9;
    background-color: #ffffff;

    div {
      text-align: center;
      margin: 0 auto;

      .discount {
        font-weight: 400;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.3px;
        color: #31364e;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      h2 {
        font-size: 24px;
        font-weight: 600;
        color: #31364e;
        margin-bottom: 10px;
      }

      p {
        font-size: 20px;
        line-height: 1;
        color: #31364e;
        margin-bottom: 25px;
      }

      .powerful__block-caption_2 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        margin-bottom: 30px;
      }

      .powerful__block-caption_3 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        margin-bottom: 20px;
      }

      .powerful__block-title_2 {
        font-size: 26px;
        line-height: 1.31;
        margin-bottom: 10px;
      }

      .powerful__block-subtitle_2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 40px;
      }

      .powerful__block-title_3 {
        font-size: 26px;
        margin-bottom: 40px;
      }

      .powerful__block-title_5 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .btn-wrapper {
        margin: 0;
      }

      &.powerful__block-titles {
        padding-bottom: 30px;
      }
    }

    &_bottom {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .bottom-box {
        padding: 25px 30px;
        margin: 0;
        display: flex;
        align-items: center;
        background-color: rgba(237, 153, 78, 0.1);

        p {
          margin: 0 auto 0 10px;
          font-size: 16px;
          line-height: 1.25;
          color: #31364e;
        }

        .notes {
          display: inline-flex;
          word-wrap: break-word;
          p {
            margin: 0;
            font-size: 0.875rem;
            line-height: 1.25rem;
            padding-left: 4px;
            word-break: break-all;
            margin-right: 20px;
          }
        }

        .btn-outline {
          margin-right: 25px;
        }
      }
    }
  }

  &.silent-retreat {
    .silent-retreat__title {
      font-size: 26px;
      font-weight: 600;
      line-height: 1.31;
      color: #31364e;
      margin-bottom: 60px;
    }
  }

  &.volunteer-program {
    background-image: url('/img/volunteer-powerful-bg.png');
    background-position-y: top;

    .powerful {
      &__title {
        text-align: center;
        margin: 0 0 40px;
      }

      &__block {
        padding: 0;

        &-titles {
          padding: 40px 0;
        }

        &-caption {
          margin: 0;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .bottom-box {
          padding: 15px;
        }

        .btn-secondary {
          width: auto !important;
        }
      }
    }
  }
}

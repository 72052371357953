.sky-resilience {
  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
  }
  .container {
    max-width: 1334px;
  }
  .section-tag {
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: #0e59a3;
    margin-bottom: 12px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .section-title {
    font-family: Lora;
    font-size: 56px;
    line-height: 68.32px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 12px;
    @media screen and (max-width: 991px) {
      font-size: 42px;
      line-height: 52px;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .section-desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #6f7283;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .banner-section {
    background-image: url(/img/sky-resilience-banner1.webp);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    @media screen and (max-width: 767px) {
      background-image: url(/img/sky-resilience-banner-mobile1.webp);
      background-size: auto;
      background-position: center;
      padding-bottom: 290px;
    }
    .container {
      padding-top: 83px;
      padding-bottom: 217px;
      margin-bottom: 100px;
      @media screen and (max-width: 991px) {
        padding-top: 58px;
        padding-bottom: 40px;
        margin-bottom: 0px;
      }
      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }
    }
    .course-type-pill {
      background: #e3f1ff;
      padding: 4px 16px;
      font-size: 14px;
      font-weight: 500;
      color: #0e59a3;
      line-height: 24px;
      border-radius: 12px;
      text-transform: uppercase;
      display: inline-block;
    }
    .banner-title {
      margin-top: 24px;
      font-family: 'Lora';
      font-size: 70px;
      line-height: 84px;
      margin-bottom: 32px;
      @media screen and (max-width: 991px) {
        font-size: 36px;
        line-height: 48px;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
        line-height: 40px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .banner-course-features {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 24px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
        gap: 8px;
      }
      li {
        display: flex;
        gap: 16px;
        align-items: center;
        @media screen and (max-width: 767px) {
          gap: 8px;
        }
        .icon-aol {
          font-size: 20px;
          display: flex;
          color: white;
          background: linear-gradient(
            102.78deg,
            #77bcff -37.89%,
            #2778c7 100.28%
          );
          width: 44px;
          height: 44px;
          align-items: center;
          justify-content: center;
          border-radius: 16px;
          @media screen and (max-width: 991px) {
            width: 34px;
            height: 34px;
            border-radius: 10px;
            font-size: 16px;
            flex-shrink: 0;
          }
          @media screen and (max-width: 767px) {
            width: 30px;
            height: 30px;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .section-registration-widget {
    .registration-widget {
      border: none;
      box-shadow: 7px 12px 39.8px 5px #7473711f;
      margin-top: -183px;
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        gap: 15px;
        .register-content {
          padding-bottom: 15px;
          .dates {
            margin-top: 0;
            .title {
              font-size: 18px;
            }
            .content {
              font-size: 16px;
              line-height: 20px;
            }
          }
          .col:last-child {
            .dates {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .col {
      &.icon-money,
      &.icon-calendar,
      &.icon-location {
        padding-left: 55px;
        padding-top: 4px;
        &::before {
          content: '\e958';
          font-family: 'iconaol';
          font-size: 30px;
          color: #0e59a3;
          position: absolute;
          left: 15px;
          top: 0;
          height: 30px;
          line-height: 30px;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            height: 24px;
            line-height: 24px;
          }
        }
      }
      &.icon-calendar {
        &::before {
          content: '\e90a';
        }
      }
      &.icon-location {
        &::before {
          content: '\e90b';
        }
      }
      &.circle {
        &::before {
          background-color: #0e59a3;
          margin-right: 20px;
          margin-left: 10px;
        }
      }
      .icon-aol {
        font-size: 30px;
        color: #0e59a3;
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
      &.instructor,
      &.location {
        align-items: flex-start;
      }
      .instructor-content,
      .contact-content {
        margin-left: 10px;
      }
      &.notes-content {
        display: flex;
        gap: 10px;
      }
    }
    .register-button {
      background: #0e59a3;
      border-radius: 100px;
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
      padding: 20px 32px;
    }
    .additional-info-box {
      background: #0e59a333;
      padding: 15px 20px;
      font-family: Work Sans;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
      .info-row {
        display: flex;
        gap: 10px;
        strong {
          font-weight: 500;
        }
      }
      .icon-aol {
        font-size: 24px;
      }
    }
  }
  .section-benefits-resilience {
    padding: 100px 0;
    @media screen and (max-width: 767px) {
      padding: 60px 0;
    }
    .section-desc {
      max-width: 650px;
      margin: auto;
    }
    .benefits-row {
      display: flex;
      gap: 75px;
      padding-top: 60px;
      @media screen and (max-width: 1199px) {
        gap: 40px;
      }
      @media screen and (max-width: 991px) {
        flex-wrap: wrap;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
        padding-top: 30px;
      }
    }
    .benefits-col.col-img {
      justify-content: center;
      @media screen and (max-width: 1199px) {
        flex-basis: 25%;
      }
      @media screen and (max-width: 991px) {
        order: -1;
        flex-basis: 100%;
        align-items: center;
      }
    }
    .img-resilience-benefit {
      border-radius: 32px;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
      }
    }
    .benefits-col {
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 64px;
      @media screen and (max-width: 767px) {
        gap: 20px;
      }
    }
    .benefit-item-icon {
      font-size: 44px;
      color: #0e59a3;
      height: 44px;
      margin-bottom: 24px;
      @media screen and (max-width: 767px) {
        font-size: 30px;
        height: 30px;
        margin-bottom: 12px;
      }
    }
    .benefit-item-title {
      font-family: Lora;
      font-weight: 500;
      font-size: 28px;
      line-height: 36.4px;
      margin-bottom: 16px;
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 12px;
      }
    }
    .benefit-item-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #6f7283;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  section.featured-in {
    padding-bottom: 140px;
    @media screen and (max-width: 767px) {
      padding-bottom: 60px;
    }
    .featured-listing {
      padding-top: 48px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      row-gap: 48px;
      column-gap: 100px;
      @media screen and (max-width: 767px) {
        padding-top: 30px;
        row-gap: 20px;
        flex-direction: column;
      }
    }
    .featured-item-logo img {
      height: 48px;
      @media screen and (max-width: 767px) {
        height: 30px;
      }
    }
  }
  .section-why {
    padding-bottom: 140px;
    @media screen and (max-width: 767px) {
      padding-bottom: 60px;
    }
    .why-content-box {
      display: flex;
      gap: 48px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
      }
    }
    .cb-image-container {
      max-width: 680px;
      img {
        border-radius: 32px;
      }
      @media screen and (max-width: 1199px) {
        flex: 50%;
        flex-shrink: 0;
        img {
          max-width: 100%;
        }
      }
    }
    .cb-info-container {
      gap: 20px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        gap: 12px;
      }
      p {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #6f7283;
        @media screen and (max-width: 991px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    .section-title {
      text-align: left;
    }
  }
  section.how-work {
    padding-bottom: 140px;
    @media screen and (max-width: 767px) {
      padding-bottom: 60px;
    }
    .how-work-features {
      margin-top: 60px;
      display: grid;
      gap: 47px 20px;
      grid-template-columns: repeat(var(--n, 2), 1fr);
      @media (width < 768px) {
        --n: 1;
        gap: 38px 15px;
      }
      @media screen and (max-width: 991px) {
        margin-top: 40px;
      }
    }
    .hw-item {
      background: #f7f7f7;
      border-radius: 20px;
      padding: 0 20px 30px;
      text-align: center;
      //margin-bottom: 27px;
    }
    .hw-icon {
      background: linear-gradient(102.78deg, #3d8be8 -37.89%, #89beec 100.28%);
      height: 54px;
      width: 54px;
      color: white;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 47px;
      border: 13px solid white;
      box-sizing: content-box;
      margin: -27px auto 10px;
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        height: 27px;
        width: 20px;
        background: #f7f7f7;
        border-right: white 5px solid;
        border-top-right-radius: 30px;
        border-top: white 5px solid;
        position: absolute;
        left: -27px;
        top: 9px;
        @media screen and (max-width: 991px) {
          height: 20px;
        }
      }
      &::after {
        left: unset;
        right: -27px;
        border-radius: unset;
        border-left: white 5px solid;
        border-top-right-radius: unset;
        border-top-left-radius: 30px;
        border-right: none;
      }
      @media screen and (max-width: 991px) {
        height: 41px;
        width: 41px;
        font-size: 20px;
      }
    }
    .hw-title {
      font-family: Lora;
      font-size: 24px;
      font-weight: 500;
      line-height: 31.2px;
      margin-bottom: 12px;
    }
    .hw-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .changing-lives {
    padding-bottom: 140px;
    @media screen and (max-width: 767px) {
      padding-bottom: 60px;
    }
    .section-title {
      margin-bottom: 48px;
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .video-changing-lives {
      text-align: center;
      iframe {
        border-radius: 32px;
        @media screen and (max-width: 1199px) {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          height: 315px;
        }
      }
    }
  }
  .section-testimonials {
    padding-bottom: 140px;
    .testimonials-listing {
      margin-top: 48px;
      display: grid;
      gap: 48px 24px;
      grid-template-columns: repeat(var(--n, 3), 1fr);
      @media (width < 768px) {
        --n: 1;
        gap: 38px 15px;
      }
    }
    .testimonial-item {
      box-shadow: 4px 6px 25.1px 0px #6e6b6824;
      border-radius: 32px;
      padding: 32px 24px;
      display: flex;
      flex-direction: column;
    }
    .testimony-title {
      font-family: Lora;
      font-size: 24px;
      line-height: 31.2px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    .testimony-text {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
      a {
        text-decoration: underline;
        color: #0e59a3;
      }
    }
    .author-info {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-top: auto;
    }
    .author-picutre {
      width: 60px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 100px;
      }
    }
    .author-name {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }
    .author-position {
      font-size: 16px;
      line-height: 22px;
      color: #6f7283;
      margin-top: 2px;
    }
    .page-registration-action {
      text-align: center;
      padding-top: 60px;
      button {
        background: linear-gradient(
          102.78deg,
          #6db7ff -37.89%,
          #0e59a3 100.28%
        );
        padding: 20px 32px;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        border-radius: 100px;
        border: none;
        color: white;
      }
    }
  }
}

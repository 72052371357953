@media screen and (max-width: 1199px) {
  .program-meet {
    &__container {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
}

@media screen and (max-width: 991px) {
  .program-meet {
    &__container {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__image:after {
      height: 50%;
    }

    &__title {
      text-align: center;
      font-size: 28px;
      line-height: 40px;
    }

    &__subtitle {
      text-align: center;
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 30px;
    }

    &__text {
      font-size: 16px;
      line-height: 22px;
    }

    &__quote {
      font-size: 16px;
      line-height: 24px;
    }

    .program-meet-card {
      padding: 20px 15px 20px 90px;

      &__icon {
        top: 20px;
        left: 15px;
      }

      &__title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 5px;
      }

      &__text {
        font-size: 14px;
        line-height: 22px;
      }
    }

    &__hint {
      font-size: 12px;
      line-height: 16px;
    }

    &__button {
      margin: 0;
    }

    &_toolkit {
      .program-meet {
        &__image img {
          object-position: right middle;
        }
      }
      .program-meet__text {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .program-meet {
    &__button {
      width: 100%;
    }
  }
}

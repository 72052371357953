.card-title-bxs {
  .course_name {
    font-family: Lora !important;
    font-size: 28px !important;
    line-height: 1.43 !important;
    // white-space: nowrap !important;
    // overflow: hidden !important;
    // text-overflow: ellipsis !important;
    margin-bottom: 0.75rem;
    font-weight: 500 !important;
    color: #31364e;
  }
  .course_place {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #31364e;
  }
}

.duration-time-txt {
  border-radius: 4px !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #31364e !important;
}
.collection-lock {
  position: absolute;
  top: 20px;
  right: 44px;
}

.upcoming_course_card.newCard-new {
  width: 100%;
  max-width: 262.6px;
  height: auto;
  text-align: left;
  display: flex;
  /* justify-items: flex-end; */
  justify-content: space-between;
  padding: 20px 20px 30px;
  background-size: cover !important;
  background-position: center !important;
  color: #31364e;
  border: 0;
  flex-direction: column;
  text-decoration: none;
  min-height: 163px;
  /* margin-bottom: 20px; */
}
.upcoming_course_card.newCard-new img {
  width: auto !important;
}

.upcoming_course_card.newCard-new {
  position: relative;
}
.card.image-card.image-card-1 .course-like,
.upcoming_course_card.newCard-new .course-like {
  background-image: url('/img/ic-heart.svg');
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  z-index: 101;
  background-color: rgba(255, 255, 255, 0.6);
}
.upcoming_course_card.newCard-new .course-like.liked {
  background-image: url('/img/ic-heart-filled.svg');
}
.card.image-card.image-card-1 .course-like.liked {
  background-image: url('/img/ic-heart-filled.svg');
}
.upcoming_course_card.newCard-new .forClick,
.swiper-slide.popular-slide-item .forClick {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 52px;
  z-index: 998;
}

@media (min-width: 767px) {
  .upcoming_course_card.newCard-new {
    height: auto;

    min-height: 263px;
    /* margin-bottom: 20px; */
  }
}
@media (min-width: 1200px) {
  .upcoming_course_card.newCard-new {
    height: auto;

    min-height: 320px;
    /* margin-bottom: 20px; */
  }
}
@media (max-width: 991px) {
  .upcoming_course_card.newCard-new {
    min-height: 263px;
  }
}

@media (max-width: 767px) {
  .container.mob-container {
    max-width: 540px !important;
  }
  .upcoming_course_card.newCard-new {
    min-height: 301px;
  }
  .mob-container.container.upcoming_course .col-6.col-lg-3.col-md-4 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 640px) {
  .upcoming_course_card.newCard-new {
    height: auto;

    min-height: 301px;
    /* margin-bottom: 20px; */
  }
}

@media (max-width: 576px) {
  .upcoming_course_card.newCard-new {
    height: auto;

    min-height: 163px;
  }
  .find-page-tiles .upcoming_course_card.newCard-new {
    height: auto;

    min-height: 183px;
  }
}

.meditate-collection,
.meditate-find {
  overflow: hidden;
}
.meditate-collection .card-title-bxs .course_name {
  font-weight: 800 !important;
  font-size: 18px !important;
  font-family:
    Work Sans,
    sans-serif !important;
  line-height: 1.2 !important;
}
.meditate-collection .course_dat,
.meditate-find .course_dat {
  z-index: 9;
}

.meditate-collection .card-title-bxs,
.meditate-find .card-title-bxs {
  position: absolute;
  bottom: 30px;
  top: auto;
  left: 20px;

  z-index: 1;
}
.meditate-collection .course_name,
.meditate-find .course_name {
  margin-bottom: 10px;
}

.course-join-card__text-container .description {
  font-size: 20px;
  padding: 0;
  margin: 25px 0 30px;
  line-height: 1.38;
}
.course-join-card__text-container ul li {
  position: relative;
  padding-left: 15px;
  font-size: 16px;
  line-height: 1.43;
  margin-bottom: 10px;
}
.course-join-card__text-container ul li::before {
  content: '';
  background-color: #ed994e;
  font-weight: bold;
  display: inline-block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 10px;
}

.meditation-digital-membership {
  max-width: 620px !important;
  width: 620px;
}
.meditation-digital-membership .course-join-card__body {
  padding: 40px 0 0 0 !important;
}
.meditation-digital-membership .course-join-card__text-container .description {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
  color: #31364e !important;
  letter-spacing: 0.3px;
}
.meditation-digital-membership .course-join-card__text-container ul li {
  position: relative;
  padding-left: 15px;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 10px;
}
.meditation-digital-membership .modal-gray-text {
  color: #9598a6;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}

.meditation-digital-membership .course-join-card__footer {
  padding: 0px !important;
}
.meditation-digital-membership .course-join-card__footer .btn-secondary.v2 {
  justify-content: space-around;
  width: 225px;
  height: 50px;
  margin: 25px !important;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  font-size: 15px;
  border-radius: 16px;
}
.meditation-digital-membership .course-join-card__footer .btn-secondary.v3 {
  width: 125px;
  height: 50px;
  margin: 25px !important;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  font-size: 15px;
  border-radius: 16px;
}
.meditation-digital-membership .course-join-card__text-container {
  padding: 0 25px !important;
  margin: 10px 0 20px !important;
  line-height: 1.38;
}

.course-join-card__title {
  font-family: Lora;
  font-weight: 400;
  text-align: center;
}
.contentCard {
  .card-title,
  .course_name,
  .card-text,
  .course_place {
    color: #fff !important;
  }
}

.search-form {
  a.btn {
    font-weight: 600;
    text-decoration: none;
    color: #31364e;
    opacity: 0.5;
  }
}

.switch-flter-container {
  display: flex;
}

.upcoming_course_card_v1 .forClick {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 52px;
  z-index: 998;
}
.upcoming_course_card_v1 {
  margin-top: 13px;
  height: 315px;
  margin-bottom: 12px;
}

.inline-edit-input-container .icon {
  position: absolute;
  padding: 30px 10px 10px 10px;
  color: #3d8be8;
  text-align: left;
  right: 0px;
  background-color: #ffffff85;
}
.inline-edit-input-container {
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
  .upcoming_course_card_v1 {
    height: 200px;
    margin-bottom: 2px;
  }
  .inline-edit-input-container .icon {
    padding: 0px 10px;
  }
}

.meditation #player.visible {
  z-index: 1000 !important;
}

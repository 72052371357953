.program-answers {
  padding: 80px 0;

  &__quote {
    position: relative;
    padding-top: 91px;
    margin-top: 43px;

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 95px;
      width: 404px;
      height: 202px;
      background: linear-gradient(180deg, #96bbef 0%, #5b86ec 100%);
      border-top-left-radius: 202px;
      border-top-right-radius: 202px;
      border-bottom: 0;
      opacity: 0.08;
    }

    &-title {
      font-weight: bold;
      font-size: 44px;
      line-height: 60px;
      color: #1e2232;
      margin-bottom: 28px;
    }

    &-text {
      font-size: 16px;
      line-height: 22px;
      color: #606270;
    }
  }

  &__item {
    position: relative;
    color: #1e2232;
    display: flex;
    align-items: center;

    &-image {
      position: relative;
      width: 172px;
      height: 220px;
      border-radius: 8px;
      margin-right: 40px;
      overflow: hidden;
      flex-shrink: 0;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &-info {
      &__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 16px;
      }

      &__text {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}

@import './404';
@import './backend-checkout';

.login-screen {
  background: #ffffff url('/img/login-bg-angle.png') no-repeat top right -40px /
    100% auto;
  height: 100vh - 10;
}
.mrgb,
figure {
  margin-bottom: 0;
}

.aol_header {
  position: relative;

  &.is-sticky {
    width: 100%;
    left: 0;
    z-index: 999;
    position: fixed;
    top: 0px;
  }
}

.aol_brandLogo img {
  max-width: 113px;
}

.inline-loader-container {
  text-align: center;
  .wrapper {
    width: 106px;
    margin: 10px auto;
    text-align: center;
  }
  .circle {
    border-radius: 50%;
    border: 3px #4b5487 solid;
    float: left;
    height: 25px;
    margin: 0 5px;
    width: 25px;
  }
  .circle-1 {
    animation: move 1s ease-in-out infinite;
  }
  .circle-1a {
    animation: fade 1s ease-in-out infinite;
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .circle-2 {
    animation: move 1s ease-in-out infinite;
  }
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(35px);
    }
  }
  .circle-1a {
    margin-left: -30px;
    opacity: 0;
  }
  .circle-3 {
    animation: circle-3 1s ease-in-out infinite;
    opacity: 1;
  }
  @keyframes circle-3 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  h1 {
    text-align: center;
    color: #4b5487;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin: 10px auto;
    padding: 10px;
    text-transform: uppercase;
    clear: both;
  }
}
.order__card__payment-method {
  width: 100%;
  .bank-card-info {
    display: inline-flex;
  }
}
.card-element {
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 8px;
  .StripeElement {
    margin-top: 30px;
  }
}

.cover-spin {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  border-radius: 8px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #3d8be8;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
  margin-top: -23px;
  margin-left: -23px;
}
.cover-spin-inline {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 10px;
}
.cover-spin-inline::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #3d8be8;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
.scheduling-spin {
  position: unset;
  height: unset;
}

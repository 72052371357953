.program-faqs {
  padding: 80px 0;
  background-color: #8abfec;
  background-image: url('/img/faqs-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;

  &__card {
    padding: 25px;
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 12px;
    display: flex;
    flex-direction: column;

    &-title {
      font-family:
        Work Sans,
        sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    &-link {
      font-size: 14px;
      line-height: 18px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .program-faqs {
    padding: 60px 0;

    &__card {
      padding: 25px 20px;

      &-link {
        line-height: 20px;
      }
    }
  }
}

.wcf-input {
  font-family: $wcf-font-regular;
  font-size: 16px;
  line-height: 24px;
  position: relative;

  &__label {
    margin-bottom: 8px;
    color: transparentize($wcf-dark, 0.3);
    ~ .wcf-input__button {
      top: 40px;
    }
  }

  &__field {
    width: 100%;
    padding: 11px;
    border-radius: 0;
    border: 1px solid $wcf-light-grey;
    color: $wcf-dark-grey;
    background-color: $wcf-light;
    box-shadow: $wcf-shadow;
    transition:
      border-color $wcf-transition-effect,
      box-shadow $wcf-transition-effect;

    &:focus {
      border-color: transparentize($wcf-dark, 0.5) !important;
      box-shadow: $wcf-shadow-active;
    }

    &::placeholder {
      color: transparentize($wcf-dark-grey, 0.5);
    }
    &.error {
      border-color: $wcf-orange !important;
    }
  }

  &__button {
    position: absolute;
    top: 8px;
    right: 16px;
    display: block;
    background-color: $wcf-light;
    padding: 4px;
    width: 32px;
    height: 32px;
    text-align: center;
    border: none;

    img {
      width: 24px;
      height: 24px;
    }

    &:focus {
      outline: none;
    }
  }

  &__info,
  &__error-message {
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    padding: 0 12px;
  }

  &__info {
    color: transparentize($wcf-dark-blue, 0.5);
  }

  &__error-message {
    text-align: right;
    color: $wcf-orange;
    display: block;
  }
}

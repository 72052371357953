@import '../variables';
@media screen and (max-width: 720px) {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
      padding: 0;

      .logo {
        display: flex;
        justify-content: center;
        border-bottom: $grey-border;
        padding: 6px 0;

        img {
          width: 88px;
          height: 35px;
        }
      }

      .mobile-wrapper {
        padding: 20px;

        .modal-header {
          flex-direction: column;

          .close {
            position: static;
            width: 32px;
            height: 32px;
            background-color: $white;
            border-radius: 50%;
            padding: 0;
            margin: 0;
            opacity: 1;
            box-shadow: 0 2px 10px 0 rgba(61, 139, 232, 0.1);
          }

          .modal-title {
            margin-top: 30px;
            font-size: 24px;
            color: $color-grey-title;
          }
        }

        .modal-body {
          padding: 0;
          margin: 25px 0 0;
          flex: 0;

          .description {
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 1.43;
          }

          ul li::before {
            content: '' !important;
            // width: 0;
          }
        }

        .card-wrapper {
          flex-direction: column;
          align-items: center;

          .card {
            margin-top: 20px;
            width: 335px;
            .card-body {
              font-size: 16px;
              font-weight: 600;
              .card-title {
                padding: 10px 0;
                margin-bottom: 20px;
              }

              .btn {
                height: 44px;
                font-size: 14px;
                margin: 20px 0;
              }
            }
          }
        }
      }
    }
  }
  .modal {
    &.locked {
      padding: 0 !important;
      .modal-dialog {
        .modal-content {
          .mobile-wrapper {
            .card-wrapper {
              .card {
                width: 100%;
                .card-body {
                  .card-title {
                    background-color: $white;
                    text-align: center;
                    button {
                      margin: 0 !important;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.modal_module_details {
      border-radius: 12px 12px 0 0;
      background-color: #f7f7f7;
      height: 220px;
      top: inherit;
      bottom: 0;
      .title {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .description {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .modal_video {
    &.full {
      .modal-dialog {
        .modal-content {
          .mobile-wrapper {
            .modal-header {
              .video-shrink {
                right: 50px;
              }
              .close {
                left: calc(100% - 30px);
                top: 30px;
              }
            }
          }
        }
      }
    }
    .modal-dialog {
      width: 90%;
      height: auto;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%) !important;
      .modal-content {
        .mobile-wrapper {
          padding: 0;
          .modal-header {
            .close {
              z-index: 999;
              position: absolute;
              top: -13px;
              right: -13px;
            }
          }
        }
      }
    }
  }
}
